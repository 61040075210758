import { Component, Input, OnInit } from '@angular/core';
import { CurrencyPipe } from '@angular/common';
import { AlertController, ModalController } from '@ionic/angular';
import { Title } from '@angular/platform-browser';
import { environment } from 'src/environments/environment';

import { ApiService } from '../../services/api.service';
import { UserService } from '../../services/user.service';
import { YmService } from '../../services/ym.service';

import { SpellCountPipe } from '../../pipes/spell-count.pipe';

@Component({
  selector: 'app-sub',
  templateUrl: './sub.page.html',
  styleUrls: ['./sub.page.scss'],
})
export class SubPage implements OnInit {

  @Input() isModal: boolean;
  private req: any;
  licenseData: {
    promocode: string,
    promocode_sale: number,
    pvzs: number,
    pvzs_sale: number, // скидка за ПВЗ
    months: number,
    months_sale: number // скидка за месяцы
  }
  pvzsCount: number;
  disabledPromoInput: boolean; // костыль, чтобы фокуса на инпуте при открытии в модале не было
  hasAccessToPvzs: boolean;

  constructor(private alertCtrl: AlertController, public api: ApiService, private appTitle: Title, private currencyPipe: CurrencyPipe, private modalCtrl: ModalController, private spellCount: SpellCountPipe, public user: UserService, private ym: YmService) {
    this.licenseData = {
      promocode: null,
      promocode_sale: 0,
      pvzs: this.user.data.subLicenses ? this.user.data.subLicenses : 1,
      pvzs_sale: 0,
      months: 3,
      months_sale: 0
    }
  }

  ngOnInit() {
    if (this.isModal)
      this.licenseData.months = 1
    this.getPvzs();
  }

  ionViewDidEnter() {
    this.appTitle.setTitle(`Тарифный план | ${ environment.appTitle }`);
    if (this.isModal) {
      this.ym.hit('sub', { params: { title: 'Окно оформления тарифного плана' } });
      this.disabledPromoInput = true;
      setTimeout(() => {
        this.disabledPromoInput = false;
      }, 1000);
    } else {
      this.ym.hit();
    }
  }

  private getPvzs() {
    this.api.get('pvzs_count').subscribe((res: any) => {
      if (res.success) {
        this.pvzsCount = res.amount;
        this.licenseData.pvzs = this.pvzsCount ? this.pvzsCount : 1
      }
    });
  }

  getLicensesCount() {
    if (this.licenseData.pvzs < this.pvzsCount)
      this.licenseData.pvzs = this.pvzsCount
    return this.licenseData.pvzs;
  }

  /**
   * Подсчитываем количество оставшихся месяцев для просчёта дозакупки лицензий и улучшения тарифа
   */
  calculateRemainingMonths(): number {
    let monthDiff = new Date(this.user.data.subUntil).getMonth() - new Date().getMonth();
    if (monthDiff == 0)
      monthDiff = 1;
    let yearDiff = new Date(this.user.data.subUntil).getFullYear() - new Date().getFullYear();
    return monthDiff + yearDiff * 12;
  }

  calculateRemainingDays(): string {
    let days: number = Math.ceil((new Date(this.user.data.subUntil).getTime() - Date.now()) / 1000 / 60 / 60 / 24);
    return days == 0 ? 'сегодня' : this.spellCount.transform(days, 'день', "дня", "дней");
  }

  calculatePrice(price: number, pvzs: number, months: number): number {
    this.licenseData.pvzs_sale = 0;
    this.licenseData.months_sale = 0;
    if (this.user.subPlans && this.user.subPlans.sales) {
      for (let i = 0; i < this.user.subPlans.sales.pvzs.length; i++) {
        if (pvzs >= this.user.subPlans.sales.pvzs[i].count) {
          this.licenseData.pvzs_sale = 100 * (1 - this.user.subPlans.sales.pvzs[i].sale);
          break;
        }
      }
      for (let i = 0; i < this.user.subPlans.sales.months.length; i++) {
        if (months >= this.user.subPlans.sales.months[i].count) {
          this.licenseData.months_sale = 100 * (1 - this.user.subPlans.sales.months[i].sale);
          break;
        }
      }
    }

    return Math.ceil(price * (1 - (this.licenseData.months_sale + this.licenseData.pvzs_sale) / 100) * pvzs * (this.licenseData.promocode_sale ? (100 - this.licenseData.promocode_sale) / 100 : 1));
  }

  changePvzs(count: number) {
    this.licenseData.pvzs += count;  
  }

  changeMonths(count: number) {
    this.licenseData.months = count;
  }

  async checkPromocode() {
    if (this.licenseData.promocode_sale) {
      this.licenseData.promocode = null;
    } else if (this.licenseData.promocode) {
      await this.api.loadingPresent();
      this.api.post('pay_promo', { code: this.licenseData.promocode }, true)
        .subscribe({
          next: async (res: any) => {
            this.api.loadingDismiss();
            if (res.success) {
              this.ym.reachGoal('SUB_PROMO_CHECK', { "Промокод": this.licenseData.promocode })
              this.licenseData.promocode_sale = res.sale;
              this.api.toastPresent('Промокод успешно применён!');
            } else {
              this.ym.reachGoal('SUB_PROMO_FAILED', { "Промокод": this.licenseData.promocode })
              this.licenseData.promocode = null
              this.api.toastPresent(res.message);
            }
          }
        });
    }
  }

  codeChanged() {
    this.licenseData.promocode_sale = 0;
  }

  async addLicenses() {
    const confirm = await this.alertCtrl.create({
      message: `Укажите сколько ПВЗ вы хотите добавить в тариф:`,
      inputs: [
        {
          type: 'number',
          min: 1,
          value: 1
        }
      ],
      buttons: [{
          text: 'Отмена',
          role: 'cancel'
        },
        {
          text: 'Продолжить',
          role: 'confirm',
          handler: async (data) => {           
            let price = this.calculatePrice(this.user.data.subPrice, data[0], this.calculateRemainingMonths()) * this.calculateRemainingMonths();
            const alert = await this.alertCtrl.create({
              message: `Вы уверены, что хотите добавить в тариф ${ data[0] } ПВЗ${ this.user.data.subPrice ? ` за ${ this.currencyPipe.transform(price, 'RUB', 'symbol', '1.0-2', 'ru-RU') }` : '' }?`,
              buttons: [{
                  text: 'Отмена',
                  role: 'cancel'
                },
                {
                  text: "Добавить",
                  role: 'confirm',
                  handler: () => {
                    this.ym.reachGoal('SUB_LICENSES_ADDING', { "Количество ПВЗ": data[0], "order_price": price, "currency": "RUB" })
                    this.licenseData.promocode = null;
                    this.pay(data[0], null);
                  },
                }
              ],
            });
        
            await alert.present();
          },
        }
      ],
    });

    await confirm.present();
  }

  /**
   * 
   * @param plan 1 - standard, 2 - profi
   */
  async buy(plan: number) {
    let action = this.user.data.subActive ? "продлить" : "подключить";
    let price = this.calculatePrice(this.user.data.subPrice ? this.user.data.subPrice : (plan == 2 ? this.user.subPlans.prices.profi : this.user.subPlans.prices.standard), this.licenseData.pvzs, this.licenseData.months) * this.licenseData.months;
    let text = `Вы уверены, что хотите ${ action } тариф "ПРОФИ" для ${ this.licenseData.pvzs } ПВЗ на ${ this.licenseData.months == 12 ? '1 год' : this.spellCount.transform(this.licenseData.months, 'месяц', "месяца", "месяцев") } за ${ this.currencyPipe.transform(price, 'RUB', 'symbol', '1.0-2', 'ru-RU') }?`;
    if (this.user.data.subType == null) {
      action = "попробовать";
      text = `Вы уверены, что хотите бесплатно попробовать тариф "ПРОФИ" в течение 14 дней?`;
    }
    
    const alert = await this.alertCtrl.create({
      message: text,
      buttons: [{
          text: 'Отмена',
          role: 'cancel'
        },
        {
          text: action,
          role: 'confirm',
          handler: () => {
            if (this.user.data.subType == null) {
              this.ym.reachGoal('SUB_TRIAL_ACTIVATING', { "Количество ПВЗ": this.licenseData.pvzs })
              this.pay(this.licenseData.pvzs)
            } else {
              this.ym.reachGoal('SUB_BUYING_PROFI', { "Количество ПВЗ": this.licenseData.pvzs, "order_price": price, "currency": "RUB" })
              this.pay(this.licenseData.pvzs, this.licenseData.months)
            }
          },
        }
      ],
    });

    await alert.present();
  }

  pay(pvzs?: number, months?: number, plan?: number) {
    let showReloadAlert = async (message: string) => {
      const alert = await this.alertCtrl.create({
        message,
        buttons: [{
            text: 'Отмена',
            role: 'cancel'
          },
          {
            text: 'Обновить',
            role: 'confirm',
            handler: () => {
              window.location.reload();
            },
          }
        ],
      });
      await alert.present();
    }

    this.api.loadingPresent();
    let data: any = { data: {} };
    if (pvzs)
      data.data.pvzs = pvzs;
    if (months)
      data.data.months = months;
    if (plan)
      data.data.plan = plan;
    if (this.licenseData.promocode && this.user.data.subType)
      data.data.code = this.licenseData.promocode;
    this.api.post('pay', data, true)
      .subscribe({
        next: async (res: any) => {
          this.api.loadingDismiss();
          if (res.success) {
            if (res.reload) {
              showReloadAlert('Чтобы активировать пробный период, необходимо обновить страницу. Обновить?');
            } else if (res.url) {
              const alert = await this.alertCtrl.create({
                message: 'Вы будете перенаправлены на страницу оплаты. После проведения платежа может потребовать несколько минут на его обработку.',
                buttons: [{
                    text: 'Отмена',
                    role: 'cancel'
                  },
                  {
                    text: 'Продолжить',
                    role: 'confirm',
                    handler: () => {
                      window.open(res.url, "_blank");
                      showReloadAlert('После проведения оплаты нажмите кнопку "Обновить", чтобы изменения вступили в силу.');
                    },
                  }
                ],
              });
              await alert.present();
            } else if (res.direct) {
              this.ym.reachGoal('SUB_PAY_ERROR')
              const alert = await this.alertCtrl.create({
                message: 'Произошла ошибка при создании счёта. Вы будете перенаправлены для продолжения в поддержку.',
                buttons: [{
                    text: 'Отмена',
                    role: 'cancel'
                  },
                  {
                    text: 'Продолжить',
                    role: 'confirm',
                    handler: () => {
                      window.open(res.direct, "_blank");
                    },
                  }
                ],
              });
              await alert.present();
            }
          } else {
            this.api.toastPresent(res.message);
          }
        }
      });
  }

  close() {
    this.modalCtrl.dismiss();
  }

  ngOnDestroy() {
    if (this.req)
      this.req.unsubscribe()
  }

}
