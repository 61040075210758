import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular'
import { AlertsComponent } from './alerts.component';

@NgModule({
	declarations: [
		AlertsComponent
	],
	imports: [
		CommonModule,
		FormsModule,
		IonicModule,
	],
	exports: [
		AlertsComponent
	]
})

export class AlertsModule {}
