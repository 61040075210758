import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular'
import { MainToolbarComponent } from './main-toolbar.component';

import { AlertsModule } from '../../popovers/alerts/alerts.module'
import { SettingsModule } from 'src/app/modals/settings/settings.module';

@NgModule({
	declarations: [
		MainToolbarComponent,
	],
	imports: [
		AlertsModule,
		CommonModule,
		IonicModule,
		SettingsModule
	],
	exports: [
		MainToolbarComponent
	]
})

export class MainToolbarModule {}
