import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

import { ApiService } from '../../services/api.service';

@Component({
  selector: 'app-task-edit',
  templateUrl: './task-edit.component.html',
  styleUrls: ['./task-edit.component.scss'],
})
export class TaskEditComponent implements OnInit {

  @ViewChild('title') titleInput: any;
  @Input() editingItem: any;
  dataForm: FormGroup;
  userPvzs: any;
  
  constructor(public api: ApiService, private formBuilder: FormBuilder, private modalCtrl: ModalController) {
    this.userPvzs = [];
  }

  async ngOnInit() {
    this.dataForm = this.formBuilder.group({
      type: [1, Validators.required],
      pvz_id: [],
      title: [, [Validators.required, Validators.minLength(3), Validators.maxLength(50)]],
      url: [, Validators.maxLength(250)],
      important: [],
      value: [, Validators.min(0)],
      price: [, Validators.min(0)],
      summ: [, Validators.min(0)],
      comment: [, Validators.maxLength(250)]
    });

    if (this.editingItem) {
      // удаляем значения ПВЗ, чтобы подставить их позже, когда загрузится список
      let filteredItem = { ...this.editingItem };
      delete filteredItem.pvz_id;
      this.dataForm.patchValue(filteredItem);
    }

    setTimeout(() => this.titleInput.setFocus(), 500);

    this.getPvzs();
  }

  private getPvzs() {
    this.api.getPvzs()
      .subscribe({
        next: (res: any) => {
          this.api.loadingDismiss();
          if (res.success) {
            this.userPvzs = res.items;
            
            if (this.editingItem) {
              this.dataForm.patchValue({ pvz_id: this.editingItem.pvz_id });
            }
          } else {
            this.api.toastPresent('Не удалось загрузить список ПВЗ. Повторите попытку позже.');
            return this.modalCtrl.dismiss(null, 'close');
          }
        }
      });
  }

  async save() {
    return;
    if (this.dataForm.valid) {
      let data = { ...this.dataForm.value };
      
      if (this.editingItem) {
        data.id = this.editingItem.id;
      }

      if (!data.important)
        data.important = 0;
      
      await this.api.loadingPresent();

      this.api.post('request', data, true)
        .subscribe({
          next: async (res: any) => {
            this.api.loadingDismiss();
            if (res.success) {
              this.api.toastPresent(this.editingItem ? "Заявка успешно обновлена." : "Заявка успешно добавлена.");
              return this.modalCtrl.dismiss(true, 'saved');
            } else {
              this.api.toastPresent(res.message);
              if (res.code == 401) {
                return this.modalCtrl.dismiss(null, 'close');
              }
            }
          }
        });
    } else {
      this.api.toastPresent("Указаны некорректные данные в форме. Проверьте ещё раз введённую информацию.");
    }
  }

  close() {
    return this.modalCtrl.dismiss(null, 'close');
  }
}