import { Injectable } from '@angular/core';
import { AlertController, ModalController } from '@ionic/angular';

import { ApiService } from './api.service';
import { YmService } from './ym.service';

import { SalaryRewardEditComponent } from '../modals/salary-reward-edit/salary-reward-edit.component';

@Injectable({
  providedIn: 'root'
})
export class RewardsService {

  constructor(private alertCtrl: AlertController, private api: ApiService, private modalCtrl: ModalController, private ym: YmService) {}

  /**
   * Получает объект с начислением по его id
   * @param id номер начисления
   * @returns 
   */
  get(id: number): Promise<any> {
    return new Promise(async (resolve, reject) => {
      await this.api.loadingPresent();
      this.api.get(`salary_rewards/0`, { reward_id: id })
        .subscribe({
          next: async (res: any) => {
            this.api.loadingDismiss();
            if (res.success) {
              if (res.items[0]) {
                this.formatEmployeesArray(res.items[0])
                resolve(res.items[0])
              } else {
                resolve(false)
              }
            } else if (res.code != 401) {
              this.api.toastPresent(res.message);
              resolve(false)
            }
          }
        }
      );
    });
  }


  /**
   * Загружает инфу о начислении и открывает окно редактирования
   * @param id номер начисления
   * @returns промис с выполненным действием в модально окне
   */
  edit(id: number): Promise<any> {
    return new Promise(async (resolve, reject) => {
      let editingItem = await this.get(id);
      if (editingItem) {
        const modal = await this.modalCtrl.create({
          component: SalaryRewardEditComponent,
            componentProps: { editingItem },
            cssClass: 'salary-edit-modal'
        });
        modal.present();
    
        const { data, role } = await modal.onWillDismiss();
    
        resolve(role)
      } else {
        resolve(false)
      }
    });
  }

  /**
   * Удаляет начисление с предварительным подтверждением
   * @param item объект с начислением
   * @returns 
   */
  async delete(item): Promise<boolean> {
    return new Promise(async (resolve, reject) => {
      let moneyExists = false;
      for (let i = 0; i < item.employees.length; i++) {
        if (item.employees[i].money_date != '0' || item.employees[i].salary_date != '0') {
          moneyExists = true;
          break;
        }
      }
      if (moneyExists) {
        return this.api.toastPresent(`Вы не можете удалить это начисление, потому что оно уже было учтено в зарплате.`);
      }
      const alert = await this.alertCtrl.create({
        message: 'Вы действительно хотите удалить это начисление?',
        buttons: [{
            text: 'Отмена',
            role: 'cancel'
          },
          {
            text: 'Удалить',
            role: 'confirm',
            handler: async () => {
              await this.api.loadingPresent();
              let data: any = { id: item.id };
              this.api.post('salary_rewards_delete', data, true)
                .subscribe({
                  next: (res: any) => {
                    this.api.loadingDismiss();
                    if (res.success) {
                      this.ym.reachGoal('SALARY_REWARD_DELETED')
                      this.api.toastPresent("Начисление успешно удалено.");
                      resolve(true)
                    } else {
                      this.api.toastPresent(res.message);
                      resolve(false)
                    }
                  }
                });
            }
          }
        ],
      });
      await alert.present();
    });
  }

  /**
   * Преобразует строковые данные о разделении начисления между сотрудниками в массив сотрудников, чтобы корректно обрабатывать при редактировании
   * @param item объект с начислением
   */
  formatEmployeesArray(item) {
    if (item.employees_id) {
      item.employees = [];
      let employees_id = item.employees_id.split(';')
      let employees_user_id = item.employees_user_id.split(';')
      let employees_name = item.employees_name.split(';')
      let employees_value = item.employees_value.split(';')
      let employees_salary = item.employees_salary ? item.employees_salary.split(';') : []
      let employees_salary_id = item.employees_salary_id ? item.employees_salary_id.split(';') : []
      let employees_money = item.employees_money ? item.employees_money.split(';') : []
      
      for (let k = 0; k < employees_id.length; k++) {
        item.employees.push({ id: employees_id[k], user_id: employees_user_id[k], name: employees_name[k], value: employees_value[k], salary_id: parseInt(employees_salary_id[k]), salary_date: employees_salary[k], money_date: employees_money[k] })
      }
    }
  }

}
