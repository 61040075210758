import { Component, OnInit } from '@angular/core';
import { ModalController, PopoverController } from '@ionic/angular';

import { SubPage } from '../../pages/sub/sub.page'

import { ApiService } from '../../services/api.service';
import { EventsService } from '../../services/events.service';
import { UserService } from '../../services/user.service';
import { YmService } from '../../services/ym.service';

@Component({
  selector: 'app-alerts',
  templateUrl: './alerts.component.html',
  styleUrls: ['./alerts.component.scss']
})
export class AlertsComponent implements OnInit {

  items: any;
  itemsNotFound: boolean;
  itemsLoading: boolean;
  private req: any;
  private page: number;
  hasAccessToActionsHistory: boolean;
  onlySelfAlerts: boolean;

  segment: string;

  constructor(public api: ApiService, private events: EventsService, private modalCtrl: ModalController, private popCtrl: PopoverController, public user: UserService, private ym: YmService) {
    this.page = 0;
    this.segment = 'user_alerts';
    this.hasAccessToActionsHistory = this.user.data.role == 0 || this.user.checkPermissions([27, 28, 29])
    this.onlySelfAlerts = this.user.data.role != 0 && this.user.checkPermissions([27])
  }

  ngOnInit() {
    this.ym.hit('user-alerts', { params: { title: 'Окно уведомлений' } });
    this.getItems();
  }

  switchSegment(e: any) {
    if (e.detail.value)
      this.getItems();
  }

  getItems(infiniteScroll?: any) {
    if (!infiniteScroll) {
      this.page = 0;
      this.itemsNotFound = false;
      this.itemsLoading = true;
      this.items = [];
    }
    if (this.req)
      this.req.unsubscribe();
    this.req = this.api.get(`${ this.segment }/${ this.page }`)
      .subscribe({
        next: (res: any) => {
          this.itemsLoading = false;
          if (res.success) {
            if (res.items.length > 0) {
              if (!infiniteScroll) {
                this.items = res.items;
                this.itemsNotFound = false;
              } else {
                this.items = this.items.concat(res.items);
                infiniteScroll.target.complete();
              }
              this.page++;
            } else {
              if (!infiniteScroll) {
                this.itemsNotFound = true;
                this.items = [];
              } else {
                infiniteScroll.target.disabled = true;
              }
            }
          } else {
            if (!infiniteScroll) {
              this.itemsNotFound = true;
              this.items = [];
            } else {
              infiniteScroll.target.disabled = true;
            }
            this.api.toastPresent(res.message);
          }
        }
    });
  }

  async doAction(item: any, action: string) {
    await this.api.loadingPresent();
    this.api.post('user_alert', { id: item.id, action }, true)
      .subscribe({
        next: (res: any) => {
          this.api.loadingDismiss();
          if (res.success) {
            if (action == 'delete')
              this.getItems();
            else {
              if (item.id == null) {
                this.user.data.hasNewAlerts = false;
                 for (let i = 0; i < this.items.length; i++)
                  this.items[i].status = 1
              } else {
                item.status = 1;
              }
            }
          } else {
            this.api.toastPresent(res.message);
            if (res.code == 401) {
              return this.popCtrl.dismiss(null, 'close');
            }
          }
        }
      });
  }

  goTo(page: string) {
    this.popCtrl.dismiss({ page }, 'go');
  }

  initPush() {
    //console.log('init toggle', e.detail.checked);
    if (!this.user.data.subActive) {
      if (this.user.data.pushAllowed) {
        setTimeout(async () => {
          this.user.data.pushAllowed = false;
          this.api.toastPresent('Подключите тариф "ПРОФИ", чтобы получать полезные push-уведомления о сменах сотрудников и другой активности на ПВЗ.')
          const modal = await this.modalCtrl.create({
            component: SubPage,
            componentProps: { isModal: true },
            backdropDismiss: true,
            cssClass: 'sub-modal'
          });
          modal.present();
          this.ym.reachGoal('SUB_SHOW_MODAL', { "Окно подписки показано": "Включение пуш-уведомлений" })
        }, 10);
      }
    } else {
      this.ym.reachGoal(this.user.data.pushAllowed ? 'PUSH_ENABLED' : 'PUSH_DISABLED')
      this.events.publishPushInit(this.user.data.pushAllowed); 
    }
  }

  ngOnDestroy() {
    if (this.req)
      this.req.unsubscribe()
  }
}
