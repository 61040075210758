import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

import { ApiService } from '../../services/api.service';
import { YmService } from '../../services/ym.service';

import { PvzSelectComponent } from '../../modals/pvz-select/pvz-select.component'

@Component({
  selector: 'app-request-edit',
  templateUrl: './request-edit.component.html',
  styleUrls: ['./request-edit.component.scss'],
})
export class RequestEditComponent implements OnInit {

  @ViewChild('title') titleInput: any;
  @Input() editingItem: any;
  dataForm: FormGroup;
  userPvzs: any;
  fakeUsername;
  fakePvzAddress;
  
  constructor(public api: ApiService, private formBuilder: FormBuilder, private modalCtrl: ModalController, private ym: YmService) {
    this.userPvzs = [];
  }

  async ngOnInit() {
    this.ym.hit('request-edit', { params: { title: 'Окно редактирования заявки' } });

    this.dataForm = this.formBuilder.group({
      type: [1, Validators.required],
      pvz_id: [, Validators.required],
      title: [, [Validators.required, Validators.minLength(3), Validators.maxLength(50)]],
      url: [, [Validators.maxLength(250), Validators.pattern(/https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)/)]],
      important: [],
      value: [, Validators.min(0)],
      price: [, Validators.min(0)],
      summ: [, Validators.min(0)],
      comment: [, Validators.maxLength(250)]
    });

    if (this.editingItem) {
      if (this.editingItem.employee_name)
        this.fakeUsername = this.editingItem.employee_name
      if (this.editingItem.pvz_address)
        this.fakePvzAddress = this.editingItem.pvz_address

      this.dataForm.patchValue(this.editingItem);
    }

    setTimeout(() => this.titleInput.setFocus(), 500);

    this.getPvzs();
  }

  private getPvzs() {
    this.api.getPvzs()
      .subscribe({
        next: (res: any) => {
          this.api.loadingDismiss();
          if (res.success) {
            this.userPvzs = res.items;
            
            if (this.editingItem) {
              this.dataForm.patchValue({ pvz_id: this.editingItem.pvz_id });
            } else if (this.userPvzs.length > 0) {
              this.dataForm.get('pvz_id').setValue(this.userPvzs[0].id);
              this.fakePvzAddress = this.userPvzs[0].pvz_address;
            }
          } else {
            this.api.toastPresent('Не удалось загрузить список ПВЗ. Повторите попытку позже.');
            return this.modalCtrl.dismiss(null, 'close');
          }
        }
      });
  }

  calculateSumm() {
    if (this.dataForm.get('value').value && this.dataForm.get('price').value)
      this.dataForm.patchValue({ summ: this.dataForm.get('value').value * this.dataForm.get('price').value })
    else
      this.dataForm.get('summ').setValue(null);
  }

  async showSelect(type: string) {
    let modal;
    switch (type) {
      case 'pvz':
        modal = await this.modalCtrl.create({
          component: PvzSelectComponent,
          componentProps: { id: this.dataForm.get('pvz_id').value },
          cssClass: 'pvz-select-modal',
        });
        await modal.present();
    
        var { data, role } = await modal.onDidDismiss();
    
        if (role == 'pvz') {
          this.dataForm.patchValue({ pvz_id: data.item.id })
          this.fakePvzAddress = data.item.pvz_address
        }
        break;
    }
  }

  async save() {
    if (this.dataForm.valid) {
      let data = { ...this.dataForm.value };
      
      if (this.editingItem) {
        data.id = this.editingItem.id;
      }

      if (!data.important)
        data.important = 0;
      
      await this.api.loadingPresent();

      this.api.post('request', data, true)
        .subscribe({
          next: async (res: any) => {
            this.api.loadingDismiss();
            if (res.success) {
              this.api.toastPresent(this.editingItem ? "Заявка успешно обновлена." : "Заявка успешно добавлена.");
              return this.modalCtrl.dismiss({ id: res.id }, 'saved');
            } else {
              this.api.toastPresent(res.message);
              if (res.code == 401) {
                return this.modalCtrl.dismiss(null, 'close');
              }
            }
          }
        });
    } else {
      this.api.toastPresent("Указаны некорректные данные в форме. Проверьте ещё раз введённую информацию.");
    }
  }

  close() {
    return this.modalCtrl.dismiss(null, 'close');
  }
}
