import { Component, Input, OnInit } from '@angular/core';
import { AlertController, ModalController } from '@ionic/angular';

import { SubPage } from '../../pages/sub/sub.page'

import { ApiService } from '../../services/api.service';
import { UserService } from '../../services/user.service';
import { YmService } from '../../services/ym.service';

@Component({
  selector: 'app-cameras-connect',
  templateUrl: './cameras-connect.component.html',
  styleUrls: ['./cameras-connect.component.scss'],
})
export class CamerasConnectComponent implements OnInit {

  @Input() pvzId: number;
  @Input() pvzAddress: string;
  step: number;
  nextDisabled: boolean
  items; // список камер ПВЗ
  cameras; // список всех камер сервиса
  services; // объект с сервисами для интеграций
  serviceName: string;
  canEditCameras: boolean;

  constructor(private alertCtrl: AlertController, private api: ApiService, private modalCtrl: ModalController, public user: UserService, private ym: YmService) {
    this.canEditCameras = this.user.data.role == 0 || this.user.checkPermissions([26])
    this.services = {
      'ipeye': null,
      'ivideon': null,
      'linkvideo': null
    }
  }

  async ngOnInit() {
    if (this.user.data.subActive) {
      if (!this.pvzId) {
        this.ym.hit('cameras-connect', { params: { title: 'Окно подключения видеосервисов' } });
        await this.api.loadingPresent();
        this.getIntegrations();
        this.step = 1
      } else {
        await this.api.loadingPresent()
        this.api.get(`pvz_cameras/${ this.pvzId }`)
          .subscribe({
            next: (res: any) => {
              if (res.success) {
                if (res.items?.length > 0) {
                  this.cameras = res.items
                }
              } else {
                this.api.toastPresent(res.message);
                if (res.code == 401) {
                  return this.modalCtrl.dismiss(null, 'close');
                }
              }
              if (!this.cameras && (this.user.data.role == 0 || this.user.checkPermissions([26]))) {
                this.getIntegrations();
              } else {
                this.api.loadingDismiss();
                if (this.cameras) {
                  document.getElementsByClassName('cameras-connect-modal')[0].classList.add('view-cameras')
                  // для ivideon получаем потоки с камер
                  if (this.cameras[0].service_name == 'ivideon') {
                    for (let i = 0; i < this.cameras.length; i++) {
                      this.getIvideonCameraLive(this.cameras[i])
                    }
                  }
                }

                this.step = 3
                this.ym.hit('cameras-select', { params: { title: 'Окно просмотра камер' } });
              }
            }
          });
      }
    } else {
      this.ym.hit('cameras-connect', { params: { title: 'Окно подключения видеосервисов' } });
      this.step = 1
    }
}

  getIntegrations() {
    this.api.get(`integrations`)
      .subscribe({
        next: (res: any) => {
          this.api.loadingDismiss();
          if (res.success) {
            for (let i = 0; i < res.items.length; i++) {
              if (Object.prototype.hasOwnProperty.call(this.services, res.items[i].service_name)) {
                this.services[res.items[i].service_name] = res.items[i].id
              }
            }
            this.step = 1
            this.ym.hit('cameras-select', { params: { title: 'Окно выбора камер' } });
          } else {
            this.api.toastPresent(res.message);
            if (res.code == 401) {
              return this.modalCtrl.dismiss(null, 'close');
            }
          }
        }
      });
  }

  async connect(type: string) {
    if (!this.user.data.subActive) {
      const modal = await this.modalCtrl.create({
        component: SubPage,
        componentProps: { isModal: true },
        backdropDismiss: true,
        cssClass: 'sub-modal'
      });
      modal.present();
      this.ym.reachGoal('SUB_SHOW_MODAL', { "Окно подписки показано": "Просмотр камер ПВЗ" })
      return this.api.toastPresent('Подключите тариф "ПРОФИ", чтобы просматривать камеры по каждому ПВЗ через pvzAdmin.');
    } else {
      let confirm;
      switch (type) {
        case 'ipeye':
          confirm = await this.alertCtrl.create({
            header: 'Интеграция с IPEYE',
            subHeader: 'Укажите ваш токен интеграции.',
            message: `Создайте токен в вашем Личном кабинете IPEYE в разделе "Интеграции" по кнопке "Добавить интеграцию" и скопируйте его в поле ниже. ${ this.pvzId ? 'Привязать камеры к конкретным ПВЗ можно будет на следующем шаге.' : 'Привязать камеры к пункту выдачи можно через меню ПВЗ в списке.' }`,
            inputs: [
              {
                type: 'text',
                placeholder: 'Токен интеграции IPEYE',
                value: '',
            
              }
            ],
            buttons: [{
                text: 'Отмена',
                role: 'cancel'
              },
              {
                text: 'Сохранить',
                role: 'confirm',
                handler: async (data) => {    
                  if (!data[0] || data[0] && (data[0].length < 1 || data[0].length > 128)) {
                    this.api.toastPresent('Укажите токен до 128 символов.');
                    return false;
                  }      
                  await this.api.loadingPresent();
                  this.api.post('integration', { service_name: type, token: data[0] }, true)
                    .subscribe({
                      next: (res: any) => {
                        this.api.loadingDismiss();
                        if (res.success) {
                          this.services[type] = res.id
                        } else {
                          this.api.toastPresent(res.message);
                          if (res.code == 401) {
                            return this.modalCtrl.dismiss(null, 'close');
                          }
                        }
                      }
                    });
                },
              }
            ],
          });
          confirm.present();
          break;
        case 'linkvideo':
          confirm = await this.alertCtrl.create({
            header: 'Интеграция с LinkVideo',
            subHeader: 'Укажите ваш токен интеграции.',
            message: `Убедитесь, что у камер указано место установки ПВЗ. Перейдите в "Профиль" - "Интеграция с pvzAdmin" - "Сгенерировать токен" и скопируйте полученный токен в поле ниже. ${ this.pvzId ? 'Привязать камеры к конкретным ПВЗ можно будет на следующем шаге.' : 'Привязать камеры к ПВЗ можно через меню этого пункта в списке.' }`,
            inputs: [
              {
                type: 'text',
                placeholder: 'Токен интеграции LinkVideo',
                value: '',
            
              }
            ],
            buttons: [{
                text: 'Отмена',
                role: 'cancel'
              },
              {
                text: 'Сохранить',
                role: 'confirm',
                handler: async (data) => {    
                  if (!data[0] || data[0] && (data[0].length < 1 || data[0].length > 128)) {
                    this.api.toastPresent('Укажите токен до 128 символов.');
                    return false;
                  }      
                  await this.api.loadingPresent();
                  this.api.post('integration', { service_name: type, token: data[0] }, true)
                    .subscribe({
                      next: (res: any) => {
                        this.api.loadingDismiss();
                        if (res.success) {
                          this.services[type] = res.id
                        } else {
                          this.api.toastPresent(res.message);
                          if (res.code == 401) {
                            return this.modalCtrl.dismiss(null, 'close');
                          }
                        }
                      }
                    });
                },
              }
            ],
          });
          confirm.present();
          break;
        case 'ivideon':
          confirm = await this.alertCtrl.create({
            header: 'Интеграция с Ivideon',
            message: `Сейчас вы будете перенаправлены в ваш личный кабинет Ivideon. После авторизации нажмите на кнопку "Разрешить" на запрос получения доступа сервису pvzAdmin. Продолжить?`,
            buttons: [{
                text: 'Отмена',
                role: 'cancel'
              },
              {
                text: 'Продолжить',
                role: 'confirm',
                handler: async (data) => {
                  await this.api.loadingPresent();
                  this.api.post('integration', { service_name: type }, true)
                    .subscribe({
                      next: (res: any) => {
                        this.api.loadingDismiss();
                        if (res.success) {
                          window.open(res.url, "_self");
                        } else {
                          this.api.toastPresent(res.message);
                          if (res.code == 401) {
                            return this.modalCtrl.dismiss(null, 'close');
                          }
                        }
                      }
                    });
                },
              }
            ],
          });
          confirm.present();
          break;
      }
    }
  }

  async disconnect(service_name: string) {
    const confirm = await this.alertCtrl.create({
      message: `Вы уверены, что хотите отключить интеграцию с этим сервисом? Все привязанные к ПВЗ камеры будут недоступны. Вы всегда сможете повторно настроить интеграцию позже.`,
      buttons: [{
          text: 'Отмена',
          role: 'cancel'
        },
        {
          text: 'Отключить',
          role: 'confirm',
          handler: async () => {    
            await this.api.loadingPresent();
            this.api.post('integration_delete', { service_name }, true)
              .subscribe({
                next: (res: any) => {
                  this.api.loadingDismiss();
                  if (res.success) {
                    this.services[service_name] = null
                  } else {
                    this.api.toastPresent(res.message);
                    if (res.code == 401) {
                      return this.modalCtrl.dismiss(null, 'close');
                    }
                  }
                }
              });
          },
        }
      ],
    });
    confirm.present();
  }

  async showCameras(service_name: string) {
    this.nextDisabled = true;
    this.serviceName = service_name
    await this.api.loadingPresent();
    this.api.get('cameras_list', { service_name })
      .subscribe({
        next: (res: any) => {
          this.api.loadingDismiss();
          if (res.success) {
            this.items = res.items.map(i => { return { ...i, ...{ service_name } } })
            // если ивидеон, то для каждой камеры запрашиваем превью
            if (this.serviceName == 'ivideon') {
              for (let i = 0; i < this.items.length; i++) {
                this.api.get(`camera_preview`, { camera_id: this.items[i].id, token: this.items[i].token })
                  .subscribe({
                    next: (res: any) => {
                      if (res.success)
                        this.items[i].poster = res.poster
                    }
                  });
              }
            }
            this.step++;
          } else {
            this.api.toastPresent(res.message);
            if (res.code == 401) {
              return this.modalCtrl.dismiss(null, 'close');
            }
          }
        }
      });
  }

  selectCamera(item) {
    item.selected = !item.selected
  }

  selectedCamerasChanged() {
    let allUnchecked = true;
    for (let i = 0; i < this.items.length; i++) {
      if (this.items[i].selected) {
        allUnchecked = false;
        break;
      }
    }
    this.nextDisabled = allUnchecked;
  }

  async toStart() {
    await this.api.loadingPresent();
    this.getIntegrations();
    document.getElementsByClassName('cameras-connect-modal')[0].classList.remove('view-cameras')
    this.cameras = null;
    this.step = 1;
  }

  back() {
    this.step--;
  }

  async next() {
    switch (this.step) {
      case 2: // выбор камеры и сохранение списка
        await this.api.loadingPresent()
        this.cameras = this.items.filter(c => c.selected)

        let items = this.cameras
        this.api.post('pvz_cameras', { pvz_id: this.pvzId, items, service_id: this.services[this.serviceName] }, true)
          .subscribe({
            next: (res: any) => {
              this.api.loadingDismiss();
              if (res.success) {
                if (this.cameras.length > 0) {
                  document.getElementsByClassName('cameras-connect-modal')[0].classList.add('view-cameras')
                  this.step++;
                  
                  // для ивидеона получаем поток воспроизведения
                  if (this.serviceName == 'ivideon')
                    for (let i = 0; i < this.cameras.length; i++) {
                      this.getIvideonCameraLive(this.cameras[i])
                    }
                } else
                  this.modalCtrl.dismiss({ data: null, role: 'saved' })
              } else {
                this.api.toastPresent(res.message);
                if (res.code == 401) {
                  return this.modalCtrl.dismiss(null, 'close');
                }
              }
            }
          });
        break;
    }
  }

  getIvideonCameraLive(item) {
    this.api.get(`camera_live`, { camera_id: item.id, token: item.token })
      .subscribe({
        next: (res: any) => {
          if (res.success)
            item.video = res.video          
        }
      });
  }

  close() {
    this.modalCtrl.dismiss();
  }

}
