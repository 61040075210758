import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular'
import { PermissionsIconsListComponent } from './permissions-icons-list.component';

@NgModule({
	declarations: [
		PermissionsIconsListComponent,
	],
	imports: [
		CommonModule,
		IonicModule,
	],
	exports: [
		PermissionsIconsListComponent
	]
})

export class PermissionsIconsListModule {}
