import { Directive, ElementRef, Input, OnInit, Renderer2 } from '@angular/core';

@Directive({
    selector: '[scrollbar]'
})
export class ScrollbarDirective implements OnInit {

    @Input() scrollbar: string

    constructor(public elementRef: ElementRef, public renderer: Renderer2) { }

    ngOnInit() {
      const hostEl: Element = this.elementRef.nativeElement,
      styleElement: HTMLStyleElement = this.renderer.createElement('style');

      this.renderer.setProperty(styleElement, 'innerHTML', this.scrollbar || this.getCustomStyle());
      this.renderer.appendChild(hostEl.shadowRoot, styleElement);
    }

    getCustomStyle() {
      return `@media(pointer: fine) {
                ::-webkit-scrollbar {
                  width: 8px;
                  height: 8px;
                  z-index: 1;
                }
            
                ::-webkit-scrollbar-track:hover {
                  background: var(--ion-color-secondary-shade);
                }
                ::-webkit-scrollbar-thumb {
                  background: var(--ion-color-primary);
                }
                .inner-scroll {
                    scrollbar-width: thin;
                }
              }`
    }
}