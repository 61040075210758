import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { IonDatetime, ModalController } from '@ionic/angular';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DatePipe } from '@angular/common';

import { SalaryFormulaSelectComponent } from '../../modals/salary-formula-select/salary-formula-select.component'

import { ApiService } from '../../services/api.service';
import { UserService } from '../../services/user.service';
import { YmService } from '../../services/ym.service';


@Component({
  selector: 'app-users-edit',
  templateUrl: './users-edit.component.html',
  styleUrls: ['./users-edit.component.scss'],
})
export class UsersEditComponent implements OnInit {

  @ViewChild('dateEmploymentPicker') dateEmploymentPicker : IonDatetime
  @ViewChild('dateDismissedPicker') dateDismissedPicker : IonDatetime
  @ViewChild('dateBirthdayPicker') dateBirthdayPicker : IonDatetime
  @ViewChild('form_input') formInput: any;
  @Input() editingItem: any;
  dataForm: FormGroup;
  userPvzs: Array<any>;
  userRoles: Array<any>;
  showWBParams: boolean;
  formulas: any; // список формул смен
  formulas_salary: any; // список формул зп
  pvzRequired: boolean;
  roleRights;

  constructor(public api: ApiService, private datePipe: DatePipe, private formBuilder: FormBuilder, private modalCtrl: ModalController, public user: UserService, private ym: YmService) {
    this.roleRights = {};
  }

  ngOnInit() {
    this.ym.hit('users-edit', { params: { title: "Окно редактирования сотрудника" } });

    if (this.user.data.subActive)
      this.getFormulas(1);
    this.getFormulas(2);

    this.dataForm = this.formBuilder.group({
      name: ['', Validators.compose([Validators.required, Validators.maxLength(50)])],
      role: [2, Validators.required],
      status: [1, Validators.required],
      types: [],
      formula_id: [],
      formula_salary_id: [],
      shift_rate: [0, Validators.min(0)],
      shift_hour_rate: [0, Validators.min(0)],
      email: [, Validators.compose([Validators.email, Validators.maxLength(50)])],
      email2: [, Validators.compose([Validators.email, Validators.maxLength(50)])],
      tel: [],
      tel2: [],
      telegram: [, Validators.pattern(/^@?[a-zA-Z][a-zA-Z0-9_]{4,31}$/)],
      sex: [, Validators.compose([Validators.min(1), Validators.max(2)])],
      birthday: [, Validators.pattern(/(0[1-9]|[12][0-9]|3[01])\.(0[1-9]|1[0,1,2])\.(19|20)\d{2}/)],
      employment_date: [, Validators.pattern(/(0[1-9]|[12][0-9]|3[01])\.(0[1-9]|1[0,1,2])\.(19|20)\d{2}/)],
      dismissed_date: [, Validators.pattern(/(0[1-9]|[12][0-9]|3[01])\.(0[1-9]|1[0,1,2])\.(19|20)\d{2}/)],
      home_address: [, Validators.maxLength(100)],
      documents: [, Validators.maxLength(250)],
      agreement_type: [, Validators.maxLength(25)],
      comment: [, Validators.maxLength(250)],
      pvzs: [],
      money_data: [, Validators.maxLength(100)],
      rewrite_permissions: [],
      permission_shifts: [, Validators.min(0)],
      permission_salary: [, Validators.min(0)],
      permission_stats: [, Validators.min(0)],
      permission_money: [, Validators.min(0)],
      permission_requests: [, Validators.min(0)],
      permission_users: [, Validators.min(0)],
      permission_pvzs: [, Validators.min(0)],
      permission_history: [, Validators.min(0)],
      permission_plan: [, Validators.min(0)],
      permission_videocam: [, Validators.min(0)],
      permission_contests: [, Validators.min(0)],
    });

    if (!this.user.data.subActive) {
      this.dataForm.get('permission_shifts').disable();
      this.dataForm.get('permission_salary').disable();
      this.dataForm.get('permission_stats').disable();
      this.dataForm.get('permission_money').disable();
      this.dataForm.get('permission_requests').disable();
      this.dataForm.get('permission_users').disable();
      this.dataForm.get('permission_pvzs').disable();
      this.dataForm.get('permission_history').disable();
      this.dataForm.get('permission_plan').disable();
      this.dataForm.get('permission_videocam').disable();
      this.dataForm.get('permission_contests').disable();
      this.dataForm.get('rewrite_permissions').disable();
    }

    if (this.editingItem) {
      delete this.editingItem.pvzs; // удаляем список прикрепленных пвз, чтобы не мешал при проверке на сервере
      if (this.editingItem.birthday)
        this.editingItem.birthday = this.datePipe.transform(this.editingItem.birthday, 'dd.MM.yyyy')
      if (this.editingItem.employment_date)
        this.editingItem.employment_date = this.datePipe.transform(this.editingItem.employment_date, 'dd.MM.yyyy')
      if (this.editingItem.dismissed_date)
        this.editingItem.dismissed_date = this.datePipe.transform(this.editingItem.dismissed_date, 'dd.MM.yyyy')
      

      // если есть переопределенные права, то заполняем форму
      if (this.editingItem.permissions != null) {
        this.dataForm.patchValue({ rewrite_permissions: true });
        this.editingItem = this.user.decodePermissions(this.editingItem)
      }
      
      this.editingItem.types = []
      for (const type in this.api.pvzTypes) {
        if (Object.prototype.hasOwnProperty.call(this.api.pvzTypes, type)) {
          if (this.editingItem.type & parseInt(type))
            this.editingItem.types.push(type)
        }
      }
    }

    // если новый ссотрудник или редачим с неперепоределенными правами, то блокируем их на изменение
    this.rewritingRights();

    // если администратор, то прикрепление к ПВЗ обязательное поле, иначе не сможет потом с сотрудником ничего сделать
    if (this.user.data.role != 0 && !this.editingItem && this.user.checkPermissions([21])) {
      this.pvzRequired = true;
      this.dataForm.get('pvzs').addValidators(Validators.required);
      this.dataForm.get('pvzs').updateValueAndValidity();
    }

    if (this.editingItem) {
      this.dataForm.patchValue(this.editingItem);
      if (!this.user.data.subActive)
        this.dataForm.get('formula_salary_id').setValue(null)
    }

    // у рука нельзя удалять почту
    if (this.editingItem && this.user.data.role == 0 && this.editingItem.id == this.user.data.id) {
      this.dataForm.get('email').addValidators(Validators.required);
      this.dataForm.get('email').updateValueAndValidity();
    }

    this.selectedTypes();
  }

  ionViewDidEnter() {
    this.getRoles();
    if (!this.editingItem)
      this.getPvzs();
    setTimeout(() => this.formInput.setFocus(), 200);
  }

  private getFormulas(type: number) {
    if (type == 2)
      this.api.getFormulas(type).subscribe((res: any) => {
        if (res.success) this.formulas = res.items; else {
          this.api.toastPresent('Не удалось загрузить список формул для расчёта. Повторите попытку позже.');
          this.close();
        }
      });
    else
      this.api.getFormulas(type).subscribe((res: any) => {
        if (res.success) this.formulas_salary = res.items; else {
          this.api.toastPresent('Не удалось загрузить список формул для расчёта. Повторите попытку позже.');
          this.close();
        }
      });
  }

  private getPvzs() {
    this.api.getPvzs().subscribe((res: any) => {
      if (res.success) {
        if (res.items.length > 0) {
          this.userPvzs = res.items.sort((a: any, b: any) => { return a.name > b.name ? 1 : -1 });
          if (res.items.length == 1 && this.user.data.role != 0 && this.user.checkPermissions([21]) ) // если админ и 1 пвз, то сразу его указываем, т.к. прикрепление для админа обязательно
            this.dataForm.patchValue({ pvzs: [this.userPvzs[0].id] })
        } else if (this.user.data.role != 0 && this.user.checkPermissions([21]) ) {
          this.api.toastPresent('Вы пока не прикреплены ни к одному ПВЗ и не можете добавлять сотрудников. Обратитесь к руководителю, чтобы он вас прикрепил к нужным ПВЗ.');
          this.close();  
        }
      } else {
        this.api.toastPresent('Не удалось загрузить список ПВЗ. Повторите попытку позже.');
        this.close();
      }
    });
  }

  private getRoles() {
    this.api.getRoles().subscribe((res: any) => {
      if (res.success) {
        this.userRoles = res.items;
        this.updateRoleRights();
      }
      else {
        this.api.toastPresent('Не удалось загрузить список должностей. Повторите попытку позже.');
        this.close();
      }
    });
  }

  updateRoleRights() {
    this.roleRights = this.userRoles.find(r => r.id == this.dataForm.value.role)

    if (this.roleRights)
      this.roleRights = this.user.decodePermissions(this.roleRights);
  }

  rewritingRights() {
    if (!this.dataForm.value.rewrite_permissions) {
      this.dataForm.get('permission_shifts').disable();
      delete this.editingItem?.permission_shifts
      this.dataForm.get('permission_salary').disable();
      delete this.editingItem?.permission_salary
      this.dataForm.get('permission_stats').disable();
      delete this.editingItem?.permission_stats
      this.dataForm.get('permission_money').disable();
      delete this.editingItem?.permission_money
      this.dataForm.get('permission_requests').disable();
      delete this.editingItem?.permission_requests
      this.dataForm.get('permission_users').disable();
      delete this.editingItem?.permission_users
      this.dataForm.get('permission_pvzs').disable();
      delete this.editingItem?.permission_pvzs
      this.dataForm.get('permission_history').disable();
      delete this.editingItem?.permission_history
      this.dataForm.get('permission_plan').disable();
      delete this.editingItem?.permission_plan
      this.dataForm.get('permission_videocam').disable();
      delete this.editingItem?.permission_videocam
      this.dataForm.get('permission_contests').disable();
      delete this.editingItem?.permission_contests
      this.dataForm.patchValue({ permissions: null })      
    } else {
      this.dataForm.get('permission_shifts').enable();
      this.dataForm.get('permission_salary').enable();
      this.dataForm.get('permission_stats').enable();
      this.dataForm.get('permission_money').enable();
      this.dataForm.get('permission_requests').enable();
      this.dataForm.get('permission_users').enable();
      this.dataForm.get('permission_pvzs').enable();
      this.dataForm.get('permission_history').enable();
      this.dataForm.get('permission_plan').enable();
      this.dataForm.get('permission_videocam').enable();
      this.dataForm.get('permission_contests').enable();
    }
  }

  async selectFormula(type: number) {
    if (type == 1 && !this.user.data.subActive) {
      return this.api.toastPresent('Подключите тариф "ПРОФИ", чтобы автоматически рассчитывать по формулам зарплаты сотрудникам.');
    } else {
      const modal = await this.modalCtrl.create({
        component: SalaryFormulaSelectComponent,
        componentProps: { type, subActive: this.user.data.subActive, none: true, id: type == 1 ? this.dataForm.value.formula_salary_id : this.dataForm.value.formula_id },
        cssClass: 'salary-formula-select-modal shift-edit',
      });
      modal.present();
  
      const { data, role } = await modal.onWillDismiss();
  
      this.getFormulas(type);
      if (role === 'selected') {
        let obj: any = {}
        obj[type == 1 ? 'formula_salary_id' : 'formula_id'] = data.item.id
        this.dataForm.patchValue(obj)
      }
    }
  }

  selectedTypes() {
    if (!this.dataForm.value.types || this.dataForm.value.types && (this.dataForm.value.types.length == 0 || this.dataForm.value.types.includes(1))) {
      this.showWBParams = true;
    } else {
      this.showWBParams = false;
      this.dataForm.value.ready_reward = null;
    }
  }

  roleChanged() {
    this.updateRoleRights();
    if (!this.user.data.subActive && (this.dataForm.value.role == 1 || this.dataForm.value.role >= 20))
      this.dataForm.get('role').setErrors({ notValid: true })
    else
      this.dataForm.get('role').setErrors(null)
  }

  setCalendarEmploymentDate() {
    if (this.dataForm.get('employment_date').valid) {
      this.dateEmploymentPicker.reset(this.api.formatInputDate(this.dataForm.get('employment_date').value, 'yyyy-mm-dd'));
    }
  }

  setCalendarDismissedDate() {
    if (this.dataForm.get('dismissed_date').valid) {
      this.dateDismissedPicker.reset(this.api.formatInputDate(this.dataForm.get('dismissed_date').value, 'yyyy-mm-dd'));
    }
  }

  setCalendarBirthdayDate() {
    if (this.dataForm.get('birthday').valid) {
      this.dateBirthdayPicker.reset(this.api.formatInputDate(this.dataForm.get('birthday').value, 'yyyy-mm-dd'));
    }
  }

  setInputDate(e, type: string) {
    if (document.querySelector('.datetime-select.datetime-ready') && document.getElementsByClassName('month-year-picker-open').length == 0) {
      e.target.confirm(true)
      this.dataForm.get(type).setValue(this.api.formatInputDate(e.detail.value, 'dd.mm.yyyy'))
    }
  }

  async save() {
    //console.log(this.dataForm.value);
    //return;

    if (!this.user.data.subActive && this.dataForm.value.formula_salary_id)
      return this.api.toastPresent('Подключите тариф "ПРОФИ", чтобы автоматически рассчитывать по формулам зарплаты сотрудникам.');

    let user = this.editingItem ? { ...this.editingItem, ...this.dataForm.value } : { ...this.dataForm.value };

    if (user.types?.length > 0) {
      user.type = user.types.reduce((sum: string, type: string) => parseInt(sum) + parseInt(type))
      delete user.types;
    } 

    if (this.dataForm.value.tel != null && this.dataForm.value.tel != "") {
      var phone = this.dataForm.value.tel.replace(/[^\d]/g, "");
      if (phone.length >= 11 && phone.length <= 12) {
        user.tel = phone[0] == 8 ? '7' + phone.slice(1) : phone;
        this.dataForm.get('tel').setErrors(null);
      } else {
        this.dataForm.get('tel').setErrors({ notValid: true })
        return;
      }
    }

    if (this.dataForm.value.tel2 != null && this.dataForm.value.tel2 != "") {
      var phone = this.dataForm.value.tel2.replace(/[^\d]/g, "");
      if (phone.length >= 11 && phone.length <= 12) {
        user.tel2 = phone[0] == 8 ? '7' + phone.slice(1) : phone;
        this.dataForm.get('tel2').setErrors(null);
      } else {
        this.dataForm.get('tel2').setErrors({ notValid: true })
        return;
      }
    }

    if (this.dataForm.value.shift_rate && this.dataForm.value.shift_hour_rate) {
      this.dataForm.get('shift_rate').setErrors({ notValid: true });
      this.dataForm.get('shift_hour_rate').setErrors({ notValid: true });
      return;
    } else {
      this.dataForm.get('shift_rate').setErrors(null);
      this.dataForm.get('shift_hour_rate').setErrors(null);
    }

    if ((this.dataForm.value.role == 1 || this.dataForm.value.role >= 20) && !this.user.data.subActive) {
      return this.api.toastPresent('Подключите тариф "ПРОФИ", чтобы разрешить администраторам доступ к сервису и настроить гибкий ролевой доступ.');
    }

    user = this.user.calculatePermissions(user)

    if (this.dataForm.valid) {
      await this.api.loadingPresent();
      this.api.post('user', user, true)
        .subscribe({
          next: (res: any) => {
            this.api.loadingDismiss();
            if (res.success) {
              this.api.getUsers(null, true);
              return this.modalCtrl.dismiss({ id: res.id, skip_pvzs: !this.editingItem && user.pvzs && user.pvzs.length > 0, role: user.role }, 'saved');
            } else {
              this.api.toastPresent(res.message);
              if (res.code == 401) {
                return this.modalCtrl.dismiss(null, 'close');
              }
            }
          }
        });
    } else {
      this.api.toastPresent("Указаны некорректные данные в форме. Проверьте ещё раз указанную информацию.");
    }
  }

  close() {
    return this.modalCtrl.dismiss(null, 'close');
  }

}
