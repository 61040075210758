import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Platform } from '@ionic/angular';

import { ApiService } from '../../services/api.service';
import { EventsService } from '../../services/events.service';
import { UserService } from '../../services/user.service';
import { YmService } from '../../services/ym.service';

@Component({
  selector: 'app-settings-stats',
  templateUrl: './settings-stats.component.html',
  styleUrls: ['./settings-stats.component.scss'],
})
export class SettingsStatsComponent implements OnInit {

  dataForm: FormGroup;
  showMoneyData: boolean;
  showStatsData: boolean;

  constructor(private api: ApiService, private events: EventsService, private formBuilder: FormBuilder, public platform: Platform, public user: UserService, private ym: YmService) {
    this.showMoneyData = this.user.data.role == 0 || this.user.checkPermissions([14, 15])
    this.showStatsData = this.user.data.role == 0 || this.user.checkPermissions([11, 12, 13])
    this.dataForm = this.formBuilder.group({
      charts_unit: [],
      charts_style: [],
      charts_series: [],
      charts_height: [],
      chart_balance: [],
      chart_balance_width: [],
      chart_balance_order: [],
      chart_ready: [],
      chart_ready_width: [],
      chart_ready_order: [],
      chart_recieved: [],
      chart_recieved_width: [],
      chart_recieved_order: [],
      chart_returned: [],
      chart_returned_width: [],
      chart_returned_order: [],
      chart_users: [],
      chart_users_width: [],
      chart_users_order: [],
      chart_rating: [],
      chart_rating_width: [],
      chart_rating_order: [],
      chart_ready_reward: [],
      chart_ready_reward_width: [],
      chart_ready_reward_order: [],
      chart_rating_reward: [],
      chart_rating_reward_width: [],
      chart_rating_reward_order: [],
      chart_repack_reward: [],
      chart_repack_reward_width: [],
      chart_repack_reward_order: [],
      chart_bag_reward: [],
      chart_bag_reward_width: [],
      chart_bag_reward_order: [],
    });
    
    this.dataForm.patchValue(this.user.data.settings.statsSettings)
  }

  ngOnInit() {
    this.ym.hit('settings-stats', { params: { title: "Окно настроек раздела Статистика" } });
  }

  paramsChanged(redraw?: boolean, get_stats?: boolean) {
    this.user.data.settings.statsSettings = { ...this.user.data.settings.statsSettings, ...this.dataForm.value };
    this.api.syncUserData('statsSettings', true)
    // если поменяли юниты или стиль графика
    if (redraw)
      this.events.publishSettingsParamsChanged(get_stats);
  }

}
