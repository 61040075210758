import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'fake-select',
  templateUrl: './fake-select.component.html',
  styleUrls: ['./fake-select.component.scss'],
})
export class FakeSelectComponent implements OnInit {

  @Input() placeholder: string;
  @Input() value: string;

  constructor() { }

  ngOnInit() {}

}
