import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';

import { ApiService } from '../../services/api.service';
import { UserService } from '../../services/user.service';
import { YmService } from '../../services/ym.service';


@Component({
  selector: 'app-settings-users',
  templateUrl: './settings-users.component.html',
  styleUrls: ['./settings-users.component.scss'],
})
export class SettingsUsersComponent implements OnInit {

  dataForm: FormGroup;

  constructor(private api: ApiService, private formBuilder: FormBuilder, private user: UserService, private ym: YmService) { }

  ngOnInit() {
    this.ym.hit('settings-users', { params: { title: "Окно настроек раздела Сотрудники" } });
    this.dataForm = this.formBuilder.group({
      compact_list: [],
      show_today_events_icon: [],
    });
    
    this.dataForm.patchValue(this.user.data.settings.usersSettings)
  }

  paramsChanged() {
    this.user.data.settings.usersSettings = { ...this.user.data.settings.usersSettings, ...this.dataForm.value };
    this.api.syncUserData('usersSettings', true)
  }

}
