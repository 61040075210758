import { Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges, ViewEncapsulation } from '@angular/core';
import videojs from 'video.js';

declare let SLDP: any

@Component({
  selector: 'app-video-item',
  templateUrl: './video-item.component.html',
  styleUrls: ['./video-item.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class VideoItemComponent implements OnInit, OnChanges, OnDestroy {

  randId: string;
  @Input() options: {
    type: string,
    video: string,
    poster: string
  };
  player;

  constructor() {
    // генерим уникальный id для дат, чтобы при переключении нормально монтировалась кнопка и срабатывали события
    let chars = "0123456789ABCDEFGHIJKLMNOPQRSTUVWXTZabcdefghiklmnopqrstuvwxyz";
    this.randId = '';
    for (let i = 0; i < 4; i++) {
      let rnum = Math.floor(Math.random() * chars.length);
      this.randId += chars.substring(rnum, rnum + 1);
    }
  }

  initVideo() {
    if (this.options.video) {
      setTimeout(() => {
        switch (this.options.type) {
          case 'ipeye':
          case 'ivideon':
          case 'linkvideo':
            this.player = videojs('video-' + this.randId, { 
              sources: [{ 
                src: this.options.video, 
                poster: this.options.poster, 
                type: 'application/x-mpegURL' 
              }], 
              autoplay: true, 
              controls: true, 
              fluid: true,
              language: 'ru'
            });
            break;
          case 'linkvideo-sldp':
            this.player = SLDP.init({ 
              container: 'video-' + this.randId, 
              splash_screen: this.options.poster,
              stream_url: this.options.video,
              adaptive_bitrate: { initial_rendition: '240p' },
              buffering: 500,
              autoplay: true,
              width: 'parent'
            });
            break;
        }
      }, 200);
    }
  }

  ngOnInit() {
    this.initVideo()
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes?.options?.currentValue?.video)
      this.initVideo();
  }

  ngOnDestroy() {
    if (this.player) {
      switch (this.options.type) {
        case 'ipeye':
        case 'ivideon':
        case 'linkvideo':
          this.player.dispose();   
          break;
        case 'linkvideo-sldp':
          this.player.destroy();
          break;
      }
    }
  }
}