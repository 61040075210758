import { Injectable } from '@angular/core';
import { Location } from '@angular/common';

import { ApiService } from './api.service';
import { UserService } from './user.service';
import { environment } from '../../environments/environment';

declare let document: any;
declare let ym: any;

@Injectable({
  providedIn: 'root'
})
export class YmService {

  private isLoaded: boolean;
  private isInitialized: boolean;
  private metrikaID: number;

  constructor(private api: ApiService, private location: Location, private user: UserService) {
    this.metrikaID = 94380646;

    this.isLoaded = typeof ym !== 'undefined' && environment.production;
    if (this.isLoaded)
      document.addEventListener(`yacounter${ this.metrikaID }inited`, () => {
        this.isInitialized = true;
      });

    this.initialize();
  }

  /**
   * Инициализация счетчика с параметрами пользовательской сессии
   * @link https://yandex.ru/support/metrica/code/counter-initialize.html
   */
  initialize() {
    if (this.isLoaded) { 
      if (!this.isInitialized) {
        let params: any = { clickmap: true, trackLinks: true, accurateTrackBounce: true, triggerEvent: true, params: { "Версия": this.api.appVer, "Подписка": this.user.data.subActive ? (this.user.data.subPrice ? 'Тариф' : 'Триал') : "Нет", "Лицензий": this.user.data.subLicenses ? this.user.data.subLicenses : 0, "Пуши": this.user.data.pushAllowed ? "Включены" : "Выключены" } }
        if (this.user.data.id)
          params.userParams = this.buildUserParams();
        ym(this.metrikaID, "init", params);
      }
    }
  }

  /**
   * Собирает объект с параметрами пользовательской сессии
   * @returns объект с параметрами пользовательской сессии
   */
  private buildUserParams() {
    return { UserID: this.user.data.id, "Должность": this.user.data.roleName }
  }

  /**
   * Установка параметров пользовательской сессии
   * @link https://yandex.ru/support/metrica/objects/user-params.html
   */
  userParams() {
    if (this.isLoaded) {
      if (!this.isInitialized)
        this.initialize();
      ym(this.metrikaID, 'userParams', this.buildUserParams())
    }
  }

  /**
   * Достижение заранее добавленной в Метрике цели
   * @link https://yandex.ru/support/metrica/objects/reachgoal.html
   * @param target идентификатор цели
   * @param params параметры цели
   */
  reachGoal(target: string, params?: any) {
    if (this.isLoaded) {
      if (!this.isInitialized)
        this.initialize();
      ym(this.metrikaID, 'reachGoal', target, params)
    }
  }

  /**
   * Передача события о просмотре страницы
   * @link https://yandex.ru/support/metrica/objects/hit.html
   * @param action название действия на английском, добавится к url через #action
   * @param options объект с передаваемыми параметрами
   */
  hit(action?: string, options?: any) {
    if (this.isLoaded) {
      if (!this.isInitialized)
        this.initialize();
      ym(this.metrikaID, 'hit', `${ this.location.path()}${ action ? '#' + action : '' }`, options)
    }
  }
}
