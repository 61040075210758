import { DatePipe, NgClass, NgFor, NgIf } from "@angular/common";
import { Component, OnInit, ViewChild } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { Title } from "@angular/platform-browser";
import { ActivatedRoute, Router } from "@angular/router";
import { AlertController, NavController, Platform } from "@ionic/angular/standalone";
import {
  IonButton,
  IonCol,
  IonContent,
  IonGrid,
  IonIcon,
  IonInput,
  IonItem,
  IonLabel,
  IonList,
  IonNote,
  IonRow,
} from "@ionic/angular/standalone";
import { MaskitoDirective } from "@maskito/angular";
import { MaskitoElementPredicate, MaskitoOptions } from "@maskito/core";
import { maskitoPhoneOptionsGenerator } from "@maskito/phone";
import { addIcons } from "ionicons";
import { logInOutline } from "ionicons/icons";
import metadata from "libphonenumber-js/min/metadata";
import { environment } from "src/environments/environment";

import { TelConfirmationComponent } from "../../components/tel-confirmation/tel-confirmation.component";
import { ApiService } from "../../services/api.service";
import { ToolsService } from "../../services/tools.service";
import { UserService } from "../../services/user.service";
import { YmService } from "../../services/ym.service";

@Component({
  imports: [
    DatePipe,
    NgIf,
    FormsModule,
    NgFor,
    NgClass,
    IonContent,
    IonGrid,
    IonRow,
    IonCol,
    IonNote,
    IonList,
    IonItem,
    IonInput,
    IonButton,
    IonLabel,
    IonIcon,
    TelConfirmationComponent,
    MaskitoDirective,
  ],
  selector: "app-login",
  standalone: true,
  styleUrls: ["./login.page.scss"],
  templateUrl: "./login.page.html",
})
export class LoginPage implements OnInit {
  account: {
    attempts: number;
    code: string;
    id: number;
    login: string;
    pvz: string; // адрес пвз, если входим по ссылке ПВЗ
    tel: string;
  };
  accountData: any; // информация об аккаунте, полученная с бэкенда
  accounts: Array<any>;
  appName: string = environment.appTitle;
  currDate: Date;
  formError: string;
  @ViewChild("form_input") formInput: any;
  readonly phoneMask: MaskitoOptions = maskitoPhoneOptionsGenerator({
    countryIsoCode: "RU",
    metadata,
    strict: false,
  });
  step: string; // текущий шаг авторизации: 1 - ввод почты, 2 - подтверждение кодом, 3 - выбор пользователя
  private isNewUser: boolean; // для нового юзера при входе показываем подключение тарифа и подтверждаем номер
  private pvzUUID: string; // уникальный код ПВЗ, если входим по ссылке
  private widgetFailedToLoad: boolean; // загрузился или нет виджет подтверждения телефона

  constructor(
    private alertCtrl: AlertController,
    public api: ApiService,
    private aRouter: ActivatedRoute,
    private appTitle: Title,
    private navCtrl: NavController,
    private platform: Platform,
    private router: Router,
    private toolsService: ToolsService,
    public user: UserService,
    private ym: YmService,
  ) {
    this.pvzUUID = this.aRouter.snapshot.paramMap.get("uuid");
    if (this.pvzUUID) {
      this.currDate = new Date();
    }
    // вход на ПВЗ доступен только с компьютера
    if (this.pvzUUID && !this.platform.is("desktop")) {
      this.api.toastPresent("Вход по ссылке ПВЗ доступен только с компьютера на пункте выдачи.");
      this.router.navigate(["/login"], { replaceUrl: true });
    }
    addIcons({ logInOutline });
  }

  /**
   * Автоматическая проверка кода по мере ввода
   * @param type тип входа - по одноразовому коду или по ссылке ПВЗ
   */
  autologin(type: "pvz" | "user") {
    if (this.account.code) {
      if (/\d{4}/.test(this.account.code)) {
        if (type == "user") this.confirmCode();
        else if (type == "pvz" && (!this.account.login || this.account.login.length > 0)) this.confirmPincode();
      }
    }
  }

  /**
   * Проверка одноразового кода из почты и вход в ЛК
   */
  async confirmCode() {
    let code = this.account.code ? this.account.code.toString().trim() : "";
    if (/\d{4}/.test(code)) {
      await this.api.loadingPresent();
      this.api.post("v1/login", { code, login: this.account.login }, true).subscribe({
        next: (res: any) => {
          this.api.loadingDismiss();
          if (res.success) {
            this.account.attempts = null;

            if (res.accounts) {
              this.accounts = res.accounts;
              this.step = "SELECT_ACCOUNT";
            } else {
              // если новый собственник, то переходим к подтверждению номера телефона
              if (this.isNewUser && !this.widgetFailedToLoad) {
                this.accountData = res;
                this.step = "ENTER_TEL";
                setTimeout(() => this.formInput.setFocus(), 200);
              } else {
                this.doLogin(res);
              }
            }
          } else {
            this.ym.reachGoal("LOGIN_CODE_WRONG");
            this.account.code = "";
            this.account.attempts = res.login_attempts;
            setTimeout(() => this.formInput.setFocus(), 200);
          }
        },
      });
    } else {
      this.api.toastPresent("Код должен состоять из 4 цифр.");
    }
  }

  /**
   * Проверка введеного телефона/почты и переход к вводу пинкода при входе в ЛК по ссылке ПВЗ
   */
  confirmEmailTelPincode() {
    let code: string = this.account.code ? this.account.code.toString().trim() : "";
    if (/\d{4}/.test(code)) {
      if (this.toolsService.emailValid(this.account.login)) {
        this.account.login = this.account.login.trim().toLowerCase();
      } else if (this.toolsService.phoneValid(this.account.login)) {
        this.account.login = this.account.login.replace(/[^\d]/g, "");
      } else {
        return this.api.toastPresent(
          "Для входа в Личный кабинет укажите ваш адрес электронной почты или номер телефона.",
        );
      }

      this.confirmPincode();
    } else {
      this.api.toastPresent("Пинкод должен состоять из 4 цифр.");
    }
  }

  /**
   * Проверка пинкода и логин в систему при входе по ссылке ПВЗ
   */
  async confirmPincode() {
    let code = this.account.code ? this.account.code.toString().trim() : "";
    if (/\d{4}/.test(code)) {
      await this.api.loadingPresent();
      this.api
        .post(
          "v1/login/pvz",
          {
            code,
            id: this.account.id,
            login: this.account.login,
            pvz_uuid: this.pvzUUID,
          },
          true,
        )
        .subscribe({
          next: (res: any) => {
            this.api.loadingDismiss();
            if (res.success) {
              this.account.attempts = null;
              this.doLogin(res);
            } else {
              this.ym.reachGoal("LOGIN_CODE_WRONG");
              this.account.code = "";
              this.account.attempts = res.login_attempts;
              setTimeout(() => this.formInput.setFocus(), 200);
            }
          },
        });
    } else {
      this.api.toastPresent("Код должен состоять из 4 цифр.");
    }
  }

  confirmWidgetFailedToLoad() {
    this.widgetFailedToLoad = true;
  }

  /**
   * Вход в ЛК
   * @param res объект, полученный от бэкенда о пользователе
   */
  async doLogin(res: any) {
    await this.api.loadingPresent();
    this.ym.reachGoal("LOGIN");
    this.user.data.id = res.id;
    this.user.data.name = res.name;
    this.user.data.role = res.role;
    this.user.data.roleName = res.role_name;
    this.user.data.subType = res.sub_type;
    this.user.data.subActive = res.sub_active;
    this.user.data.showNPSButton = res.show_nps;
    if (this.pvzUUID) this.user.data.pvzUUID = this.pvzUUID;

    if (this.user.data.role == 0 || this.user.checkPermissions([30])) {
      this.user.data.subUntil = res.sub_until;
      this.user.data.subPrice = res.sub_price;
      this.user.data.subLicenses = res.sub_licenses;
      this.user.subPlans = res.sub_plans;
    }

    this.user.data.permissions = res.permissions;

    if (res.settings) {
      this.user.applySettings(res.settings);
    }

    this.user.saveUserData();

    this.api.setToken(res.token);

    this.ym.userParams();

    this.navCtrl.setDirection("root");
    this.router.navigate(["/app"], {
      replaceUrl: true,
      state: {
        isNewUser: this.isNewUser,
        needUpdate: res.update,
        noCheck: true,
      },
    });
    this.api.loadingDismiss();
  }

  /**
   * Получение списка сотрудников, прикрепленных к ПВЗ или у кого есть смена в этот день при входе по ссылке ПВЗ
   */
  async getPvzEmployees() {
    await this.api.loadingPresent();
    this.api.get(`v1/login/pvz/${this.pvzUUID}`).subscribe({
      next: async (res: any) => {
        this.api.loadingDismiss();

        if (res.success) {
          this.accounts = res.accounts;
          if (res.accounts[0]) this.account.pvz = res.accounts[0].pvz_address;
        }
      },
    });
  }

  ionViewDidEnter() {
    let title = "Вход в Личный кабинет";
    this.appTitle.setTitle(`${title} | ${environment.appTitle}`);
    this.ym.hit();
    if (this.formInput) setTimeout(() => this.formInput.setFocus(), 200);
  }

  readonly maskPredicate: MaskitoElementPredicate = async (el) => (el as HTMLIonInputElement).getInputElement();

  ngOnInit() {
    this.account = {
      attempts: null,
      code: "",
      id: null,
      login: "",
      pvz: "ПВЗ",
      tel: "",
    };

    if (this.pvzUUID) {
      // если входим по ссылке ПВЗ
      this.step = "ENTER_ON_PVZ_LINK";
      this.getPvzEmployees();
    } else {
      this.step = "ENTER_EMAIL";
      if (this.user.data.email) this.account.login = this.user.data.email;
    }
  }

  /**
   * Повторная отправка одноразового кода на почту
   */
  async resendCode() {
    await this.api.loadingPresent();
    this.account.attempts = null;
    this.account.code = "";
    this.api.post("v1/login", { login: this.account.login }, true).subscribe({
      next: (res: any) => {
        this.api.loadingDismiss();
        if (res.success) {
          this.api.toastPresent("Код для входа в Личный кабинет выслан повторно.");
        }
      },
    });
  }

  /**
   * Выбор аккаунта для входа, когда на одну почту зарегистрированы несколько ЛК
   * @param id id выбранного из списка акканту для входа
   */
  selectAccount(id: number) {
    this.api.post("v1/login", { login: this.account.login, owner: id }, true).subscribe({
      next: (res: any) => {
        this.api.loadingDismiss();
        if (res.success) {
          this.doLogin(res);
        }
      },
    });
  }

  /**
   * Выбор аккаунта для перехода на окно ввода пинкода и последующего входа в ЛК по ссылке ПВЗ
   * @param id id аккаунта для входа
   */
  selectPVZAccount(id: number) {
    if (id > 0) {
      this.account.id = id;
      this.step = "ENTER_PINCODE";
      setTimeout(() => this.formInput.setFocus(), 200);
    } else {
      this.step = "ENTER_ON_PVZ_LINK_UNKNOWN_USER";
      setTimeout(() => this.formInput.setFocus(), 200);
    }
  }

  /**
   * Проверка наличия зарегистрированной почты и показ окна регистрации или отправка одноразового кода на почту
   */
  sendCode() {
    this.account.login = this.account.login.trim().toLowerCase();
    if (this.toolsService.emailValid(this.account.login)) {
      this.formError = null;

      let doLogin = async (force: boolean) => {
        await this.api.loadingPresent();
        this.api.post("v1/login", { force, login: this.account.login }, true).subscribe({
          next: async (res: any) => {
            this.api.loadingDismiss();

            if (res.success) {
              if (res.new) {
                const alert = await this.alertCtrl.create({
                  buttons: [
                    {
                      handler: async () => {
                        this.ym.reachGoal("REGISTER_USER");
                        const alertt = await this.alertCtrl.create({
                          buttons: [{ text: "Я понял(а)" }],
                          header: "ВНИМАНИЕ!",
                          message: `Пожалуйста, дождитесь приглашения от вашего руководителя или сообщите ему адрес 
                            вашей электронной почты, чтобы он предоставил вам доступ в систему!`,
                        });

                        await alertt.present();
                      },
                      text: "Сотрудник",
                    },
                    {
                      handler: async () => {
                        const alertt = await this.alertCtrl.create({
                          buttons: [
                            {
                              role: "cancel",
                              text: "Отмена",
                            },
                            {
                              handler: () => {
                                this.ym.reachGoal("REGISTER_OWNER");
                                this.isNewUser = true;
                                doLogin(true);
                              },
                              role: "confirm",
                              text: "Зарегистрироваться",
                            },
                          ],
                          header: "ВНИМАНИЕ!",
                          message: `Вы действительно хотите зарегистрироваться в качестве собственника пункта выдачи? 
                            Если вы менеджер или другой сотрудник организации, 
                            дождитесь от руководителя приглашения в систему!`,
                        });

                        await alertt.present();
                      },
                      text: "Собственник",
                    },
                  ],
                  message: "В качестве кого вы хотите зарегистрироваться?",
                });

                await alert.present();
              } else {
                if (force) this.ym.reachGoal("REGISTER_REGISTERED");
                this.user.data.email = this.account.login;
                this.user.saveUserData();

                this.step = "CONFIRM_EMAIL";
                setTimeout(() => this.formInput.setFocus(), 200);
              }
            }
          },
        });
      };

      doLogin(false);
    } else {
      this.formError = "Проверьте корректность указанного адреса электронной почты.";
    }
  }

  /**
   * Переход к показу виджета подтверждения номера телефона при регистрации собственника
   */
  async showConfirmWidget() {
    if (this.toolsService.phoneValid(this.account.tel)) {
      this.api.setToken(this.accountData.token);
      await this.api.loadingPresent();
      this.api.post("v1/user/tel/check", { tel: this.account.tel }, true).subscribe({
        next: (res: any) => {
          if (res.success) {
            this.step = "CONFIRM_TEL";
          }
        },
      });
    } else {
      this.api.toastPresent("Проверьте корректность указанного номера телефона.");
    }
  }

  /**
   * Пропуск подтверждения номера телефона при регистрации собственника
   */
  async skipTelConfirmation() {
    const alert = await this.alertCtrl.create({
      buttons: [
        {
          cssClass: "not-important-button",
          handler: async () => {
            this.doLogin(this.accountData);
          },
          text: "Пропустить",
        },
        {
          role: "cancel",
          text: "Получить скидку",
        },
      ],
      message: `Уникальное предложение получить скидку на первую активацию тарифа "ПРОФИ"! 
        Вы действительно хотите пропустить?`,
    });

    await alert.present();
  }
}
