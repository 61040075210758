import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular'
import { ItemActionsComponent } from './item-actions.component';

@NgModule({
	declarations: [
		ItemActionsComponent
	],
	imports: [
		CommonModule,
		IonicModule
	],
	exports: [
		ItemActionsComponent
	]
})

export class ItemActionsModule {}
