import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class EventsService {
  // нет токена в запросах или токен протух
  private userNoauthSubject = new Subject<any>();
  publishUserNoauth() {
    this.userNoauthSubject.next(null);
  }
  getUserNoauth(): Subject<any> {
    return this.userNoauthSubject;
  }

  // включить пуши
  private initPushSubject = new Subject<any>();
  publishPushInit(reg: boolean) {
    this.initPushSubject.next(reg);
  }
  getPushInit(): Subject<any> {
    return this.initPushSubject;
  }

  // была изменена смена
  private initShiftSubject = new Subject<void>();
  publishShiftUpdated() {
    this.initShiftSubject.next();
  }
  getShiftUpdated(): Subject<any> {
    return this.initShiftSubject;
  }

  // были изменены параметры графиков
  private initSettingsParamsSubject = new Subject<boolean>();
  publishSettingsParamsChanged(reload_data: boolean) {
    this.initSettingsParamsSubject.next(reload_data);
  }
  getSettingsParamsChanged(): Subject<boolean> {
    return this.initSettingsParamsSubject;
  }
}
