import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { IonicModule } from '@ionic/angular';

import { SettingsComponent } from './settings.component';
import { SettingsUsersModule } from 'src/app/components/settings-users/settings-users.module';
import { SettingsPvzsModule } from 'src/app/components/settings-pvzs/settings-pvzs.module';
import { SettingsShiftsModule } from 'src/app/components/settings-shifts/settings-shifts.module';
import { SettingsStatsModule } from 'src/app/components/settings-stats/settings-stats.module';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    SettingsPvzsModule,
    SettingsShiftsModule,
    SettingsStatsModule,
    SettingsUsersModule,
  ],
  declarations: [
    SettingsComponent
  ]
})
export class SettingsModule {}
