import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AlertController, ModalController, PopoverController } from '@ionic/angular';
import { ItemActionsComponent } from 'src/app/popovers/item-actions/item-actions.component';

import { ApiService } from '../../services/api.service';
import { ShiftEditComponent } from 'src/app/modals/shift-edit/shift-edit.component';

@Component({
  selector: 'app-shift-request-item',
  templateUrl: './shift-request-item.component.html',
  styleUrls: ['./shift-request-item.component.scss'],
})
export class ShiftRequestItemComponent implements OnInit {

  @Input() item: any;
  @Input() shiftSalaryExists: boolean;
  @Input() canEditShift: boolean;
  @Input() formatShiftStatusName: any;
  @Output() approveRequest: EventEmitter<any> = new EventEmitter<any>();
  @Output() deleteRequest: EventEmitter<any> = new EventEmitter<any>();

  constructor(private api: ApiService, private alertCtrl: AlertController, private modalCtrl: ModalController, private popoverCtrl: PopoverController) { }

  ngOnInit() {}

  approve(id: number, approve: number, is_new?: boolean) {
    this.approveRequest.emit({ id, approve, is_new });
  }

  async menu(e, item) {
    const popover = await this.popoverCtrl.create({
      component: ItemActionsComponent,
      cssClass: 'item-action-popover',
      componentProps: { itemType: 'shift-request' },
      event: e,
    });
    await popover.present();

    const { data, role } = await popover.onDidDismiss();
    
    if (role == 'close')
      switch (data.action) {
        case 'edit':
          this.edit(item)
          break;
        case 'delete':
          this.delete(item);
          break;
      }
  }

  async edit(item) {
    let shift: any = {
      action: 'edit',
      user_id: item.added_by,
      pvz_id: item.pvz_id,
      date: item.date,
      can_del: true,
      requests_exists: true
    }
    this.api.loadingPresent();
    this.api.getFormulas(2).subscribe(async (res: any) => {
      if (res.success) {
        const modal = await this.modalCtrl.create({
          component: ShiftEditComponent,
          componentProps: { editingItem: shift ? { ...shift } : null, formulas: res.items }
        });
        this.api.loadingDismiss();
        await modal.present();
    
        const { data, role } = await modal.onDidDismiss();

        if (role != 'close')
          this.deleteRequest.emit(); // чтобы перезагрузить список
      } else {
        this.api.loadingDismiss();
        this.api.toastPresent('Не удалось загрузить список формул для расчёта смены. Повторите попытку позже.');
      }
    });
  }

  async delete(item) {
    const alert = await this.alertCtrl.create({
      message: 'Вы собираетесь удалить запрос сотрудника. Продолжить?',
      buttons: [{
          text: 'Отмена',
          role: 'cancel'
        },
        {
          text: 'Да',
          role: 'confirm',
          handler: () => {
            this.api.loadingPresent();
            this.api.post(`shift_request_delete`, { id: item.id }, true)
              .subscribe({
                next: (res: any) => {
                  this.api.loadingDismiss();
                  if (res.success) {
                    this.deleteRequest.emit();
                  } else {
                    this.api.toastPresent('Не удалось выполнить операцию. Повторите попытку позднее.');
                  }
                }
            });
          }
        }
      ],
    });
    await alert.present();
  }

}
