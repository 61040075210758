import { Injectable } from '@angular/core';
import { AlertController, ModalController } from '@ionic/angular';

import { MoneyDetailsEditComponent } from '../modals/money-details-edit/money-details-edit.component'

import { ApiService } from './api.service';
import { UserService } from './user.service';
import { YmService } from './ym.service';

import { CurrencyPipe, DatePipe } from '@angular/common';

@Injectable({
  providedIn: 'root'
})
export class MoneyService {

  constructor(private alertCtrl: AlertController, private api: ApiService, private currencyPipe: CurrencyPipe, private datePipe: DatePipe, private modalCtrl: ModalController, private user: UserService, private ym: YmService) {}

  /**
   * Получает объект с финансовой транзакцией по его id
   * @param id номер финансовой транзакции
   * @returns 
   */
  get(id: number): Promise<any> {
    return new Promise(async (resolve, reject) => {
      await this.api.loadingPresent();
      this.api.get(`money_details/1/0`, { money_id: id })
        .subscribe({
          next: async (res: any) => {
            this.api.loadingDismiss();
            if (res.success) {
              if (res.items[0]) {
                resolve(res.items[0])
              } else {
                resolve(false)
              }
            } else if (res.code != 401) {
              this.api.toastPresent(res.message);
              resolve(false)
            }
          }
        }
      );
    });
  }

  /**
   * Добавляет финансовую транзакцию для заявки сотрудника
   * @param item объект с данными для финансовой транзакции
   * @returns возвращает промис, чтобы можно было обработать результат на странице
   */
  addRequestTransaction(item) {
    return new Promise(async (resolve, reject) => {
      let componentProps = { editingItem: { value: item.summ, request_id: item.id, comment: `Заявка: ${ item.title }${ item.value ? ' ' + item.value + ' шт.' : "" }`, status: 1, type: 2, kind_id: 6, pvz_id: (item.pvz_id ? item.pvz_id : null), pvz_address: item.pvz_address } }
      resolve(await this.add(componentProps))
    });
  }

  /**
   * Добавляет финансовую транзакцию для начисленной зарплаты
   * @param item объект с данными для финансовой транзакции
   * @returns возвращает промис, чтобы можно было обработать результат на странице
   */
  addSalaryTransaction(item) {
    return new Promise(async (resolve, reject) => {
      const alertSelect = await this.alertCtrl.create({
        message: 'Создайте новую финансовую транзакцию о выплате зарплаты или привяжите её к добавленной ранее.',
        buttons: [ 
          {
            text: "Выбрать",
            handler: async () => {
              await this.api.loadingPresent();
              this.api.get('money_details/2/0', { export: true, kind: 5, type: 2, user_id: item.employee_id, date_to: new Date().toISOString().slice(0, 10) })
                .subscribe({
                  next: async (res: any) => {
                    this.api.loadingDismiss();
                    if (res.success) {
                      let items = [];
                      for (let i = 0; i < res.items.length; i++) {
                        items.push({ type: 'radio', value: res.items[i].id, label: `-${ this.currencyPipe.transform(res.items[i].value, 'RUB', 'symbol', '1.0-2', 'ru-RU') } – ${ this.datePipe.transform(res.items[i].date, 'd MMMM') }${ res.items[i].comment ? ` (${ res.items[i].comment })` : '' }` })
                      }

                      this.api.get('money_details/1/0', { export: true, kind: 5, type: 2, user_id: item.employee_id })
                        .subscribe({
                          next: async (res: any) => {
                            this.api.loadingDismiss();
                            if (res.success) {
                              for (let i = 0; i < res.items.length; i++) {
                                items.push({ type: 'radio', value: res.items[i].id, label: `-${ this.currencyPipe.transform(res.items[i].value, 'RUB', 'symbol', '1.0-2', 'ru-RU') } – ${ this.datePipe.transform(res.items[i].date, 'd MMMM') }${ res.items[i].comment ? ` (${ res.items[i].comment })` : '' }` })
                              }
                            }

                            if (items.length > 0) {
                              const alert = await this.alertCtrl.create({
                                message: 'Выберите финансовую транзакцию, чтобы привязать её к выплате:',
                                buttons: [
                                  {
                                    text: 'Отмена',
                                    role: 'cancel',
                                    handler: () => resolve(false)
                                  }, 
                                  {
                                    text: "Выбрать",
                                    handler: (data) => {
                                      this.api.post('salary_link_money', { id: item.id, money_id: data }, true)
                                      .subscribe({
                                        next: async (res: any) => {
                                          this.api.loadingDismiss();
                                          if (res.success) {
                                            this.api.toastPresent('Выплата успешно привязана к выбранной финансовой транзакции.');
                                            resolve('saved');
                                          } else {
                                            this.api.toastPresent('Не удалось выполнить привязку. Повторите попытку позже.');
                                            resolve(false)
                                          }
                                        }
                                      });
                                    }
                                  }
                                ],
                                inputs: items,
                              });
                          
                              await alert.present();
                            } else {
                              this.api.toastPresent('Подходящих финансовых транзакций не найдено.');
                              resolve(false)
                            }
                          }
                        });
                    } else {
                      this.api.toastPresent('Не удалось загрузить список финансовых транзакций. Повторите попытку позже.');
                      resolve(false)
                    }
                  }
                });
            }
          },
          {
            text: "Создать",
            handler: async () => {
              let status = new Date(item.formula.date) < new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate()) ? 1 : 2
              let componentProps = { editingItem: { salary_id: item.id, date: new Date().toISOString().slice(0, 10), value: item.values.salary_complete, type: 2, kind_id: 5, employee_id: item.formula.employee_id, comment: item.formula.comment }, status: this.user.data.subActive ? status : 1 }
              resolve(await this.add(componentProps))
            }
          }
        ]
      });
  
      await alertSelect.present();
    });
  }

  /**
   * Показывает окно добавления финансовой транзакции с переданными данными для вставки
   * @param componentProps 
   * @returns 
   */
  private add(componentProps): Promise<any> {
    return new Promise(async (resolve, reject) => {
      const modal = await this.modalCtrl.create({
        component: MoneyDetailsEditComponent,
        componentProps,
        cssClass: 'money-edit-modal'
      });
      modal.present();
  
      const { data, role } = await modal.onWillDismiss();
  
      if (role == 'saved')
        this.ym.reachGoal('MONEY_TRANSACTION_ADDED')

      resolve(role)
    })
  }

  /**
   * Загружает инфу о фин. тразакции и открывает окно редактирования
   * @param id номер транзакции
   * @returns промис с выполненным действием в модально окне
   */
  edit(id: number): Promise<any> {
    return new Promise(async (resolve, reject) => {
      let editingItem = await this.get(id);
      if (editingItem) {
        const modal = await this.modalCtrl.create({
          component: MoneyDetailsEditComponent,
            componentProps: { editingItem },
            cssClass: 'money-edit-modal'
        });
        modal.present();
    
        const { data, role } = await modal.onWillDismiss();
    
        resolve(role)
      } else {
        resolve(false)
      }
    });
  }

  /**
   * Удаляет транзакцию или повторяющиеся транзакции с предварительным подтверждением
   * @param item объект с финансовой транзакцией
   * @returns 
   */
  async delete(item): Promise<boolean> {
    return new Promise(async (resolve, reject) => {
      // удаление транзакции, при необходимости и всех последующих
      let deleteItem = async (recurrent_id?: number) => {
        await this.api.loadingPresent();
        let data: any = { id: item.id };
        if (recurrent_id)
          data.recurrent_id = recurrent_id;
        this.api.post('money_details_delete', data, true)
          .subscribe({
            next: (res: any) => {
              this.api.loadingDismiss();
              if (res.success) {
                this.ym.reachGoal('MONEY_TRANSACTION_DELETED')
                this.api.toastPresent(recurrent_id ? "Транзакции успешно удалены." : "Транзакция успешно удалена.");
                resolve(true)
              } else {
                this.api.toastPresent(res.message);
                resolve(false)
              }
            }
          });
      }

      if (!item.recurrent_period) {
        const alert = await this.alertCtrl.create({
          message: 'Вы действительно хотите удалить эту финансовую транзакцию?',
          buttons: [{
              text: 'Отмена',
              role: 'cancel'
            },
            {
              text: 'Удалить',
              role: 'confirm',
              handler: deleteItem
            }
          ],
        });
        await alert.present();
      } else {
        const alert = await this.alertCtrl.create({
          message: 'Вы собираетесь удалить повторяющуюся транзакцию. Вы хотите удалить из реестра только эту транзакцию или все последующие тоже?',
          buttons: [{
              text: 'Отмена',
              role: 'cancel'
            },
            {
              text: 'Только эту',
              role: 'confirm',
              handler: deleteItem
            },
            {
              text: 'Все последующие',
              role: 'confirm',
              handler: () => {
                deleteItem(item.recurrent_id)
              }
            }
          ],
        });
    
        await alert.present();
      }
    });
  }

}
