import { Injectable } from '@angular/core';
import { CanLoad, Router } from '@angular/router';
import { NavController } from '@ionic/angular';
import { Observable } from 'rxjs';
import { filter, map, take } from 'rxjs/operators';

import { ApiService } from '../services/api.service';

@Injectable({
  providedIn: 'root'
})
export class AutoLoginGuard implements CanLoad {
  
  constructor(private navCtrl: NavController, private api: ApiService, private router: Router) {}

  canLoad(): Observable<boolean> {
    return this.api.isUser.pipe(
      filter(val => val !== null),
      take(1),
      map(isAuthd => {
        if (isAuthd) {    
          this.navCtrl.setDirection('root');
          this.router.navigate(['/app'], { replaceUrl: true });
        } else {          
          return true;
        }
      })
    );
  }

}
