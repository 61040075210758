import { Component, OnInit } from '@angular/core';
import { ModalController, NavController } from '@ionic/angular';
import { Router } from '@angular/router';

import { PvzsEditComponent } from '../../modals/pvzs-edit/pvzs-edit.component'
import { UsersEditComponent } from '../../modals/users-edit/users-edit.component'
import { SalaryEditComponent } from '../../modals/salary-edit/salary-edit.component'
import { SalaryFormulaEditComponent } from '../../modals/salary-formula-edit/salary-formula-edit.component'
import { SalaryFormulaSalaryEditComponent } from '../../modals/salary-formula-salary-edit/salary-formula-salary-edit.component'
import { SubPage } from '../../pages/sub/sub.page'

import { ApiService } from '../../services/api.service';
import { StorageService } from '../../services/storage.service';
import { UserService } from '../../services/user.service';
import { YmService } from '../../services/ym.service';

@Component({
  selector: 'app-welcome-master',
  templateUrl: './welcome-master.component.html',
  styleUrls: ['./welcome-master.component.scss'],
})
export class WelcomeMasterComponent implements OnInit {

  step: number;
  savedStep: number; // на каком шагу остановили в памяти, чтобы не сохранять значения меньше этой
  nextDisabled: boolean;
  userPvzs: any;
  userUsers: any;
  userFormulas: any;
  userFormulasSalary: any;

  constructor(public api: ApiService, private modalCtrl: ModalController, private navCtrl: NavController, private router: Router, private storage: StorageService, public user: UserService , private ym: YmService) {}

  ngOnInit() {
    this.ym.hit('welcome-master', { params: { title: 'Окно первоначальной настройки' } });
    this.storage.get('welcomeStep').then(step => {
      if (step != null) {
        this.step = step
        this.savedStep = this.step;
        this.doStepAction();
      } else {
        this.step = 0
        this.storage.set('welcomeStep', 0);
      }
    });
  }

  private async getPvzs(force?: boolean) {
    this.nextDisabled = true;
    this.ym.hit('welcome-master-1', { params: { title: 'Окно первоначальной настройки: шаг 1' } });
    await this.api.loadingPresent();
    this.api.getPvzs(force)
      .subscribe({
        next: (res: any) => {
          this.api.loadingDismiss();
          if (res.success) {
            this.userPvzs = res.items;
            if (this.userPvzs.length > 0)
              this.nextDisabled = false;
          } else {
            this.api.toastPresent('Не удалось загрузить список ПВЗ. Повторите попытку позже.');
            return this.modalCtrl.dismiss(null, 'close');
          }
        }
      });
  }

  private async getUsers(force?: boolean) {
    this.ym.hit('welcome-master-2', { params: { title: 'Окно первоначальной настройки: шаг 2' } });
    this.nextDisabled = true
    await this.api.loadingPresent();
    this.api.getUsers(null, force)
      .subscribe({
        next: (res: any) => {
          this.api.loadingDismiss();
          if (res.success) {
            this.userUsers = res.items.sort((a: any, b: any) => { return a.name > b.name ? 1 : -1 });
            if (this.userUsers.length > 0)
              this.nextDisabled = false;
          } else {
            this.api.toastPresent('Не удалось загрузить список сотрудников. Повторите попытку позже.');
            return this.modalCtrl.dismiss(null, 'close');
          }
        }
      });
  }

  private async getFormulas(type: number, force?: boolean) {
    await this.api.loadingPresent();
    if (type == 2) {
      this.nextDisabled = false;
      this.ym.hit('welcome-master-3', { params: { title: 'Окно первоначальной настройки: шаг 3' } });
      this.api.getFormulas(type, force).subscribe((res: any) => {
        this.api.loadingDismiss();
        if (res.success) {
          this.userFormulas = res.items;
          if (this.userFormulas.length > 0)
            this.nextDisabled = false;
        } else {
          this.api.toastPresent('Не удалось загрузить список формул для расчёта. Повторите попытку позже.');
          this.close();
        }
      });
    } else {
      this.nextDisabled = false;
      this.ym.hit('welcome-master-4', { params: { title: 'Окно первоначальной настройки: шаг 4' } });
      this.api.getFormulas(type, force).subscribe((res: any) => {
        this.api.loadingDismiss();
        if (res.success) { 
          this.userFormulasSalary = res.items; 
        } else {
          this.api.toastPresent('Не удалось загрузить список формул для расчёта. Повторите попытку позже.');
          this.close();
        }
      });
    }
  }

  async addPvz(item?: any) {
    const modal = await this.modalCtrl.create({
      component: PvzsEditComponent,
      componentProps: item ? { editingItem: { ...item } } : null,
      cssClass: 'pvzs-edit-modal'
    });
    modal.present();

    const { data, role } = await modal.onWillDismiss();

    if (role === 'saved') {
      if (!item)
        this.ym.reachGoal('PVZ_ADDED', { "Тип добавленного ПВЗ": this.api.formatPvzType(data.type, 'full') })
    }
  }

  async addUser(item?: any) {
    const modal = await this.modalCtrl.create({
      component: UsersEditComponent,
      componentProps: item ? { editingItem: { ...item } } : null,
      cssClass: 'users-edit-modal'
    });
    modal.present();

    const { data, role } = await modal.onWillDismiss();

    if (role === 'saved') {
      if (!item)
        this.ym.reachGoal('USER_ADDED')
      this.getUsers(true);
    }
  }

  async addFormula(item?: any) {
    const modal = await this.modalCtrl.create({
      component: SalaryFormulaEditComponent,
      componentProps: item ? { editingItem: { ...item } } : null,
      cssClass: 'salary-edit-modal'
    });
    modal.present();

    const { data, role } = await modal.onWillDismiss();

    if (role === 'saved') {
      if (!item)
        this.ym.reachGoal('FORMULA_SHIFT_ADDED', { "Формула смены добавлена": "Форма сотрудника" })
      this.getFormulas(2, true)
    }
  }

  async addSalaryFormula(item?: any) {
    const modal = await this.modalCtrl.create({
      component: SalaryFormulaSalaryEditComponent,
      componentProps: item ? { editingItem: { ...item } } : null,
      cssClass: 'salary-edit-modal'
    });
    modal.present();

    const { data, role } = await modal.onWillDismiss();

    if (role === 'saved') {
      if (!item)
        this.ym.reachGoal('FORMULA_SALARY_ADDED', { "Формула зарплаты добавлена": "Форма сотрудника" })
      this.getFormulas(1, true)
    }
  }

  goToTable() {
    this.modalCtrl.dismiss();
    this.navCtrl.setDirection('root');
    this.router.navigate(['/app/shifts'], { replaceUrl: true })
  }

  async newSalary() {
    const modal = await this.modalCtrl.create({
      component: SalaryEditComponent,
      componentProps: { newSalary: true },
      cssClass: 'salary-edit-modal'
    });
    modal.present();

    const { data, role } = await modal.onWillDismiss();

    if (role === 'saved') {
      this.nextDisabled = false;
      this.ym.reachGoal('SALARY_ADDED')
    }
  }

  back() {
    if (this.step > 0) {
      this.nextDisabled = false;
      this.step--;
      switch (this.step) {
        case 1:
          this.getFormulas(2);
          break;
        case 2:
          if (this.user.data.subActive)
            this.getFormulas(1);
          break;
        case 3:
          this.getPvzs();
          break;
        case 4:
          this.getUsers();
          break;
      }
    }
  }

  migration() {
    window.open('https://forms.yandex.ru/cloud/66ea787b5d2a0622f7fe2669/', "_blank");
  }

  next() {
    this.step++;
    if (this.step > this.savedStep)
      this.storage.set('welcomeStep', this.step);
    this.doStepAction()
  }

  doStepAction() {
    switch (this.step) {
      case 1:
        this.getFormulas(2);
        break;
      case 2:
        if (this.user.data.subActive)
          this.getFormulas(1);
        break;
      case 3:
        this.getPvzs();
        break;
      case 4:
        this.getUsers();
        break;
      case 5:
        this.nextDisabled = this.savedStep <= 5;
        this.ym.hit('welcome-master-5', { params: { title: 'Окно первоначальной настройки: шаг 4' } });
        break;
      case 6:
        this.nextDisabled = this.savedStep <= 6;
        this.ym.hit('welcome-master-6', { params: { title: 'Окно первоначальной настройки: шаг 6' } });
        break;
    }
  }

  async buySub() {
    const modal = await this.modalCtrl.create({
      component: SubPage,
      componentProps: { isModal: true },
      backdropDismiss: true,
      cssClass: 'sub-modal'
    });
    modal.present();
  }

  close(finish?: boolean) {
    if (finish)
      this.storage.set('welcomeStep', 8);
    this.modalCtrl.dismiss(null, finish ? 'finish' : null);
  }

}
