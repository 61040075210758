import { Component, Input, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

import { SalaryFormulaSelectComponent } from '../../modals/salary-formula-select/salary-formula-select.component'

import { ApiService } from '../../services/api.service';
import { UserService } from '../../services/user.service';
import { YmService } from '../../services/ym.service';

@Component({
  selector: 'app-pvzs-edit',
  templateUrl: './pvzs-edit.component.html',
  styleUrls: ['./pvzs-edit.component.scss'],
})
export class PvzsEditComponent implements OnInit {

  @Input() editingItem: any;
  dataForm: FormGroup;
  timeCustomDays: boolean; // время работы одинаковое для всех дней
  formulas: any; // список формул
  pvzsGroups: any;

  constructor(public api: ApiService, private formBuilder: FormBuilder, private modalCtrl: ModalController, public user: UserService, private ym: YmService) {
    this.timeCustomDays = false;
  }

  ngOnInit() {
    this.ym.hit('pvzs-edit', { params: { title: "Окно редактирования ПВЗ" } });
    
    this.getFormulas();
    this.getGroups();

    this.dataForm = this.formBuilder.group({
      types: [, Validators.required],
      pvz_address: ['', [Validators.required, Validators.minLength(1), Validators.maxLength(100)]],
      //external_pvz_address: [, [Validators.minLength(1), Validators.maxLength(100)]],
      external_pvz_id: [],
      groups: [],
      tariff: [, Validators.min(0)],
      formula_id: [],
      shift_rate: [, Validators.min(0)],
      shift_employees: [1, [Validators.required, Validators.min(1), Validators.max(4)]],
      time: [{ lower: 9, upper: 21 }], // ежедневный режим
      time_1: [{ lower: 9, upper: 21 }],
      time_2: [{ lower: 9, upper: 21 }],
      time_3: [{ lower: 9, upper: 21 }],
      time_4: [{ lower: 9, upper: 21 }],
      time_5: [{ lower: 9, upper: 21 }],
      time_6: [{ lower: 9, upper: 21 }],
      time_7: [{ lower: 9, upper: 21 }],
      replace_reward: [],
      documents: [, Validators.maxLength(250)],
    });

    if (this.editingItem) {
      let workTime = this.editingItem.shifts_time.split(',');
      for (let i = 0; i < workTime.length; i++) {
        let dayTime = workTime[i].split('-');
        this.editingItem['time_' + (i + 1)] = { lower: dayTime[0].slice(0, dayTime[0].indexOf(':')), upper: dayTime[1].slice(0, dayTime[1].indexOf(':')) };
        if (this.editingItem['time_' + i] && this.editingItem['time_' + (i + 1)] && this.editingItem['time_' + i] != this.editingItem['time_' + (i + 1)]) {
          this.timeCustomDays = true;
        }
      }
      if (!this.timeCustomDays)
        this.editingItem['time'] = this.editingItem['time_1'];

      this.editingItem.types = []
      for (const type in this.api.pvzTypes) {
        if (Object.prototype.hasOwnProperty.call(this.api.pvzTypes, type)) {
          if (this.editingItem.type & parseInt(type))
            this.editingItem.types.push(type)
        }
      }

      this.dataForm.patchValue(this.editingItem);
    }
    if (!this.user.data.subActive)
      this.dataForm.get('shift_employees').setValue(1);
  }

  private getGroups() {
    this.api.getPvzsGroups().subscribe((res: any) => {
      if (res.success) this.pvzsGroups = res.items.sort((a: any, b: any) => { return a.title > b.title ? 1 : -1 });
      else {
        this.api.toastPresent('Не удалось загрузить список групп. Повторите попытку позже.');
        this.close();
      }
    });
  }

  getFormulas() {
    this.api.getFormulas(2).subscribe((res: any) => {
      if (res.success) {
        this.formulas = res.items;
      } else {
        this.api.toastPresent('Не удалось загрузить список формул для расчёта. Повторите попытку позже.');
        this.close();
      }
    });
  }

  pinFormatter(value: number) {
    return `${ value }:00`;
  }

  timeChanged() {
    this.dataForm.patchValue({
      time_1: this.dataForm.get('time').value,
      time_2: this.dataForm.get('time').value,
      time_3: this.dataForm.get('time').value,
      time_4: this.dataForm.get('time').value,
      time_5: this.dataForm.get('time').value,
      time_6: this.dataForm.get('time').value,
      time_7: this.dataForm.get('time').value
    });
  }

  async selectFormula() {
    const modal = await this.modalCtrl.create({
      component: SalaryFormulaSelectComponent,
      componentProps: { type: 2, subActive: this.user.data.subActive, none: true, id: this.dataForm.value.formula_id },
      cssClass: 'salary-formula-select-modal shift-edit',
    });
    modal.present();

    const { data, role } = await modal.onWillDismiss();

    this.getFormulas();
    if (role === 'selected') {
      this.dataForm.patchValue({ formula_id: data.item.id })
    }
  }

  pvzTypeSelected() {
    if (!this.dataForm.value.types.includes('1'))
      this.dataForm.patchValue({ external_pvz_id: null })
  }

  async save() {
    if (this.dataForm.valid) {
      if (!this.user.data.subActive && this.dataForm.value.shift_employees > 1)
        return this.api.toastPresent('Подключите тариф "ПРОФИ", чтобы добавить поддержку нескольких сотрудников на смене.')

      let pvz = Object.assign(this.editingItem ? this.editingItem : {}, this.dataForm.value);
      if (pvz.types?.length > 0) {
        pvz.type = pvz.types.reduce((sum: string, type: string) => parseInt(sum) + parseInt(type))
        delete pvz.types;
      }
      delete pvz.users;

      await this.api.loadingPresent();
      this.api.post('pvz', pvz, true)
        .subscribe({
          next: (res: any) => {
            this.api.loadingDismiss();
            if (res.success) {
              this.api.getPvzs(true);
              return this.modalCtrl.dismiss({ id: res.id, type: pvz.type }, 'saved');
            } else {
              this.api.toastPresent(res.message);
              if (res.code == 401) {
                return this.modalCtrl.dismiss(null, 'close');
              }
            }
          }
        });
    } else {
      this.api.toastPresent("Указаны некорректные данные в форме. Проверьте ещё раз указанную информацию.");
    }
  }

  close() {
    return this.modalCtrl.dismiss(null, 'close');
  }

}
