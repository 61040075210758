import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { FormBuilder, FormGroup } from '@angular/forms';

import { ApiService } from '../../services/api.service';
import { UserService } from '../../services/user.service';
import { YmService } from '../../services/ym.service';

import { CamerasConnectComponent } from 'src/app/modals/cameras-connect/cameras-connect.component';

@Component({
  selector: 'app-settings-pvzs',
  templateUrl: './settings-pvzs.component.html',
  styleUrls: ['./settings-pvzs.component.scss'],
})
export class SettingsPvzsComponent implements OnInit {

  dataForm: FormGroup;

  constructor(private api: ApiService, private formBuilder: FormBuilder, private modalCtrl: ModalController, private user: UserService, private ym: YmService) { }

  ngOnInit() {
    this.ym.hit('settings-pvzs', { params: { title: "Окно настроек раздела ПВЗ" } });
    this.dataForm = this.formBuilder.group({
      
    });
    
    this.dataForm.patchValue(this.user.data.settings.pvzsSettings)
  }

  paramsChanged() {
    this.user.data.settings.pvzsSettings = { ...this.user.data.settings.pvzsSettings, ...this.dataForm.value };
    this.api.syncUserData('pvzsSettings', true)
  }

  async showCamerasConnect() {
    const modal = await this.modalCtrl.create({
      component: CamerasConnectComponent
    });
    modal.present();
  }

}