import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { UserService } from 'src/app/services/user.service';
import { YmService } from '../../services/ym.service';

@Component({
  selector: 'app-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.scss'],
})
export class SettingsComponent implements OnInit {

  accessShifts: boolean;
  accessStats: boolean;
  accessUsers: boolean;
  accessPvzs: boolean;

  constructor(private modalCtrl: ModalController, public user: UserService, private ym: YmService) {
    this.accessPvzs = this.user.data.role == 0 || this.user.checkPermissions([25, 26])
    this.accessShifts = this.user.data.role == 0 || this.user.checkPermissions([4, 5])
    this.accessStats = this.user.data.role == 0 || this.user.checkPermissions([12, 13, 14, 15])
    this.accessUsers = this.user.data.role == 0 || this.user.checkPermissions([21, 22]) 
  }

  ngOnInit() {
    this.ym.hit('settings', { params: { title: "Окно настроек" } });
  }

  close() {
    this.modalCtrl.dismiss();
  }

}
