import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { ModalController } from '@ionic/angular';

import { UsersEditComponent } from '../../modals/users-edit/users-edit.component'

import { ApiService } from '../../services/api.service';
import { UserService } from '../../services/user.service';
import { YmService } from '../../services/ym.service';

@Component({
  selector: 'app-user-select',
  templateUrl: './user-select.component.html',
  styleUrls: ['./user-select.component.scss'],
})
export class UserSelectComponent implements OnInit {

  @ViewChild('search') searchInput: any;
  @Input() id: number;
  @Input() all: boolean;
  @Input() no: boolean;
  @Input() type: number; // специализация
  @Input() usersToFilter; // список пользователей для отфильтровки
  @Input() showArchive: boolean;
  @Input() date: string; // если нужно показать смены на определенную дату
  items: any;
  itemsNotFound: boolean;
  itemsLoading: boolean;
  private req: any;
  searchString: string;
  segment: number;
  canAddUser: boolean;

  constructor(public api: ApiService, private modalCtrl: ModalController, private user: UserService, private ym: YmService) {
    this.segment = 1;
    this.canAddUser = this.user.data.role == 0 || this.user.checkPermissions([21, 22])
  }

  ngOnInit() {
    this.ym.hit('user-select', { params: { title: 'Окно выбора сотрудника' } });
    this.getItems();
  }

  searchItems(e) {
    this.searchString = e.target.value.toLowerCase();
    this.getItems();
  }

  getItems() {
    this.itemsNotFound = false;
    this.itemsLoading = true;
    this.items = [];

    let params: any = { order_by: 'name', status: this.segment };
    if (this.type)
      params.type = this.type
    if (this.date)
      params.shifts_date = this.date

    if (this.req)
      this.req.unsubscribe();
    this.req = this.api.get('users', params)
      .subscribe({
        next: (res: any) => {
          this.itemsLoading = false;
          if (res.success) {
            if (res.items.length > 0) {
              if (this.usersToFilter) {
                let filteredUsers = [];
                for (let i = 0; i < res.items.length; i++) {
                  let userExists = false; // убираем пользователей ПВЗ, которые и так уже в списке показываются
                  for (let u = 0; u < this.usersToFilter.length; u++) {
                    if (res.items[i].id == this.usersToFilter[u].id) {
                      userExists = true;
                      break;
                    }
                  }
                  if (!userExists)
                    filteredUsers.push(res.items[i]);
                }
                res.items = filteredUsers
              }
              // показываем смены сотрудника, если они есть
              if (this.date) {
                res.items = res.items.sort((a: any, b: any) => (a.shifts != null ? Infinity : 1) - (b.shifts != null ? Infinity : 1));

                for (let i = 0; i < res.items.length; i++) {
                  if (res.items[i].shifts) {
                    res.items[i].shifts = res.items[i].shifts.split(';').map(s => {
                      let shiftData = s.split('|')
                      return { status: parseInt(shiftData[0]), time_start: shiftData[1] ? shiftData[1].split('-')[0].slice(0, 5) : null, time_end: shiftData[1] ? shiftData[1].split('-')[1].slice(0, 5) : null }
                    })
                  }
                }
              }
              // фильтруем результат, если ищу по названию
              if (this.searchString) {
                this.items = res.items.filter((i) => i.name.toLowerCase().indexOf(this.searchString) > -1);
              } else {
                this.items = res.items;
                if (this.items.length >= 10)
                  setTimeout(() => this.searchInput.setFocus(), 500);
              }

              this.itemsNotFound = false;
            } else {
              this.itemsNotFound = true;
            }
          } else {
            this.api.toastPresent('Не удалось загрузить список сотрудников. Повторите попытку позже.');
            this.modalCtrl.dismiss();
          }
        }
    });
  }

  async add() {
    const modal = await this.modalCtrl.create({
      component: UsersEditComponent,
      cssClass: 'users-edit-modal'
    });
    modal.present();

    const { data, role } = await modal.onWillDismiss();

    if (role === 'saved') {
      this.getItems()
    }
  }

  select(item) {
    return this.modalCtrl.dismiss({ item }, 'selected');
  }

  close() {
    return this.modalCtrl.dismiss(null, 'close');
  }

  // удаляет из DOM скрытый селект
  removeHiddenSelect() {
    let hidden = document.getElementsByClassName('hidden-select')[0]
    if (hidden)
      hidden.parentElement.removeChild(hidden);
  }

  ngOnDestroy() {
    this.removeHiddenSelect();
    if (this.req)
      this.req.unsubscribe()
  }
}