import { Component, Input, OnInit } from '@angular/core';
import { PopoverController } from '@ionic/angular';

@Component({
  selector: 'app-item-actions',
  templateUrl: './item-actions.component.html',
  styleUrls: ['./item-actions.component.scss'],
})
export class ItemActionsComponent implements OnInit {

  @Input() self: boolean;
  @Input() status: number;
  @Input() itemType: string;
  @Input() role: number;
  @Input() canEdit: boolean;
  @Input() canDelete: boolean;
  @Input() hasMoney: boolean;
  @Input() hasReward: boolean;
  @Input() hasAccessToSalary: boolean;
  @Input() hasAccessToCameras: boolean;
  @Input() userAttached: boolean;

  constructor(private popoverCtrl: PopoverController) { }

  ngOnInit() {
    setTimeout(() => {
      let popover = <HTMLElement>document.querySelector('ion-popover.item-action-popover');
      if (popover) {
        let popoverRect = popover.shadowRoot.querySelector('.popover-content').getBoundingClientRect();
        if (popoverRect.bottom > window.innerHeight) {
          popover.style.transition = 'top 300ms linear';
          popover.style.top = (popoverRect.top - (popoverRect.bottom - window.innerHeight) - 16) + 'px';
        }
      }
    }, 400);
  }

  do(action: string) {
    this.popoverCtrl.dismiss({ action }, 'close');
  }

}
