import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';

import { ApiService } from '../../services/api.service';
import { EventsService } from '../../services/events.service';
import { UserService } from '../../services/user.service';
import { YmService } from '../../services/ym.service';

@Component({
  selector: 'app-settings-shifts',
  templateUrl: './settings-shifts.component.html',
  styleUrls: ['./settings-shifts.component.scss'],
})
export class SettingsShiftsComponent implements OnInit {

  dataForm: FormGroup;

  constructor(private api: ApiService, private events: EventsService, private formBuilder: FormBuilder, private user: UserService, private ym: YmService) { }

  ngOnInit() {
    this.ym.hit('settings-shifts', { params: { title: "Окно настроек раздела График работ" } });
    this.dataForm = this.formBuilder.group({
      show_days_shifts: [],
      show_managers_shifts: [],
      show_icon_salary: [],
      show_icon_fact: [],
      show_icon_comment: [],
      show_shift_times: [],
      show_shift_hours: [],
      pvzs_per_page: [],
      show_hidden_users: [],
      shifts_from_today: [],
      show_shifts_registry: []
    });
    
    this.dataForm.patchValue(this.user.data.settings.shiftsSettings)
  }

  paramsChanged(reload?: boolean) {
    this.user.data.settings.shiftsSettings = { ...this.user.data.settings.shiftsSettings, ...this.dataForm.value };
    this.api.syncUserData('shiftsSettings', true)
    
    if (reload)
      this.events.publishSettingsParamsChanged(true);
  }

}
