import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-permissions-icons-list',
  templateUrl: './permissions-icons-list.component.html',
  styleUrls: ['./permissions-icons-list.component.scss'],
})
export class PermissionsIconsListComponent implements OnInit {

  @Input() item;

  constructor() { }

  ngOnInit() {}

}
