import { Route } from "@angular/router";

import { AuthGuard } from "./guards/auth.guard";
import { AutoLoginGuard } from "./guards/auto-login.guard";
import { LoginPage } from "./pages/login/login.page";

export const APP_ROUTES: Route[] = [
  {
    path: "",
    pathMatch: "full",
    redirectTo: "login",
  },
  {
    canMatch: [AuthGuard],
    loadChildren: () =>
      import("./pages/menu/menu.routes").then((m) => m.MENU_ROUTES),
    path: "app",
  },
  {
    canMatch: [AutoLoginGuard],
    component: LoginPage,
    path: "login",
  },
  {
    component: LoginPage,
    path: "login/pvz/:uuid",
  },
  {
    path: "**",
    redirectTo: "/login",
  },
];
