import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { AlertController, IonDatetime, ModalController } from '@ionic/angular';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

import { ApiService } from '../../services/api.service';
import { StorageService } from '../../services/storage.service';
import { UserService } from '../../services/user.service';
import { YmService } from '../../services/ym.service';

import { CurrencyPipe, DatePipe } from '@angular/common';

import { SalaryFormulaSelectComponent } from '../salary-formula-select/salary-formula-select.component'
import { UserSelectComponent } from '../../modals/user-select/user-select.component'

@Component({
  selector: 'app-salary-edit',
  templateUrl: './salary-edit.component.html',
  styleUrls: ['./salary-edit.component.scss'],
})
export class SalaryEditComponent implements OnInit {

  @ViewChild('datePicker') datePicker : IonDatetime
  @ViewChild('dateFromPicker') dateFromPicker : IonDatetime
  @ViewChild('dateToPicker') dateToPicker : IonDatetime
  @ViewChild('salary_ndfl_value') salaryNdflValueInput: any;
  @ViewChild('value') valueInput: any;
  @Input() editingItem: any;
  @Input() newSalary: boolean; // когда рассчитываем новую
  @Input() onlyEmployeeId: boolean; // когда рассчитываем из графика работ - передается только id сотрудника
  dataForm: FormGroup;
  userUsers: any;
  userFormulas: any;
  dataUpdated: string;
  dataUpdatedUserName: string;
  salaryBuilder: any; // объект с настройками расчета зарплатыи отображаемыми элементами
  reqR: any; // запрос наград
  reqO: any; // запрос переработки
  reqF: any; // запрос формулы
  reqP: any; // запрос прежних выплат
  onlyShow: boolean;
  welcomeStep: number;
  fakeUsername: string;
  canEdit: boolean;
  
  constructor(private alertCtrl: AlertController, public api: ApiService, private currencyPipe: CurrencyPipe, private datePipe: DatePipe, private formBuilder: FormBuilder, private modalCtrl: ModalController, private storage: StorageService, public user: UserService, private ym: YmService) {
    this.userUsers = [];
    this.userFormulas = [];
    this.canEdit = this.user.data.role == 0 || this.user.checkPermissions([9, 10])
  }

  async ngOnInit() {
    this.ym.hit('salary-edit', { params: { title: 'Окно начисления зарплаты' } });

    if (this.user.data.role == 0) {
      this.storage.get('welcomeStep').then(step => {
        this.welcomeStep = step ? step : 0
      });
    }

    // если просматриваем начисление с фин. транзакцией или это сотрудник, то все блокируем на изменение
    if (this.editingItem && this.editingItem.money_date || this.user.data.role != 0 && this.user.checkPermissions([6]) )
      this.onlyShow = true;

    this.dataForm = this.formBuilder.group({
      type: [1, Validators.required],
      date: [, [Validators.pattern(/(0[1-9]|[12][0-9]|3[01])\.(0[1-9]|1[0,1,2])\.(19|20)\d{2}/), Validators.required]], // дата начисления
      date_from: [, [Validators.pattern(/(0[1-9]|[12][0-9]|3[01])\.(0[1-9]|1[0,1,2])\.(19|20)\d{2}/), Validators.required]],
      date_to: [, [Validators.pattern(/(0[1-9]|[12][0-9]|3[01])\.(0[1-9]|1[0,1,2])\.(19|20)\d{2}/), Validators.required]],
      employee_id: [, Validators.required],
      formula_id: [],
      formula_rule: [],
      comment: [, Validators.maxLength(250)],
      note: [, Validators.maxLength(250)],
      salary_type: [, [Validators.min(1), Validators.max(2)]],
      salary_value_salary: [, Validators.min(0)], // фиксированный оклад
      salary_ndfl: [0, [Validators.min(0), Validators.max(30)]],
      salary_ndfl_value: [, Validators.min(-1)],
      salary_round: [0, [Validators.min(0), Validators.max(1000)]],
      salary_overhours: [, Validators.min(0)],
      salary_reward_fix: [, Validators.min(0)],
      salary_reward_proc: [, [Validators.min(0), Validators.max(100)]],
      salary_hold_fix: [, Validators.min(0)],
      salary_hold_proc: [, [Validators.min(0), Validators.max(100)]]
    });

    this.resetSalaryBuilder();

    if (this.editingItem) {
      if (this.newSalary) { // если хотим начислить запланированное
        let filteredItem = { ...this.editingItem };
        if (filteredItem.date)
          filteredItem.date = this.api.formatInputDate(filteredItem.date, 'dd.mm.yyyy')
        this.dataForm.patchValue(filteredItem);

        if (!this.user.data.subActive) {
          this.dataForm.get('formula_id').setValue(0)
          this.dataForm.get('formula_id').disable();
        }

        if (filteredItem.formula && this.user.data.subActive)
          this.parseFormula(filteredItem)
      } else { // если редактируем начисленное
        await this.api.loadingPresent();
        if (this.reqR)
          this.reqR.unsubscribe();
        this.reqR = this.api.get(`salary_details/${ this.editingItem.id }`)
          .subscribe({
            next: async (res: any) => {
              this.api.loadingDismiss();
              if (res.success) {
                this.parseFormula(res.item, true);

                if (this.onlyShow) {
                  this.dataForm.get('formula_id').disable();
                } else {
                  this.dataUpdated = res.item.added;
                  this.dataUpdatedUserName = res.item.added_name;
                }
              } else {
                this.api.toastPresent('Не удалось загрузить информацию о начислении. Повторите попытку позже.');
                this.close();
              }
            }
          });
      }
    }

    if (this.user.data.role == 0 || this.user.checkPermissions([9, 10]) ) {
      this.getUsers(this.onlyEmployeeId);
      if (this.user.data.subActive)
        this.getFormulas();
    }
  }

  resetSalaryBuilder() {
    this.salaryBuilder = {
      formula: { // параметры применяемой формулы
        salary_period: null, // дни выплаты зп, чтобы по-умолчанию подставлять период нужный
        formula: [], // распарсенные правила формулы, которые нужно будет применить
      },
      form: { // какие элементы показывать в форме для расчета
        salary_value_salary: false,
        salary_rewards: false,
        salary_salary: false,
        salary_ndfl: false,
        salary_round: false,
        salary_overhours: false,
        salary_reward_fix: false,
        salary_reward_proc: false,
        salary_hold_fix: false,
        salary_hold_proc: false
      },
      values: { // полученные значения при расчете
        formula_id: null,
        salary_value: 0, // сколько начислено всего
        salary_value_salary: 0, // сколько начислено фикс. оклада
        salary_previous: [], // какие выплаты нужно вычесть
        salary_previous_found: [], // какие выплаты за отчетный период были найдены
        salary_rewards: {},
        salary_overhours: [], // смены с переработкой
        salary_overhours_hours: 0, // количество часов переработки
        salary_overhours_reward: 0, // премия за переработку
        salary_reward: 0, // доплата
        salary_hold: 0, // штраф
        salary_ndfl: 0, // сумма вычета НДФЛ
        salary_ndfl_value: 0, // сумма для вычета НДФЛ
        salary_ndfl_full: true, // от всей суммы считать НДФЛ или нет
        salary_round: 0, // остаток от округления для вычета
        salary_complete: 0, // сумма сотруднику на руки
      }
    }
    this.resetSalaryRewards();

    this.dataForm.patchValue({
      salary_type: null,
      salary_value_salary: null,
      salary_ndfl: null,
      salary_ndfl_value: null,
      salary_round: null,
      salary_overhours: null,
      salary_reward_fix: null,
      salary_reward_proc: null,
      salary_hold_fix: null,
      salary_hold_proc: null,
      comment: null,
      note: null
    })
  }

  resetSalaryRewards() {
    this.salaryBuilder.values.salary_rewards = {  // сколько смен/премий/штрафов и суммы за них
      total: {}, // всего найденных премий, чтобы показать, что есть пропущенные
      salary: [], // список окладов
      rewards: [], // список премий
      holds: [], // список штрафов
      sum: { // начисленная сумма по типам
        salary: 0,
        rewards: 0,
        holds: 0
      },
    }
  }

  getUsers(emitUserChangedEvent?: boolean) {
    this.api.getUsers()
      .subscribe({
        next: (res: any) => {
          this.api.loadingDismiss();
          if (res.success) {
            this.userUsers = res.items.sort((a: any, b: any) => { return a.name > b.name ? 1 : -1 });

            if (this.editingItem && this.editingItem.employee_id) {
              let user = this.userUsers.find(u => u.id == this.editingItem.employee_id)
              if (user) {
                this.fakeUsername = user.name

                if (!this.dataForm.value.comment && user.money_data)
                  this.dataForm.get('comment').setValue(`Оплата: ${ user.money_data }`)

                if (emitUserChangedEvent)
                  this.userChanged();
              }
            }
          } else {
            this.api.toastPresent('Не удалось загрузить список сотрудников. Повторите попытку позже.');
            return this.modalCtrl.dismiss(null, 'close');
          }
        }
      });
  }

  /**
   * получает список неучтенных наград. если указан параметр showAlert, то получает весь список, чтобы дать выбрать, какие награды учесть в выплате
   * @param showAlert нужно ли показать список наград, чтобы выбрать какие учесть в зарплате
   */
  async getRewards(showAlert?: boolean) {
    //console.log(this.salaryBuilder.values.salary_rewards);
    if (this.dataForm.value.date_to) {
      await this.api.loadingPresent();

      let params: any = {
        user_id: this.dataForm.get('employee_id').getRawValue(),
        date_to: this.api.formatInputDate(this.dataForm.value.date_to, 'yyyy-mm-dd')
      }

      if (!showAlert) {
        if (this.newSalary)
          this.resetSalaryRewards();
        params.date_from = this.api.formatInputDate(this.dataForm.value.date_from, 'yyyy-mm-dd')
      }

      if (this.reqR)
        this.reqR.unsubscribe();
      this.reqR = this.api.get('salary_rewards_select', params)
        .subscribe({
          next: async (res: any) => {
            this.api.loadingDismiss();
            if (res.success) {

              let addRewards = (rewards) => {
                //console.log(rewards);
                for (let i = 0; i < rewards.length; i++) {
                  switch (rewards[i].type) {
                    case 1:
                      this.salaryBuilder.values.salary_rewards.rewards.push(rewards[i])
                      this.salaryBuilder.values.salary_rewards.sum.rewards += rewards[i].value;
                      break;
                    case 2:
                      this.salaryBuilder.values.salary_rewards.holds.push(rewards[i])
                      this.salaryBuilder.values.salary_rewards.sum.holds += rewards[i].value;
                      break;
                    case 3:
                      this.salaryBuilder.values.salary_rewards.salary.push(rewards[i])
                      this.salaryBuilder.values.salary_rewards.sum.salary += rewards[i].value;
                      break;
                  }
                }
                // записываем в общее начисленное значение сумму оклада (если есть) и всех найденных премий за период
                this.salaryBuilder.values.salary_value = (this.dataForm.value.salary_value_salary ? this.dataForm.value.salary_value_salary : 0) + this.salaryBuilder.values.salary_rewards.sum.rewards - this.salaryBuilder.values.salary_rewards.sum.holds + this.salaryBuilder.values.salary_rewards.sum.salary
                
                this.recalculateValue();
              }

              if (!showAlert) {
                // раскидываем оклады, премии и штрафы и суммируем общую сумму по типам
                if (this.newSalary) // только для новых начислений подставляем награды, иначе просто показываем кнопку для выбора наград, чтобы не перезаписать
                  addRewards(res.items);
                // сохраняем сколько всего вообще найдено за период неучтенных премий, чтобы предупредить об этом
                this.salaryBuilder.values.salary_rewards.total = res.total;
              } else {
                let rewards = [];
                for (let i = 0; i < res.items.length; i++) {
                  let rewardExists = false;
                  let rewardArray;
                  switch (res.items[i].type) {
                    case 1:
                      rewardArray = this.salaryBuilder.values.salary_rewards.rewards
                      break;
                    case 2:
                      rewardArray = this.salaryBuilder.values.salary_rewards.holds
                      break;
                    case 3:
                      rewardArray = this.salaryBuilder.values.salary_rewards.salary
                      break;
                  }
                  for (let k = 0; k < rewardArray.length; k++) {
                    if (res.items[i].id == rewardArray[k].id) {
                      rewardExists = true;
                      break;
                    }
                  }
                  if (!rewardExists)
                    rewards.push({ type: 'checkbox', value: res.items[i], label: `${ res.items[i].type == 2 ? '-' : (res.items[i].type == 1 ? '+' : '') }${ this.currencyPipe.transform(res.items[i].value, 'RUB', 'symbol', '1.0-2', 'ru-RU') } – ${ this.datePipe.transform(res.items[i].date, 'd MMMM') } • ${ res.items[i].pvz_address ? `${ this.api.formatPvzType(res.items[i].pvz_type, 'short') } | ${ res.items[i].pvz_address } • ` : '' }${ res.items[i].type == 1 ? 'Премия' : (res.items[i].type == 2 ? 'Штраф' : "Оклад") } ${ res.items[i].kind_name.toLowerCase() }${ res.items[i].comment ? ` (${ res.items[i].comment })` : '' }` })
                }

                if (rewards.length > 0) {
                  const alert = await this.alertCtrl.create({
                    message: 'Выберите начисления для учёта в зарплате:',
                    buttons: [
                      {
                        text: 'Отмена',
                        role: 'cancel'
                      }, 
                      {
                        text: "Добавить",
                        handler: (data) => {
                          addRewards(data);
                        }
                      },
                      {
                        text: "Добавить все",
                        handler: () => {
                          addRewards(rewards.map(r => r.value));
                        }
                      }
                    ],
                    inputs: rewards,
                  });
              
                  await alert.present();
                } else {
                  this.api.toastPresent('Неучтённые премии, штрафы и оклады не найдены.');
                }
              }
            } else {
              this.dataForm.patchValue({ date_to: null })
              this.api.toastPresent('Не удалось загрузить список начислений сотрудника. Повторите попытку позже.');
            }
          }
        });
    }
  }

  removeReward(type: string, idx: number) {
    this.salaryBuilder.values.salary_rewards.sum[type] -= this.salaryBuilder.values.salary_rewards[type][idx].value;
    this.salaryBuilder.values.salary_value -= this.salaryBuilder.values.salary_rewards[type][idx].value * (type == 'holds' ? -1 : 1);
    this.salaryBuilder.values.salary_rewards[type].splice(idx, 1);
    this.recalculateValue();
  }

  async getOverhours() {
    if (this.dataForm.value.date_from && this.dataForm.value.date_to) {
      await this.api.loadingPresent();
      this.salaryBuilder.values.salary_overhours = []
      this.salaryBuilder.values.salary_overhours_hours = 0
      this.salaryBuilder.values.salary_overhours_reward = 0
      if (this.reqO)
        this.reqO.unsubscribe();
      this.reqO = this.api.get('salary_overhours', { date_from: this.api.formatInputDate(this.dataForm.value.date_from, 'yyyy-mm-dd'), date_to: this.api.formatInputDate(this.dataForm.value.date_to, 'yyyy-mm-dd'), user_id: this.dataForm.get('employee_id').getRawValue() })
        .subscribe({
          next: (res: any) => {
            this.api.loadingDismiss();
            if (res.success) {
              this.salaryBuilder.values.salary_overhours_hours = res.hours
              this.salaryBuilder.values.salary_overhours = res.shifts

              this.recalculateValue();
            } else {
              this.api.toastPresent('Не удалось загрузить количество переработанных часов. Повторите попытку позже.');
            }
          }
        });
    }
  }

  removeOverhour(idx: number) {
    this.salaryBuilder.values.salary_overhours_hours -= this.salaryBuilder.values.salary_overhours[idx].overhours
    this.salaryBuilder.values.salary_overhours_reward -= Math.round((this.salaryBuilder.values.salary_overhours[idx].overhours * this.dataForm.value.salary_overhours + Number.EPSILON) * 100) / 100
    this.salaryBuilder.values.salary_overhours.splice(idx, 1);
    this.recalculateValue();
  }

  /**
   * Получает уже осуществлённые выплаты для вычета из расчёта. 
   * Если передан date_from, то автоматически вычтет все найденные выплаты из расчёта за выбранный период без необходимости ручного выбора.
   * @param in_selected_period искать начисленные выплаты только в отчетном периоде
   * @param show_select показать выбор начисленных выплат или нет
   */
  async getPreviousSalary(in_selected_period?: boolean, show_select?: boolean) {
    if (this.dataForm.value.date_to) {
      let params: any = { status: 1, previous: true, date_to: this.api.formatInputDate(this.dataForm.value.date, 'yyyy-mm-dd'), user_id: this.dataForm.get('employee_id').getRawValue() }
      if (in_selected_period)
        params.date_from = this.api.formatInputDate(this.dataForm.value.date_from, 'yyyy-mm-dd')
      if (show_select)
        await this.api.loadingPresent();
      if (this.reqP)
        this.reqP.unsubscribe();
      this.reqP = this.api.get('salary/0', params)
        .subscribe({
          next: async (res: any) => {
            this.api.loadingDismiss();
            if (res.success) {
                this.salaryBuilder.values.salary_previous_found = [];
                let salary = [];
                for (let i = 0; i < res.items.length; i++) {
                  let salaryExists = false;
                  for (let k = 0; k < this.salaryBuilder.values.salary_previous.length; k++) {
                    if (this.salaryBuilder.values.salary_previous[k].id == res.items[i].id || !this.newSalary && res.items[i].id != this.editingItem.id) {
                      salaryExists = true;
                      break;
                    }
                  }
                  // если выплата еще не учтена, то добавляем ее для выбора или показываем, что есть неучтенные выплаты для вычета из зп
                  if (!salaryExists) {
                    this.salaryBuilder.values.salary_previous_found.push({ id: res.items[i].id, date: res.items[i].date, value: res.items[i].salary_value, type: res.items[i].type })   
                    if (!in_selected_period || show_select)
                      salary.push({ type: 'checkbox', value: res.items[i], label: `${ this.currencyPipe.transform(res.items[i].salary_value, 'RUB', 'symbol', '1.0-2', 'ru-RU') } – ${ this.datePipe.transform(res.items[i].date, 'd MMMM') } • ${ res.items[i].type == 1 ? 'Зарплата' : "Дополнительня выплата" }` })
                  }
                }

              // если вызвали в ручном выборе
              if (!in_selected_period || show_select) {
                if (salary.length > 0) {
                  const alert = await this.alertCtrl.create({
                    message: 'При необходимости выберите начисленные ранее выплаты для вычета из зарплаты:',
                    buttons: [
                      {
                        text: 'Отмена',
                        role: 'cancel'
                      }, 
                      {
                        text: "Выбрать",
                        handler: (data) => {
                          for (let i = 0; i < data.length; i++) {
                            for (let k = 0; k < this.salaryBuilder.values.salary_previous_found.length; k++) {
                              if (this.salaryBuilder.values.salary_previous_found[k].id == data[i].id) {
                                this.salaryBuilder.values.salary_previous_found.splice(k, 1)
                                break;
                              }
                            }
                            this.salaryBuilder.values.salary_previous.push({ id: data[i].id, date: data[i].date, value: data[i].salary_value, type: data[i].type })
                          }
                          this.recalculateValue();
                        }
                      }
                    ],
                    inputs: salary,
                  });
              
                  await alert.present();
                } else {
                  this.api.toastPresent('Начисленные ранее выплаты не найдены.');
                }
              }
            } else {
              this.api.toastPresent('Не удалось загрузить список начисленных выплат. Повторите попытку позже.');
            }
          }
        });
    }
  }

  removePrevious(idx: number) {
    this.salaryBuilder.values.salary_previous.splice(idx, 1);
    this.recalculateValue();
    this.getPreviousSalary(true);
  }

  recalculateValue() {
    //console.log('начислено', this.salaryBuilder.values.salary_value);
    this.salaryBuilder.values.salary_complete = this.salaryBuilder.values.salary_value;

    if (this.salaryBuilder.values.salary_previous.length > 0) {
      //console.log('вычет прежних начислений', this.salaryBuilder.values.salary_complete);
      for (let i = 0; i < this.salaryBuilder.values.salary_previous.length; i++) {
        this.salaryBuilder.values.salary_complete -= this.salaryBuilder.values.salary_previous[i].value
      }
      //console.log('На руки', this.salaryBuilder.values.salary_complete); 
    }

    //console.log('Доплата за час переработки', this.dataForm.value.salary_overhours);
    //console.log('Часов переработки', this.salaryBuilder.values.salary_overhours_hours);
    if (this.salaryBuilder.form.salary_overhours) {
      this.salaryBuilder.values.salary_overhours_reward = Math.round(((this.dataForm.value.salary_overhours ? this.dataForm.value.salary_overhours : 0) * (this.salaryBuilder.values.salary_overhours_hours ? this.salaryBuilder.values.salary_overhours_hours : 0) + Number.EPSILON) * 100) / 100
      //console.log('Награда за переработку', this.salaryBuilder.values.salary_overhours_reward);

      this.salaryBuilder.values.salary_complete += this.salaryBuilder.values.salary_overhours_reward;
      //console.log('На руки', this.salaryBuilder.values.salary_complete); 
    }

    //console.log('Фиксированная доплата', this.dataForm.value.salary_reward_fix);
    if (this.salaryBuilder.form.salary_reward_fix) {
      this.salaryBuilder.values.salary_reward = this.dataForm.value.salary_reward_fix ? this.dataForm.value.salary_reward_fix : 0
      this.salaryBuilder.values.salary_complete += this.dataForm.value.salary_reward_fix;
      //console.log('На руки', this.salaryBuilder.values.salary_complete); 
    }

    //console.log('Доплата в процентах', this.dataForm.value.salary_reward_proc);
    if (this.salaryBuilder.form.salary_reward_proc) {
      this.salaryBuilder.values.salary_reward = this.salaryBuilder.values.salary_complete * (this.dataForm.value.salary_reward_proc ? this.dataForm.value.salary_reward_proc : 0) / 100;
      //console.log('Доплата от начисленного', this.salaryBuilder.values.salary_reward);

      this.salaryBuilder.values.salary_complete += this.salaryBuilder.values.salary_reward;
      //console.log('На руки', this.salaryBuilder.values.salary_complete); 
    }

    //console.log('Фиксированное удержание', this.dataForm.value.salary_hold_fix);
    if (this.salaryBuilder.form.salary_hold_fix) {
      this.salaryBuilder.values.salary_hold = this.dataForm.value.salary_hold_fix ? this.dataForm.value.salary_hold_fix : 0
      this.salaryBuilder.values.salary_complete -= this.dataForm.value.salary_hold_fix;
      //console.log('На руки', this.salaryBuilder.values.salary_complete); 
    }

    //console.log('Доплата в процентах', this.dataForm.value.salary_hold_proc);
    if (this.salaryBuilder.form.salary_hold_proc > 0) {
      this.salaryBuilder.values.salary_hold = this.salaryBuilder.values.salary_complete * (this.dataForm.value.salary_hold_proc ? this.dataForm.value.salary_hold_proc : 0) / 100;
      //console.log('Доплата от начисленного', this.salaryBuilder.values.salary_hold);

      this.salaryBuilder.values.salary_complete -= this.salaryBuilder.values.salary_hold;
      //console.log('На руки', this.salaryBuilder.values.salary_complete); 
    }

    //console.log('НДФЛ', this.dataForm.value.salary_ndfl);
    //console.log('Сумма для НДФЛ', this.dataForm.value.salary_ndfl_value);
    this.salaryBuilder.values.salary_ndfl = 0
    // если указан ндфл в форме
    if (this.dataForm.value.salary_ndfl && this.dataForm.value.salary_ndfl_value) {
      // если ндфл от всей суммы считаем
      if (this.dataForm.value.salary_ndfl_value == -1) {
        this.salaryBuilder.values.salary_ndfl = Math.round(this.salaryBuilder.values.salary_complete * (this.dataForm.value.salary_ndfl / 100))
        this.salaryBuilder.values.salary_ndfl_value = this.salaryBuilder.values.salary_complete;
      } else {// если насчитано меньше, чем указано к удержанию НДФЛ, то считаем от насчитанной суммы
        this.salaryBuilder.values.salary_ndfl = Math.round(this.dataForm.value.salary_ndfl_value * (this.dataForm.value.salary_ndfl / 100))
        this.salaryBuilder.values.salary_ndfl_value = this.dataForm.value.salary_ndfl_value
      }
      
      this.salaryBuilder.values.salary_ndfl = Math.round((this.salaryBuilder.values.salary_ndfl + Number.EPSILON) * 100) / 100
      //console.log('Удержанная НДФЛ', this.salaryBuilder.values.salary_ndfl);

      this.salaryBuilder.values.salary_complete -= this.salaryBuilder.values.salary_ndfl;
      //console.log('На руки', this.salaryBuilder.values.salary_complete);
    }

    //console.log('Округлить до', this.dataForm.value.salary_round);
    this.salaryBuilder.values.salary_round = 0;
    if (this.dataForm.value.salary_round) {
      this.salaryBuilder.values.salary_round = Math.round((this.salaryBuilder.values.salary_complete % this.dataForm.value.salary_round + Number.EPSILON) * 100) / 100
      //console.log('Остаток округления', this.salaryBuilder.values.salary_round);
      

      this.salaryBuilder.values.salary_complete -= this.salaryBuilder.values.salary_round;
      //console.log('На руки', this.salaryBuilder.values.salary_complete);
    }

    this.salaryBuilder.values.salary_complete = Math.round((this.salaryBuilder.values.salary_complete + Number.EPSILON) * 100) / 100
    //console.log('На руки', this.salaryBuilder.values.salary_complete);
  }

  // при смене пользователя подставляем формулу, если она есть
  userChanged() {
    if (this.newSalary && this.dataForm.get('type').getRawValue() == 1 && this.dataForm.get('employee_id').getRawValue()) {
      // получаем ранее выплаченные, чтобы их вычесть из расчета автоматически
      this.getPreviousSalary(true);
      // подставляем формулу для расчёта
      //console.log(this.dataForm.get('employee_id').getRawValue());
      let user = this.userUsers.find((u: any) => u.id == this.dataForm.get('employee_id').getRawValue());
      //console.log(user);
      
      if (!this.user.data.subActive) {
        this.dataForm.get('formula_id').setValue(0)
        this.dataForm.get('formula_id').disable();
      } else {
        if (user) {
          let formulaId = this.dataForm.get('formula_id').value;
          this.dataForm.get('formula_id').setValue(user.formula_salary_id ? user.formula_salary_id : user.role_formula_id)
          // костыль, т.к. при смене юзера и без смены формулы не срабатывает метод
          if (this.dataForm.get('formula_id').value == formulaId || formulaId == null)
            this.formulaChanged()
        } else
          this.dataForm.get('formula_id').setValue(0)
      }
    }
    if (this.dataForm.get('type').getRawValue() == 2)
      setTimeout(() => {
        this.valueInput.setFocus();
      }, 300);
  }

  newSalaryChanged(e: any) {
    //console.log(this.salaryBuilder.formula);
    
    if (e.detail.value == 1) {
      this.salaryBuilder.form.salary_rewards = false;
      this.resetSalaryRewards();
      this.salaryBuilder.form.salary_overhours = false;
      this.dataForm.get('salary_overhours').setValue(null);
      this.salaryBuilder.values.salary_overhours_hours = 0;
      this.salaryBuilder.values.salary_overhours_reward = 0;
      this.salaryBuilder.values.salary_overhours = []
      this.salaryBuilder.form.salary_value_salary = true;
      this.salaryBuilder.values.salary_value = (this.salaryBuilder.formula.salary_value_salary ? this.salaryBuilder.formula.salary_value_salary : 0) + this.salaryBuilder.values.salary_rewards.sum.rewards - this.salaryBuilder.values.salary_rewards.sum.holds + this.salaryBuilder.values.salary_rewards.sum.salary
      this.dataForm.patchValue({ salary_value_salary: this.salaryBuilder.formula.salary_value_salary ? this.salaryBuilder.formula.salary_value_salary : null })
      this.recalculateValue();
      this.dataForm.get('salary_value_salary').addValidators(Validators.required);

      setTimeout(() => {
        this.valueInput.setFocus();
      }, 300);
    }
    // для посменного расчета сразу получаем премии
    if (e.detail.value == 2) {
      this.dataForm.get('salary_value_salary').removeValidators(Validators.required);
      this.dataForm.patchValue({ salary_value_salary: null })
      this.salaryBuilder.form.salary_value_salary = false
      this.salaryBuilder.values.salary_value_salary = 0;
      this.getRewards();
    }
    this.dataForm.get('salary_value_salary').updateValueAndValidity();
  }

  // при смене типа выплаты
  typeChanged(e: any) {
    // salarybuilder сбросится на событии смены формулы
    if (e.detail.value == 1) { // если выбрали зарплату, то подставляем формулу сотрудника
      if (this.dataForm.get('employee_id').getRawValue()) {
        this.userChanged();
        // при переключении с 2 на 1, почему-то не срабатывает событие смены формулы
        if (this.user.data.subActive)
          this.formulaChanged();
      }
      this.dataForm.get('date_from').addValidators(Validators.required);
      this.dataForm.get('date_from').updateValueAndValidity();
      this.dataForm.get('date_to').addValidators(Validators.required);
      this.dataForm.get('date_to').updateValueAndValidity();
      this.dataForm.get('salary_value_salary').clearValidators();
      this.dataForm.get('salary_value_salary').updateValueAndValidity();
    } else if (e.detail.value == 2) {
      this.dataForm.get('salary_value_salary').addValidators(Validators.required);
      this.dataForm.get('salary_value_salary').updateValueAndValidity();
      this.dataForm.get('date_from').removeValidators(Validators.required);
      this.dataForm.get('date_from').updateValueAndValidity();
      this.dataForm.get('date_to').removeValidators(Validators.required);
      this.dataForm.get('date_to').updateValueAndValidity();
      this.dataForm.get('formula_id').setValue(null);
      
      this.dataForm.patchValue({ date: this.api.formatInputDate(new Date().toISOString(), 'dd.mm.yyyy') })
    }
  }

  setCalendarDate() {
    if (this.dataForm.get('date').valid)
      this.datePicker.reset(this.api.formatInputDate(this.dataForm.get('date').value, 'yyyy-mm-dd'));
  }

  setInputDate(e, type: string) {
    if (document.querySelector('.datetime-select.datetime-ready') && document.getElementsByClassName('month-year-picker-open').length == 0) {
      e.target.confirm(true)
      this.dataForm.get(type).setValue(this.api.formatInputDate(e.detail.value, 'dd.mm.yyyy'))
    }
  }

  dateChanged(type: string) {
    if (this.dataForm.get(type).valid && !this.onlyShow) {
      if (type == 'date_from') {
        this.dateFromPicker.reset(this.api.formatInputDate(this.dataForm.get('date_from').value, 'yyyy-mm-dd'));
      } else {
        this.dateToPicker.reset(this.api.formatInputDate(this.dataForm.get('date_to').value, 'yyyy-mm-dd'));
      }
  
      if (this.dataForm.value.date_from && this.dataForm.value.date_to) {
        this.getPreviousSalary(true);
        // для зарплаты получаем все оклады и премии
        if (this.salaryBuilder.form.salary_rewards || this.dataForm.get('salary_type').getRawValue() == 2) {
          this.getRewards();
        }
        // для зп если выбрана переработка, получаем
        if (this.salaryBuilder.form.salary_overhours && this.dataForm.get('salary_type').getRawValue() == 2)
          this.getOverhours();
      }
    }
  }

  ndflChanged() {
    if (this.dataForm.get('salary_ndfl').value == 0) {
      this.salaryBuilder.values.salary_ndfl_full = null;
      this.dataForm.get('salary_ndfl_value').setValue(null);
    } else if (this.salaryBuilder.values.salary_ndfl_full == null)
      this.salaryBuilder.values.salary_ndfl_full = true;
      
    if (this.salaryBuilder.values.salary_ndfl_full) {
      this.dataForm.get('salary_ndfl_value').setValue(-1);
      this.dataForm.get('salary_ndfl_value').removeValidators(Validators.required);
    } else if (this.salaryBuilder.values.salary_ndfl_full === false && this.dataForm.get('salary_ndfl_value').value == -1) {
      this.dataForm.get('salary_ndfl_value').setValue(null);
      this.dataForm.get('salary_ndfl_value').addValidators(Validators.required);
      setTimeout(() => {
        this.salaryNdflValueInput.setFocus()
      }, 300);
    }
    if (this.salaryBuilder.values.salary_ndfl_full === false) {
      if (this.dataForm.get('salary_ndfl_value').value <= 0)
        this.dataForm.get('salary_ndfl_value').setErrors({ notValid: true });
      else
        this.dataForm.get('salary_ndfl_value').setErrors(null);
    }
    this.dataForm.get('salary_ndfl_value').updateValueAndValidity();
    this.recalculateValue();
  }

  private getFormulas() {
    this.api.getFormulas(1).subscribe((res: any) => {
      if (res.success) this.userFormulas = res.items; else {
        this.api.toastPresent('Не удалось загрузить список формул для расчёта. Повторите попытку позже.');
        this.close();
      }
    });
  }

  /**
   * 
   * @param salary объект с зарплатой и формулой для парсинга
   * @param initialEdit парсинг данных при загрузке РЕДАКТИРУЕМОЙ НАЧИСЛЕННОЙ зарплаты, чтобы не срабатывали события изменения формы
   */
  parseFormula(salary: any, initialEdit?: boolean) {
    //console.log('Парсим зарплату', salary);
    try {
      // разбираем правила формулы
      this.salaryBuilder.formula.formula = JSON.parse(salary.formula);
    } catch(e) {
      //console.log(e);
      this.api.loadingDismiss()
      this.api.toastPresent("Не удалось разобрать формулу. Возможно она повреждена. Повторите попытку или свяжитесь с поддержкой.");
    }
    // применяем их к форме и показываем соответствующие элементы
    this.salaryBuilder.formula.formula.forEach((rule: any) => {
      if (initialEdit)
        this.dataForm.get(Object.keys(rule)[0]).disable(); // костыль, т.к. при подстановке значений все равно срабатывает событие, даже если сделать emitEvent: false

      // преобразовываем значения дат в нужный для инпута формат
      if (['date', 'date_from', 'date_to'].includes(Object.keys(rule)[0]))
        rule[Object.keys(rule)[0]] = this.api.formatInputDate(rule[Object.keys(rule)[0]], 'dd.mm.yyyy')
      
      this.dataForm.patchValue(rule)
      if (initialEdit)
        this.dataForm.get(Object.keys(rule)[0]).enable();
      this.salaryBuilder.form[Object.keys(rule)[0]] = true;
    });

    if (this.newSalary) {
      this.dataForm.patchValue({ salary_type: salary.salary_type })

      this.salaryBuilder.formula.salary_period = salary.salary_period;
      this.salaryBuilder.formula.salary_value_salary = salary.salary_value_salary;
      // если указан фиксированный оклад, то подставляем
      this.salaryBuilder.values.salary_value_salary = salary.salary_value_salary ? salary.salary_value_salary : null;
      this.salaryBuilder.values.salary_ndfl_full = this.dataForm.value.salary_ndfl_value == -1;
      this.salaryBuilder.values.formula_id = this.dataForm.get('formula_id').getRawValue()

      if (salary.salary_type == 1) {
        this.salaryBuilder.form.salary_value_salary = true;
        this.dataForm.patchValue({ salary_value_salary: this.salaryBuilder.values.salary_value_salary })
        this.salaryBuilder.values.salary_value = this.salaryBuilder.values.salary_value_salary
      }
      
      // параметр премий по умолчанию убран - при расчете по премиям и так они получаются, не за чем дублировать, а при расчете по фикс. окладу они вначале не считаются, нужно вбырать специально
      this.salaryBuilder.form.salary_rewards = false;

      // если выплата 2 раза в месяц, то получаем, смены. тоже самое можно для остальных периодов высчитать за какие числа смены получать
      let date: string, date_from: string, date_to: string;
      switch (this.salaryBuilder.formula.salary_period) {
        case 0: // для выплат 2 раза в месяц берем первые 15 дней или весь отчетный период
          let closeDate: number;
          // находим ближайшую дату
          if (salary.salary_day1 >= new Date().getDate())
            closeDate = salary.salary_day1;
          if (salary.salary_day2 >= new Date().getDate())
            closeDate = salary.salary_day1 >= new Date().getDate() && salary.salary_day1 < salary.salary_day2 ? salary.salary_day1 : salary.salary_day2;
          if (salary.salary_day1 < new Date().getDate() && salary.salary_day2 < new Date().getDate())
            closeDate = salary.salary_day1 < salary.salary_day2 ? salary.salary_day1 : salary.salary_day2;

          if (closeDate >= new Date().getDate()) {
            date = new Date().getFullYear() + '-' + (new Date().getMonth() + 1).toString().padStart(2, '0') + '-' + closeDate.toString().padStart(2, '0')
          } else {
            let targetDate = new Date(new Date().getFullYear(), new Date(new Date().setMonth(new Date().getMonth())).getMonth() + 1, closeDate);
            date = `${ targetDate.getFullYear() }-${ (targetDate.getMonth() + 1).toString().padStart(2, '0') }-${ closeDate.toString().padStart(2, '0') }`
          }
          
          let month = new Date(date).getDate() <= 15 ? new Date(new Date(date).setMonth(new Date(date).getMonth() - 1)).getMonth() + 1 : new Date(date).getMonth() + 1
          let year = new Date(date).getDate() <= 15 && month == 12 ? new Date(date).getFullYear() - 1 : new Date(date).getFullYear();
          date_from = year + '-' + month.toString().padStart(2, '0') + '-01';
          date_to = new Date(date).getDate() <= 15 ? year + '-' + month.toString().padStart(2, '0') + '-' + new Date(new Date(date).setDate(0)).getDate().toString().padStart(2, '0') : year + '-' + month.toString().padStart(2, '0') + '-15'
          break;
        case 99: // для единоразовых - последнюю неделю
          date = new Date(Date.now() - new Date().getTimezoneOffset() * 60 * 1000).toISOString().slice(0, 10)
          date_from = new Date(new Date().getTime() - 8 * 24 * 60 * 60 * 1000 - new Date().getTimezoneOffset() * 60 * 1000).toISOString().slice(0, 10);
          date_to = new Date(new Date().getTime() - 1 * 24 * 60 * 60 * 1000 - new Date().getTimezoneOffset() * 60 * 1000).toISOString().slice(0, 10);
          break;
        case 100: // для ежедневных - вчера
          date = new Date(Date.now() - new Date().getTimezoneOffset() * 60 * 1000).toISOString().slice(0, 10)
          date_from = new Date(new Date().getTime() - 1 * 24 * 60 * 60 * 1000 - new Date().getTimezoneOffset() * 60 * 1000).toISOString().slice(0, 10);
          date_to = new Date(new Date().getTime() - 1 * 24 * 60 * 60 * 1000 - new Date().getTimezoneOffset() * 60 * 1000).toISOString().slice(0, 10);
          break;
        case 101:
        case 102:
        case 103:
        case 104:
        case 105:
        case 106:
        case 107:
          //console.log();
          //console.log(new Date().getDay());
          //console.log(new Date().getDay());
          
          let diffDays = this.salaryBuilder.formula.salary_period - (new Date().getDay() + 101);
          if (diffDays < 0)
            diffDays += 7;

            //console.log(diffDays);
            
          date = new Date(new Date().getTime() + diffDays * 24 * 60 * 60 * 1000 - new Date().getTimezoneOffset() * 60 * 1000).toISOString().slice(0, 10)
          date_from = new Date(new Date().getTime() + (diffDays - 7) * 24 * 60 * 60 * 1000 - new Date().getTimezoneOffset() * 60 * 1000).toISOString().slice(0, 10);
          date_to = new Date(new Date().getTime() + (diffDays - 1) * 24 * 60 * 60 * 1000 - new Date().getTimezoneOffset() * 60 * 1000).toISOString().slice(0, 10);
          break;
      }
      this.dataForm.patchValue({ date_from: this.api.formatInputDate(date_from, 'dd.mm.yyyy') })
      this.dataForm.patchValue({ date_to: this.api.formatInputDate(date_to, 'dd.mm.yyyy') }) 
      this.dataForm.patchValue({ date: this.api.formatInputDate(date, 'dd.mm.yyyy') })

      this.getPreviousSalary(true);

      let user = this.userUsers.find((u: any) => u.id == this.dataForm.get('employee_id').getRawValue());
      if (user && !this.dataForm.value.comment && user.money_data) {
        this.dataForm.get('comment').setValue(`Оплата: ${ user.money_data }`)
      }
    } else {
      this.salaryBuilder.values = JSON.parse(salary.vals);
      // отключаем смену типа рассчета, если посчитано по сменам, чтобы не было проблем с учетом премий
      if (this.dataForm.get('salary_type').getRawValue() == 2) {
        this.dataForm.get('salary_type').disable();
        //this.dataForm.get('employee_id').disable();
        this.dataForm.get('type').disable();
        this.dataForm.get('formula_id').disable();
      }
    }

    if (this.dataForm.get('salary_type').getRawValue() == 1) {
      this.dataForm.get('salary_value_salary').addValidators(Validators.required);
      this.dataForm.get('salary_value_salary').updateValueAndValidity();
    }    

    // для зп получаем начисления
    if (this.dataForm.get('type').getRawValue() == 1 && !this.onlyShow) {
      if (this.dataForm.get('salary_type').getRawValue() == 2 || this.salaryBuilder.form.salary_rewards)
        this.getRewards();
      // если не по сменам и идет новый расчет, то просто пересчитываем
      else if (this.newSalary) {
        this.recalculateValue();
      }
      // если новый расчет и переработку хотят, то получаем ее
      if (this.newSalary && this.salaryBuilder.form.salary_overhours)
        this.getOverhours();
    }
    this.api.loadingDismiss()
  }

  async formulaChanged() {
    if (this.dataForm.get('type').getRawValue() == 1 && this.newSalary) {
      //console.log('formula changed', this.dataForm.get('formula_id').getRawValue());
      //console.log(this.salaryBuilder.values.formula_id);
      
      if (this.dataForm.get('formula_id').getRawValue()) { // если выбрали существующую
        this.salaryBuilder.values.formula_id = this.dataForm.get('formula_id').getRawValue()
        await this.api.loadingPresent();
        if (this.reqF)
          this.reqF.unsubscribe();
        this.reqF = this.api.get(`salary_formula/${ this.dataForm.get('formula_id').getRawValue() }`)
        .subscribe({
          next: (res: any) => {
            this.resetSalaryBuilder();

            if (res.success) {
              this.parseFormula(res.item);
            } else {
              this.api.loadingDismiss();
              this.dataForm.get('formula_id').setValue(null);
              this.api.toastPresent('Не удалось загрузить информацию о формуле. Повторите попытку позже.');
            }
          }
        });
      } else if (this.dataForm.get('formula_id').getRawValue() === 0) { // если выбрали без формулы
        // TODO надо сделать, чтобы вручную сбрасывались значения, а при изменении каких-то полей формулы(формы) 
        // просто менялось на НЕТ, но значения не сбрасывались
        this.resetSalaryBuilder();
      }
    }
  }

  async selectFormula() {
    const modal = await this.modalCtrl.create({
      component: SalaryFormulaSelectComponent,
      componentProps: { type: 1, subActive: this.user.data.subActive, no: true, id: this.dataForm.value.formula_id },
      cssClass: 'salary-formula-select-modal shift-edit',
    });
    modal.present();

    const { data, role } = await modal.onWillDismiss();

    this.getFormulas();
    if (role === 'selected') {
      this.dataForm.patchValue({ formula_id: data.item.id })
    }
  }

  formulaRuleChanged(e: any) {
    this.salaryBuilder.form[e.detail.value] = true;

    setTimeout(() => {
      this.dataForm.get('formula_rule').disable()
      this.dataForm.get('formula_rule').setValue(null)
      this.dataForm.get('formula_rule').enable()
    }, 500);
    
    // если выбрали премии и штрафы, то сразу получаем их
    if (e.detail.value == 'salary_rewards')
      this.getRewards();
    // если выбрали переработку, то получаем
    if (e.detail.value == 'salary_overhours')
      this.getOverhours();
    // если выбрали вычет прежних выплат
    if (e.detail.value == 'salary_previous')
      this.getPreviousSalary();
    // если выбрали НДФЛ, подставляем 13%
    if (e.detail.value == 'salary_ndfl') {
      this.salaryBuilder.values.salary_ndfl = 13;
      this.dataForm.get('salary_ndfl').setValue(13);
    }
    // если выбрали округление, подставляем 1000
    if (e.detail.value == 'salary_round') {
      this.salaryBuilder.values.salary_round = 1000;
      this.dataForm.get('salary_round').setValue(1000);
    }
  }

  formulaRuleValueChanged() {
    this.salaryBuilder.values.salary_value_salary = this.dataForm.value.salary_value_salary;
    this.salaryBuilder.values.salary_value = (this.dataForm.value.salary_value_salary ? this.dataForm.value.salary_value_salary : 0) + this.salaryBuilder.values.salary_rewards.sum.rewards - this.salaryBuilder.values.salary_rewards.sum.holds + this.salaryBuilder.values.salary_rewards.sum.salary
    this.recalculateValue();
  }

  removeFormulaRule(rule: string) {
    this.salaryBuilder.form[rule] = false;
    if (this.dataForm.get(rule))
      this.dataForm.get(rule).setValue(null)

    switch (rule) {
      case 'salary_overhours':
        this.salaryBuilder.values.salary_overhours_reward = 0;
        this.salaryBuilder.values.salary_overhours_hours = 0;
        this.salaryBuilder.values.salary_overhours = []
        break;
      case 'salary_reward_fix':
      case 'salary_reward_proc':
        this.salaryBuilder.values.salary_reward = 0;
        break;
      case 'salary_hold_fix':
      case 'salary_hold_proc':
        this.salaryBuilder.values.salary_hold = 0;
        break;
      case 'salary_ndfl':
        this.salaryBuilder.values.salary_ndfl = 0;
        this.salaryBuilder.values.salary_ndfl_value = 0;
        this.salaryBuilder.values.salary_ndfl_full = true;
        break;
      case 'salary_round':
        this.salaryBuilder.values.salary_round = 0;
        break;
      case 'salary_rewards':
        if (this.salaryBuilder.values.salary_rewards.sum)
          this.salaryBuilder.values.salary_value -= (this.salaryBuilder.values.salary_rewards.sum.salary + this.salaryBuilder.values.salary_rewards.sum.rewards - this.salaryBuilder.values.salary_rewards.sum.holds)
        this.resetSalaryRewards();
        break;
    }

    this.recalculateValue();
  }

  async showSelect(type: string) {
    switch (type) {
      case 'user':
        const modal = await this.modalCtrl.create({
          component: UserSelectComponent,
          componentProps: { id: this.dataForm.get('employee_id').value },
          cssClass: 'user-select-modal',
        });
        await modal.present();
    
        const { data, role } = await modal.onDidDismiss();
    
        if (role == 'selected') {
          this.dataForm.patchValue({ employee_id: data.item.id })
          this.fakeUsername = data.item.name

          if (!this.dataForm.value.comment && data.item.money_data)
            this.dataForm.get('comment').setValue(`Оплата: ${ data.item.money_data }`)
        }
        break;
    }
  }

  async save() {
    //console.log(this.dataForm);
    //console.log(this.salaryBuilder);

    if (!this.user.data.subActive && this.dataForm.get('formula_id').getRawValue()) {
      return this.api.toastPresent('Подключите тариф "ПРОФИ", чтобы рассчитывать выплаты сотрудникам по формулам зарплат.');
    }

    if (this.dataForm.get('type').getRawValue() == 1 && this.salaryBuilder.values.salary_value == 0 && this.salaryBuilder.values.salary_value_salary == 0 && this.salaryBuilder.values.salary_complete == 0 || this.dataForm.get('type').getRawValue() == 2 && !this.dataForm.value.salary_value_salary)
      return this.api.toastPresent('Сотруднику ничего не начисляется. Измените или добавьте параметры расчёта, чтобы была сумма для начисления.');

    if (this.dataForm.valid) {
      let data: any = {
        formula: {
          type: this.dataForm.get('type').getRawValue(),
          date: this.api.formatInputDate(this.dataForm.value.date, 'yyyy-mm-dd'),
          employee_id: this.dataForm.get('employee_id').getRawValue(),
          comment: this.dataForm.value.comment,
          note: this.dataForm.value.note,
          salary_value_salary: this.dataForm.value.salary_value_salary
        },
        values: {}
      };

      if (this.dataForm.get('type').getRawValue() == 1) {
        data.formula.date_from = this.api.formatInputDate(this.dataForm.value.date_from, 'yyyy-mm-dd')
        data.formula.date_to = this.api.formatInputDate(this.dataForm.value.date_to, 'yyyy-mm-dd')
        data.formula.formula_id = this.dataForm.get('formula_id').getRawValue()
        data.formula.salary_type = this.dataForm.get('salary_type').getRawValue();
        data.formula.salary_ndfl = this.dataForm.value.salary_ndfl
        data.formula.salary_ndfl_value = this.dataForm.value.salary_ndfl_value
        data.formula.salary_round = this.dataForm.value.salary_round
        data.formula.salary_overhours = this.dataForm.value.salary_overhours
        data.formula.salary_reward_fix = this.dataForm.value.salary_reward_fix
        data.formula.salary_reward_proc = this.dataForm.value.salary_reward_proc
        data.formula.salary_hold_fix = this.dataForm.value.salary_hold_fix
        data.formula.salary_hold_proc = this.dataForm.value.salary_hold_proc
        data.values.salary_value = this.salaryBuilder.values.salary_value
        data.values.salary_value_salary = this.salaryBuilder.values.salary_value_salary
        data.values.salary_overhours_hours = this.salaryBuilder.values.salary_overhours_hours
        data.values.salary_overhours = this.salaryBuilder.values.salary_overhours
        data.values.salary_overhours_reward = this.salaryBuilder.values.salary_overhours_reward
        data.values.salary_reward = this.salaryBuilder.values.salary_reward
        data.values.salary_hold = this.salaryBuilder.values.salary_hold
        data.values.salary_ndfl = this.salaryBuilder.values.salary_ndfl
        data.values.salary_ndfl_value = this.salaryBuilder.values.salary_ndfl_value
        data.values.salary_ndfl_full = this.salaryBuilder.values.salary_ndfl_full
        data.values.salary_round = this.salaryBuilder.values.salary_round
        data.values.salary_complete = this.salaryBuilder.values.salary_complete
        data.values.salary_previous = this.salaryBuilder.values.salary_previous
        data.values.salary_rewards = { ...this.salaryBuilder.values.salary_rewards }
        data.values.salary_rewards.total = {}
      }
      if (this.dataForm.get('type').getRawValue() == 2) {
        data.values.salary_value_salary = this.dataForm.value.salary_value_salary
        data.values.salary_complete = this.dataForm.value.salary_value_salary
      }
      
      if (this.editingItem && this.editingItem.id) {
        data.id = this.editingItem.id;
      }
      
      await this.api.loadingPresent();
      this.api.post('salary', data, true)
        .subscribe({
          next: async (res: any) => {
            this.api.loadingDismiss();
            if (res.success) {
              if (!data.id && this.welcomeStep == 6)
                this.storage.set('welcomeStep', 7)
              this.api.toastPresent(this.newSalary ? "Выплата успешно начислена." : "Выплата успешно обновлена.");
              return this.modalCtrl.dismiss({ ...data, ...{ id: res.id } }, 'saved');
            } else {
              this.api.toastPresent(res.message);
              if (res.code == 401) {
                return this.modalCtrl.dismiss(null, 'close');
              }
            }
          }
        });
    } else {
      this.api.toastPresent("Указаны некорректные данные в форме. Проверьте ещё раз введённую информацию.");
    }
  }

  close() {
    return this.modalCtrl.dismiss(null, 'close');
  }

  ngOnDestroy() {
    if (this.reqR)
      this.reqR.unsubscribe();
    if (this.reqO)
      this.reqO.unsubscribe();
  }
}