import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

import { ApiService } from '../../services/api.service';
import { YmService } from '../../services/ym.service';

@Component({
  selector: 'app-salary-formula-salary-edit',
  templateUrl: './salary-formula-salary-edit.component.html',
  styleUrls: ['./salary-formula-salary-edit.component.scss'],
})
export class SalaryFormulaSalaryEditComponent implements OnInit {

  @ViewChild('title') valueInput: any;
  @ViewChild('salary_day1') salaryDay1Input: any;
  @ViewChild('salary_value') salaryValueInput: any;
  @ViewChild('salary_ndfl_value') salaryNdflValueInput: any;
  @Input() editingItem: any;
  ndflFullValue: boolean; // от всей суммы или нет вычитать НДФЛ
  dataForm: FormGroup;
  dataUpdated: string;
  dataUpdatedUserName: string;
  
  constructor(private api: ApiService, private formBuilder: FormBuilder, private modalCtrl: ModalController, private ym: YmService) {

  }

  async ngOnInit() {
    this.ym.hit('salary-formula-salary-edit', { params: { title: 'Окно редактирования формулы зарплаты' } });

    this.dataForm = this.formBuilder.group({
      title: [, [Validators.required, Validators.minLength(1), Validators.maxLength(50)]],
      comment: [, Validators.maxLength(250)],
      salary_period: [, [Validators.required, Validators.min(0), Validators.max(107)]],
      salary_day1: [, [Validators.min(1), Validators.max(31)]],
      salary_day2: [, [Validators.min(1), Validators.max(31)]],
      salary_type: [, [Validators.required, Validators.min(1), Validators.max(2)]],
      salary_value_salary: [, Validators.min(0)], // фиксированный оклад
      salary_ndfl: [0, [Validators.min(0), Validators.max(30)]],
      salary_ndfl_value: [, Validators.min(-1)],
      salary_round: [0, [Validators.min(0), Validators.max(1000)]],
      salary_overhours: [, Validators.min(0)],
      salary_reward_fix: [, Validators.min(0)],
      salary_reward_proc: [, [Validators.min(0), Validators.max(100)]],
      salary_hold_fix: [, Validators.min(0)],
      salary_hold_proc: [, [Validators.min(0), Validators.max(100)]],
    });

    if (this.editingItem) {
      let filteredItem = { ...this.editingItem };

      try {
        let formula = JSON.parse(filteredItem.formula)
        delete filteredItem.formula;
        this.dataForm.patchValue(filteredItem);
        formula.forEach((rule: any) => this.dataForm.patchValue(rule));

        // если ндфл от всей суммы считаем
        this.ndflFullValue = this.dataForm.get('salary_ndfl_value').value === -1;
      } catch(e) {
        this.api.toastPresent("Не удалось разобрать формулу. Возможно она повреждена. Повторите попытку или свяжитесь с поддержкой.");
        return this.close();
      }

      this.dataUpdated = this.editingItem.updated;
      this.dataUpdatedUserName = this.editingItem.updated_name ? this.editingItem.updated_name : this.editingItem.added_name;
    }

    setTimeout(() => {
      this.valueInput.setFocus()
    }, 500);
  }

  typeChanged() {
    if (this.dataForm.get('salary_type').value == 1) {
      this.dataForm.get('salary_overhours').setValue(null);
      this.dataForm.get('salary_value_salary').addValidators(Validators.required);
      setTimeout(() => {
        this.salaryValueInput.setFocus();
      }, 300);
    } else {
      this.dataForm.get('salary_value_salary').removeValidators(Validators.required);
      this.dataForm.get('salary_value_salary').setValue(null);
    }
    this.dataForm.get('salary_value_salary').updateValueAndValidity();
  }

  periodChanged(e: any) {
    if (e.detail.value == 0) {
      this.dataForm.get('salary_day1').addValidators(Validators.required);
      this.dataForm.get('salary_day2').addValidators(Validators.required);
      setTimeout(() => {
        this.salaryDay1Input.setFocus()
      }, 300);
    } else { // если выплата каждый день/неделю, то дат нет
      this.dataForm.get('salary_day1').setValue(null)
      this.dataForm.get('salary_day1').removeValidators(Validators.required);
      this.dataForm.get('salary_day2').setValue(null)
      this.dataForm.get('salary_day2').removeValidators(Validators.required);
    } 
    this.dataForm.get('salary_day1').updateValueAndValidity();
    this.dataForm.get('salary_day2').updateValueAndValidity();
  }

  ndflChanged() {
    if (this.dataForm.get('salary_ndfl').value == 0) {
      this.ndflFullValue = null;
      this.dataForm.get('salary_ndfl_value').setValue(null);
    } else if (this.ndflFullValue == null)
      this.ndflFullValue = true;
    if (this.ndflFullValue) {
      this.dataForm.get('salary_ndfl_value').setValue(-1);
      this.dataForm.get('salary_ndfl_value').removeValidators(Validators.required);
    } else if (this.ndflFullValue === false && this.dataForm.get('salary_ndfl_value').value == -1) {
      this.dataForm.get('salary_ndfl_value').setValue(null);
      this.dataForm.get('salary_ndfl_value').addValidators(Validators.required);
      setTimeout(() => {
        this.salaryNdflValueInput.setFocus()
      }, 300);
    }
    if (this.ndflFullValue === false) {
      if (this.dataForm.get('salary_ndfl_value').value <= 0)
        this.dataForm.get('salary_ndfl_value').setErrors({ notValid: true });
      else
        this.dataForm.get('salary_ndfl_value').setErrors(null);
    }
    this.dataForm.get('salary_ndfl_value').updateValueAndValidity();
  }

  rewardsChanged() {
    if (this.dataForm.get('salary_reward_fix').value && this.dataForm.get('salary_reward_proc').value) {
      this.dataForm.get('salary_reward_fix').setErrors({ notValid: true });
      this.dataForm.get('salary_reward_proc').setErrors({ notValid: true });
    } else {
      this.dataForm.get('salary_reward_fix').setErrors(null);
      this.dataForm.get('salary_reward_proc').setErrors(null);
    }
    if (this.dataForm.get('salary_hold_fix').value && this.dataForm.get('salary_hold_proc').value) {
      this.dataForm.get('salary_hold_fix').setErrors({ notValid: true });
      this.dataForm.get('salary_hold_proc').setErrors({ notValid: true });
    } else {
      this.dataForm.get('salary_hold_fix').setErrors(null);
      this.dataForm.get('salary_hold_proc').setErrors(null);
    }
  }

  async save() {
    if (this.dataForm.get('salary_period').value == null)
      this.dataForm.get('salary_period').markAsTouched();
    if (this.dataForm.get('salary_type').value == null)
      this.dataForm.get('salary_type').markAsTouched();

    if (this.dataForm.value.salary_period == 0 && (this.dataForm.value.salary_day1 < 15 && this.dataForm.value.salary_day2 < 15 ||
        this.dataForm.value.salary_day1 > 15 && this.dataForm.value.salary_day2 > 15)) {
      this.dataForm.get('salary_day1').setErrors({ notValid: true });
      this.dataForm.get('salary_day2').setErrors({ notValid: true });
      return this.api.toastPresent('Укажите корректные даты выплат зарплаты. Между ними не должно быть больше 15 дней. Зарплата выплачивается в первой и второй половинах месяца.')
    }

    if (this.dataForm.valid) {
      let data = { ...this.dataForm.value };
      
      if (this.editingItem) {
        data.id = this.editingItem.id;
      }
      
      await this.api.loadingPresent();

      this.api.post('salary_formula_salary', data, true)
        .subscribe({
          next: async (res: any) => {
            this.api.loadingDismiss();
            if (res.success) {
              this.api.getFormulas(1, true);
              this.api.toastPresent(this.editingItem ? "Формула успешно обновлена." : "Формула успешно добавлена.");
              return this.modalCtrl.dismiss({ id: res.id }, 'saved');
            } else {
              this.api.toastPresent(res.message);
              if (res.code == 401) {
                return this.modalCtrl.dismiss(null, 'close');
              }
            }
          }
        });
    } else {
      this.api.toastPresent("Указаны некорректные данные в форме. Проверьте ещё раз введённую информацию.");
    }
  }

  close() {
    return this.modalCtrl.dismiss(null, 'close');
  }
}
