import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ModalController, NavController, PopoverController } from '@ionic/angular';

import { AlertsComponent } from '../../popovers/alerts/alerts.component'

import { ApiService } from '../../services/api.service';
import { UserService } from '../../services/user.service';
import { YmService } from '../../services/ym.service';
import { SettingsComponent } from 'src/app/modals/settings/settings.component';

@Component({
  selector: 'app-main-toolbar',
  templateUrl: './main-toolbar.component.html',
  styleUrls: ['./main-toolbar.component.scss'],
})
export class MainToolbarComponent implements OnInit {

  subExpuringSoon: boolean;
  hasAccessToPlans: any;
  showSettingsButton: boolean;

  constructor(private api: ApiService, private modalCtrl: ModalController, private navCtrl: NavController, private popCtrl: PopoverController, private router: Router, public user: UserService, private ym: YmService) {}

  ngOnInit() {
    this.hasAccessToPlans = this.user.data.role == 0 || this.user.checkPermissions([30])
    this.subExpuringSoon = new Date(this.user.data.subUntil).getTime() - Date.now() <= 3 * 24 * 60 * 60 * 1000    
    this.showSettingsButton = this.user.data.role == 0 || this.user.checkPermissions([4, 5, 12, 13, 21, 22, 25, 26])
  }

  async showSettings() {
    const modal = await this.modalCtrl.create({
      component: SettingsComponent
    });
    modal.present();    
  }

  buy() {
    this.ym.reachGoal('SUB_GO_PAGE_TOOLBAR')
    this.navCtrl.setDirection('root');
    this.router.navigate(['/app/plans'], { replaceUrl: true })
  }

  async showAlerts(e: any) {
    const popover = await this.popCtrl.create({
      component: AlertsComponent,
      cssClass: 'alerts-popover',
      event: e,
    });
    await popover.present();

    const { data, role } = await popover.onDidDismiss();

    if (role == 'go') {
      this.navCtrl.setDirection('root');
      this.router.navigate([data.page], { replaceUrl: true })
    }
    
    this.api.get(`user_alerts_check`)
      .subscribe({
        next: (res: any) => {
          this.user.data.hasNewAlerts = res.success && res.new_alerts;
        }
      });
  }

}
