import { Injectable } from "@angular/core";
import { CanMatch, Router } from "@angular/router";
import { NavController } from "@ionic/angular/standalone";
import { Observable } from "rxjs";
import { filter, map, take, tap } from "rxjs/operators";

import { ApiService } from "../services/api.service";

@Injectable({
  providedIn: "root",
})
export class AutoLoginGuard implements CanMatch {
  constructor(
    private navCtrl: NavController,
    private api: ApiService,
    private router: Router,
  ) {}

  canMatch(): Observable<boolean> {
    return this.api.isUser.pipe(
      //tap((res) => console.log(res)),
      filter((val) => val !== null),
      take(1),
      map((isAuthd) => {
        if (isAuthd) {
          this.navCtrl.setDirection("root");
          this.router.navigate(["/app"], { replaceUrl: true });
        } else {
          return true;
        }
      }),
    );
  }
}
