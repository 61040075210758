import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
	name: 'spellCount'
})

export class SpellCountPipe implements PipeTransform {

	transform(value: number, strOne: string, strTwo: string, strMany: string): string {
		if ((value || value === 0) && strOne && strTwo && strMany) {
			if (value % 10 == 1 && value % 100 != 11) {
				return value + ' ' + strOne;
			} else if (value % 10 >= 2 && value % 10 <= 4 && (value % 100 < 10 || value % 100 >= 20)) {
				return value + ' ' + strTwo;
			} else {
				return value + ' ' + strMany;
			}
		}
		return '';
	}

}