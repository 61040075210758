import { Component, Input, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';

import { SalaryFormulaEditComponent } from '../salary-formula-edit/salary-formula-edit.component'
import { SalaryFormulaSalaryEditComponent } from '../salary-formula-salary-edit/salary-formula-salary-edit.component'

import { ApiService } from '../../services/api.service';
import { UserService } from '../../services/user.service';
import { YmService } from '../../services/ym.service';


@Component({
  selector: 'app-salary-formula-select',
  templateUrl: './salary-formula-select.component.html',
  styleUrls: ['./salary-formula-select.component.scss'],
})
export class SalaryFormulaSelectComponent implements OnInit {

  @Input() type: number;
  @Input() id: number;
  @Input() none: boolean; // есть ли пункт "Нет"
  @Input() no: boolean; // есть ли пункт "Без формулы"
  @Input() subActive: boolean;
  items: any;
  itemsNotFound: boolean;
  itemsLoading: boolean;
  private req: any;
  searchString: string;
  canAddFormula: boolean;

  constructor(private api: ApiService, private modalCtrl: ModalController, private user: UserService, private ym: YmService) {}

  ngOnInit() {
    this.canAddFormula = !!(this.user.data.role == 0 || this.type == 1 && this.user.checkPermissions([9, 10])  || this.type == 2 &&  this.user.checkPermissions([4, 5]) )
    this.ym.hit('salary-formula-select-' + this.type, { params: { title: 'Окно выбора формулы ' + (this.type == 1 ? 'зарплаты' : "смены") } });
    this.getItems();
  }

  searchItems(e) {
    this.searchString = e.target.value.toLowerCase();
    this.getItems();
  }

  getItems(force?: boolean) {
    this.itemsNotFound = false;
    this.itemsLoading = true;
    this.items = [];
    if (this.req)
      this.req.unsubscribe();
    this.req = this.api.getFormulas(this.type, force)
      .subscribe({
        next: (res: any) => {
          this.itemsLoading = false;
          if (res.success) {
            if (res.items.length > 0) {
              for (let i = 0; i < res.items.length; i++) {
                // для формул преобразуем формулу в человекочитаемый формат
                if (res.items[i].type == 1) {
                  switch (res.items[i].salary_period) {
                    case 0:
                      res.items[i].salary_period_desc = '2 раза в месяц'
                      break;
                    case 99:
                      res.items[i].salary_period_desc = 'единоразово'
                      break;
                    case 100:
                      res.items[i].salary_period_desc = 'каждый день'
                      break;
                    case 102:
                      res.items[i].salary_period_desc = 'по понедельникам'
                      break;
                    case 103:
                      res.items[i].salary_period_desc = 'по вторникам'
                      break;
                    case 104:
                      res.items[i].salary_period_desc = 'по средам'
                      break;
                    case 105:
                      res.items[i].salary_period_desc = 'по четвергам'
                      break;
                    case 106:
                      res.items[i].salary_period_desc = 'по пятницам'
                      break;
                    case 107:
                      res.items[i].salary_period_desc = 'по субботам'
                      break;
                    case 101:
                      res.items[i].salary_period_desc = 'по воскресеньям'
                      break;
                    default:
                      res.items[i].salary_period_desc = 'неизвестно';
                  }
                }
                res.items[i].formula_desc = this.api.buildShiftFormula(res.items[i].formula, 1)   

                // фильтруем результат, если ищу по названию
                if (!this.searchString || this.searchString && res.items[i].title.toLowerCase().indexOf(this.searchString) > -1)
                  this.items.push(res.items[i])
              }
              this.itemsNotFound = false;
            } else {
              this.itemsNotFound = true;
            }
          } else {
            this.api.toastPresent('Не удалось загрузить список формул. Повторите попытку позже.');
            this.modalCtrl.dismiss();
          }
        }
    });
  }

  async add() {
    if (!this.subActive && this.type == 1) {
      return this.api.toastPresent('Подключите тариф "ПРОФИ", чтобы добавлять формулы для расчёта зарплат.');
    } else {
      const modal = await this.modalCtrl.create({
        component: this.type == 1 ? SalaryFormulaSalaryEditComponent : SalaryFormulaEditComponent,
        cssClass: 'formula-edit-modal'
      });
      modal.present();
  
      const { data, role } = await modal.onWillDismiss();
  
      if (role === 'saved') {
        if (this.type == 1) 
          this.ym.reachGoal('FORMULA_SALARY_ADDED', { "Формула зарплаты добавлена": "Форма выбора формулы" })
        else
          this.ym.reachGoal('FORMULA_SHIFT_ADDED', { "Формула смены добавлена": "Форма выбора формулы" })
        this.getItems(true)
      }
    }
  }

  select(item) {
    return this.modalCtrl.dismiss({ item }, 'selected');
  }

  close() {
    return this.modalCtrl.dismiss(null, 'close');
  }

  // удаляет из DOM скрытый селект
  removeHiddenSelect() {
    let hidden = document.getElementsByClassName('hidden-select')[0]
    if (hidden)
      hidden.parentElement.removeChild(hidden);
  }

  ngOnDestroy() {
    this.removeHiddenSelect();
    if (this.req)
      this.req.unsubscribe()
  }

}
