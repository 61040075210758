<ion-content class="ion-padding">
  <ion-grid>
    <ion-row class="ion-align-items-center" id="logo">
      <ion-col class="ion-text-center">
        <a href="/" title="На главную"
          ><img src="/assets/imgs/logo_w.png" [alt]="appName" [title]="appName"
        /></a>
      </ion-col>
    </ion-row>
    <ion-row class="ion-align-items-center" id="form">
      <ion-col class="ion-text-center">
        <ng-container *ngIf="step === 'ENTER_EMAIL'">
          <form (ngSubmit)="sendCode()" class="content-card">
            <div>Вход в Личный кабинет</div>
            <div class="ion-padding-top">
              <ion-note
                >Введите электронную почту, и мы пришлём вам код для входа в
                Личный кабинет.</ion-note
              >
            </div>
            <ion-list lines="none">
              <ion-item>
                <ion-input
                  #form_input
                  type="email"
                  placeholder="Электронная почта..."
                  [(ngModel)]="account.login"
                  name="login"
                ></ion-input>
              </ion-item>
            </ion-list>
            <div>
              <ion-button
                color="primary"
                expand="block"
                [disabled]="account.login === ''"
                type="submit"
                >Получить код</ion-button
              >
            </div>
            <div class="form-error" *ngIf="formError !== null">
              {{ formError }}
            </div>
            <div class="ion-padding-top">
              <ion-note class="small"
                >Проблемы со входом?<br /><a
                  href="https://t.me/pvzadmin_ru"
                  target="_blank"
                  rel="nofollow"
                  >Напишите нам</a
                ></ion-note
              >
            </div>
          </form>
        </ng-container>

        <ng-container *ngIf="step === 'CONFIRM_EMAIL'">
          <form (ngSubmit)="confirmCode()" class="content-card">
            <div>Код подтверждения</div>
            <div class="ion-padding-top">
              <ion-note
                >Мы отправили на ваш почтовый адрес
                <span class="email">{{ account.login }}</span> письмо с
                4-значным кодом. Введите этот код для продолжения.</ion-note
              >
            </div>
            <ion-list lines="none">
              <ion-item>
                <ion-input
                  inputmode="numeric"
                  maxlength="4"
                  placeholder="Код из почты..."
                  #form_input
                  (ionInput)="autologin('user')"
                  [(ngModel)]="account.code"
                  name="code"
                ></ion-input>
              </ion-item>
            </ion-list>
            <div>
              <ion-button
                type="submit"
                color="primary"
                expand="block"
                [disabled]="!account.code || account.code && account.code.toString().trim().length !== 4"
                >Войти</ion-button
              >
            </div>
            <div
              class="form-error"
              *ngIf="account.attempts !== null && account.attempts > 0"
            >
              Осталось попыток: {{ account.attempts }}
            </div>
            <div
              class="form-error"
              *ngIf="account.attempts !== null && account.attempts === 0"
            >
              Запросите код повторно!
            </div>
            <div class="ion-padding-top">
              <ion-note class="small"
                >Если письмо так и не пришло, проверьте папку<br />"Нежелательная
                почта (Спам)" или запросите код повторно.</ion-note
              >
            </div>
            <div class="activate-email ion-padding-top" (click)="resendCode()">
              Получить код повторно
            </div>
            <div class="ion-padding-top">
              <ion-note class="small"
                >Проблемы со входом?<br /><a
                  href="https://t.me/pvzadmin_ru"
                  target="_blank"
                  rel="nofollow"
                  >Напишите нам</a
                ></ion-note
              >
            </div>
          </form>
        </ng-container>

        <ng-container *ngIf="step === 'SELECT_ACCOUNT'">
          <div class="content-card">
            <div>Выбор учётной записи</div>
            <div class="ion-padding-top">
              <ion-note
                >Выберите под какой учётной записью вы хотите войти в Личный
                кабинет.</ion-note
              >
            </div>
            <ion-list class="ion-padding-top" lines="none">
              <ion-item
                class="card-table-tr"
                *ngFor="let a of accounts; index as i"
                button
                (click)="selectAccount(a.id)"
                [ngClass]="{ 'odd': i % 2 !== 0 }"
              >
                <ion-label>
                  <h2>{{ a.name }}</h2>
                  <p *ngIf="a.owner_name !== a.name">{{ a.owner_name }}</p>
                  <p>{{ a.role }}</p>
                </ion-label>
                <ion-icon
                  slot="icon-only"
                  name="log-in-outline"
                  slot="end"
                  class="enter-icon"
                ></ion-icon>
              </ion-item>
            </ion-list>
            <div class="ion-padding-top">
              <ion-note class="small"
                >Проблемы со входом?<br /><a
                  href="https://t.me/pvzadmin_ru"
                  target="_blank"
                  rel="nofollow"
                  >Напишите нам</a
                ></ion-note
              >
            </div>
          </div>
        </ng-container>

        <ng-container *ngIf="step === 'ENTER_ON_PVZ_LINK'">
          <div class="content-card">
            <div>Вход на {{ account.pvz }}</div>
            <div id="curr-date">{{ currDate | date: 'dd MMMM yyyy' }}</div>
            <div class="ion-padding-top" *ngIf="accounts?.length > 0">
              <ion-note
                >Для входа под собой выберите сотрудника из списка или войдите в
                качестве подменного.</ion-note
              >
            </div>
            <div class="form-error" *ngIf="accounts?.length === 0">
              Прикреплённые или сотрудники со сменой на этом ПВЗ не найдены. При
              необходимости войдите под подменным сотрудником.
            </div>
            <ion-list class="ion-padding-top" lines="none">
              <ion-item
                class="card-table-tr"
                *ngFor="let a of accounts; index as i"
                button
                (click)="selectPVZAccount(a.id)"
                [ngClass]="{ 'odd': i % 2 !== 0 }"
              >
                <ion-label>
                  <h2>{{ a.name }}</h2>
                  <p>{{ a.role_name }}</p>
                  <p class="shift-today" *ngIf="a.shift_today">Смена сегодня</p>
                </ion-label>
                <ion-icon
                  slot="icon-only"
                  name="log-in-outline"
                  slot="end"
                  class="enter-icon"
                ></ion-icon>
              </ion-item>
              <ion-item
                class="card-table-tr ion-margin-top"
                button
                (click)="selectPVZAccount(0)"
              >
                <ion-label>
                  <h2>Подменный сотрудник</h2>
                  <p>Если не нашли себя в списке</p>
                </ion-label>
                <ion-icon
                  slot="icon-only"
                  name="log-in-outline"
                  slot="end"
                  class="enter-icon"
                ></ion-icon>
              </ion-item>
            </ion-list>
            <div class="ion-padding-top">
              <ion-note class="small"
                >Проблемы со входом?<br /><a
                  href="https://t.me/pvzadmin_ru"
                  target="_blank"
                  rel="nofollow"
                  >Напишите нам</a
                ></ion-note
              >
            </div>
          </div>
        </ng-container>

        <ng-container *ngIf="step === 'ENTER_PINCODE'">
          <form (ngSubmit)="confirmPincode()" class="content-card">
            <div>Личный пинкод</div>
            <div class="ion-padding-top">
              <ion-note
                >Введите пинкод, который был установлен вам управляющим при
                приёме на работу.</ion-note
              >
            </div>
            <ion-list lines="none">
              <ion-item>
                <ion-input
                  inputmode="numeric"
                  type="password"
                  placeholder="Ваш пинкод..."
                  #form_input
                  maxlength="4"
                  (ionInput)="autologin('pvz')"
                  [(ngModel)]="account.code"
                  name="code"
                ></ion-input>
              </ion-item>
            </ion-list>
            <div>
              <ion-button
                type="submit"
                color="primary"
                expand="block"
                [disabled]="!account.code || account.code && account.code.toString().trim().length !== 4"
                >Войти</ion-button
              >
            </div>
            <div
              class="form-error"
              *ngIf="account.attempts !== null && account.attempts > 0"
            >
              Осталось попыток: {{ account.attempts }}<ng-container
                *ngIf="account.attempts === 1"
                >.<br />При ошибке пинкод будет обновлён.</ng-container
              >
            </div>
            <div
              class="form-error"
              *ngIf="account.attempts !== null && account.attempts === 0"
            >
              Обратитесь к управляющему,<br />чтобы получить новый пинкод!
            </div>
            <div class="ion-padding-top">
              <ion-note class="small"
                >Если вы не знаете свой пинкод,<br />обратитесь к
                управляющему.</ion-note
              >
            </div>
            <div class="ion-padding-top">
              <ion-note class="small"
                >Проблемы со входом?<br /><a
                  href="https://t.me/pvzadmin_ru"
                  target="_blank"
                  rel="nofollow"
                  >Напишите нам</a
                ></ion-note
              >
            </div>
          </form>
        </ng-container>

        <ng-container *ngIf="step === 'ENTER_ON_PVZ_LINK_UNKNOWN_USER'">
          <form (ngSubmit)="confirmEmailTelPincode()" class="content-card">
            <div>Вход на {{ account.pvz }}</div>
            <div class="ion-padding-top">
              <ion-note
                >Введите электронную почту/телефон и пинкод, которые были
                установлены вам управляющим при приёме на работу.</ion-note
              >
            </div>
            <ion-list lines="none">
              <ion-item>
                <ion-input
                  #form_input
                  placeholder="Почта или телефон..."
                  [(ngModel)]="account.login"
                  name="login"
                ></ion-input>
              </ion-item>
              <ion-item>
                <ion-input
                  inputmode="numeric"
                  type="password"
                  placeholder="Ваш пинкод..."
                  maxlength="4"
                  [(ngModel)]="account.code"
                  (ionInput)="autologin('pvz')"
                  name="code"
                ></ion-input>
              </ion-item>
            </ion-list>
            <div>
              <ion-button
                type="submit"
                color="primary"
                expand="block"
                [disabled]="!account.login || !account.code || account.code && account.code.toString().trim().length !== 4"
                >Войти</ion-button
              >
            </div>
            <div
              class="form-error"
              *ngIf="account.attempts !== null && account.attempts > 0"
            >
              Осталось попыток: {{ account.attempts }}<ng-container
                *ngIf="account.attempts === 1"
                >.<br />При ошибке пинкод будет обновлён.</ng-container
              >
            </div>
            <div
              class="form-error"
              *ngIf="account.attempts !== null && account.attempts === 0"
            >
              Обратитесь к управляющему,<br />чтобы получить новый пинкод!
            </div>
            <div class="ion-padding-top">
              <ion-note class="small"
                >Если вы не знаете свой пинкод,<br />обратитесь к
                управляющему.</ion-note
              >
            </div>
            <div class="ion-padding-top">
              <ion-note class="small"
                >Проблемы со входом?<br /><a
                  href="https://t.me/pvzadmin_ru"
                  target="_blank"
                  rel="nofollow"
                  >Напишите нам</a
                ></ion-note
              >
            </div>
          </form>
        </ng-container>

        <ng-container *ngIf="step === 'ENTER_TEL' || step === 'CONFIRM_TEL'">
          <div class="content-card">
            <form *ngIf="step === 'ENTER_TEL'" (ngSubmit)="showConfirmWidget()">
              <div>Специальное предложение</div>
              <div class="ion-padding-top">
                <ion-note
                  >Подтвердите ваш номер телефона и получите дополнительную
                  скидку на первую активацию тарифа "ПРОФИ"!</ion-note
                >
              </div>
              <ion-list lines="none">
                <ion-item>
                  <ion-input
                    placeholder="Ваш телефон..."
                    type="tel"
                    autocomplete="tel"
                    inputmode="tel"
                    [maskito]="phoneMask"
                    [maskitoElement]="maskPredicate"
                    #form_input
                    [(ngModel)]="account.tel"
                    name="tel"
                  ></ion-input>
                </ion-item>
              </ion-list>
              <div>
                <ion-button
                  type="submit"
                  color="primary"
                  expand="block"
                  [disabled]="!account.tel"
                  >Подтвердить</ion-button
                >
              </div>
            </form>
            <app-tel-confirmation
              *ngIf="step === 'CONFIRM_TEL'"
              [params]="{ tel: account.tel, subActive: false }"
              (telConfirmed)="doLogin(accountData)"
              (failedToLoad)="confirmWidgetFailedToLoad()"
            ></app-tel-confirmation>
            <div
              class="skip-confirmation ion-padding-top"
              (click)="skipTelConfirmation()"
            >
              Пропустить
            </div>
            <div class="ion-padding-top">
              <ion-note class="small"
                >Проблемы со входом?<br /><a
                  href="https://t.me/pvzadmin_ru"
                  target="_blank"
                  rel="nofollow"
                  >Напишите нам</a
                ></ion-note
              >
            </div>
          </div>
        </ng-container>

        <div class="ion-text-center ion-padding-top">
          <ion-note class="small"
            >Вход в Личный кабинет означает ваше<br />согласие с
            <a href="https://pvz-admin.ru/oferta.pdf" target="_blank"
              >условиями публичной оферты</a
            >.</ion-note
          >
        </div>
      </ion-col>
    </ion-row>
    <ion-row class="ion-align-items-center" id="version">
      <ion-col class="ion-text-center"> ver. {{ api.appVer }} </ion-col>
    </ion-row>
  </ion-grid>
</ion-content>
