import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { AlertController, IonDatetime, ModalController } from '@ionic/angular';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

import { PvzSelectComponent } from '../pvz-select/pvz-select.component'
import { SalaryFormulaSelectComponent } from '../salary-formula-select/salary-formula-select.component'
import { UserSelectComponent } from '../user-select/user-select.component'
import { DatePipe } from '@angular/common';
import { SpellCountPipe } from '../../pipes/spell-count.pipe';

import { ApiService } from '../../services/api.service';
import { EventsService } from '../../services/events.service';
import { UserService } from '../../services/user.service';
import { YmService } from '../../services/ym.service';

@Component({
  selector: 'app-shift-edit',
  templateUrl: './shift-edit.component.html',
  styleUrls: ['./shift-edit.component.scss'],
})
export class ShiftEditComponent implements OnInit {

  @ViewChild('dateButton') dateButton: any;
  @ViewChild('value') valueInput: any;
  @ViewChild('datePicker') datePicker : IonDatetime
  @ViewChild('dateRepeatPicker') dateRepeatPicker : IonDatetime
  @Input() editingItem: any;
  @Input() formulas: any;
  @Input() repeatUntil: string;
  formula: any; // примененные для расчета поля формулы
  dataForm: FormGroup;
  shiftTimes;
  shiftTimesStart;
  shiftTimesEnd;
  userPvzs;
  userUsers;
  selectFormulaChoosen: boolean;
  factShiftTime: string;
  today: string;
  canEditShift: boolean;
  requests;
  requestsLoading: boolean;
  reqR;
  dataUpdated: string; // последнее обновление данных и кем
  dataUpdatedUserName: string;

  constructor(private alertCtrl: AlertController, public api: ApiService, private datePipe: DatePipe, private events: EventsService, private formBuilder: FormBuilder, private modalCtrl: ModalController, private spellCount: SpellCountPipe, public user: UserService, private ym: YmService) {
    this.canEditShift = this.user.data.role == 0 || this.user.checkPermissions([4, 5]) 
    this.shiftTimes = [
      {
          "value": "00:00",
          "title": "00:00"
      },
      {
          "value": "00:15",
          "title": "00:15"
      },
      {
          "value": "00:30",
          "title": "00:30"
      },
      {
          "value": "00:45",
          "title": "00:45"
      },
      {
          "value": "01:00",
          "title": "01:00"
      },
      {
          "value": "01:15",
          "title": "01:15"
      },
      {
          "value": "01:30",
          "title": "01:30"
      },
      {
          "value": "01:45",
          "title": "01:45"
      },
      {
          "value": "02:00",
          "title": "02:00"
      },
      {
          "value": "02:15",
          "title": "02:15"
      },
      {
          "value": "02:30",
          "title": "02:30"
      },
      {
          "value": "02:45",
          "title": "02:45"
      },
      {
          "value": "03:00",
          "title": "03:00"
      },
      {
          "value": "03:15",
          "title": "03:15"
      },
      {
          "value": "03:30",
          "title": "03:30"
      },
      {
          "value": "03:45",
          "title": "03:45"
      },
      {
          "value": "04:00",
          "title": "04:00"
      },
      {
          "value": "04:15",
          "title": "04:15"
      },
      {
          "value": "04:30",
          "title": "04:30"
      },
      {
          "value": "04:45",
          "title": "04:45"
      },
      {
          "value": "05:00",
          "title": "05:00"
      },
      {
          "value": "05:15",
          "title": "05:15"
      },
      {
          "value": "05:30",
          "title": "05:30"
      },
      {
          "value": "05:45",
          "title": "05:45"
      },
      {
          "value": "06:00",
          "title": "06:00"
      },
      {
          "value": "06:15",
          "title": "06:15"
      },
      {
          "value": "06:30",
          "title": "06:30"
      },
      {
          "value": "06:45",
          "title": "06:45"
      },
      {
          "value": "07:00",
          "title": "07:00"
      },
      {
          "value": "07:15",
          "title": "07:15"
      },
      {
          "value": "07:30",
          "title": "07:30"
      },
      {
          "value": "07:45",
          "title": "07:45"
      },
      {
          "value": "08:00",
          "title": "08:00"
      },
      {
          "value": "08:15",
          "title": "08:15"
      },
      {
          "value": "08:30",
          "title": "08:30"
      },
      {
          "value": "08:45",
          "title": "08:45"
      },
      {
          "value": "09:00",
          "title": "09:00"
      },
      {
          "value": "09:15",
          "title": "09:15"
      },
      {
          "value": "09:30",
          "title": "09:30"
      },
      {
          "value": "09:45",
          "title": "09:45"
      },
      {
          "value": "10:00",
          "title": "10:00"
      },
      {
          "value": "10:15",
          "title": "10:15"
      },
      {
          "value": "10:30",
          "title": "10:30"
      },
      {
          "value": "10:45",
          "title": "10:45"
      },
      {
          "value": "11:00",
          "title": "11:00"
      },
      {
          "value": "11:15",
          "title": "11:15"
      },
      {
          "value": "11:30",
          "title": "11:30"
      },
      {
          "value": "11:45",
          "title": "11:45"
      },
      {
          "value": "12:00",
          "title": "12:00"
      },
      {
          "value": "12:15",
          "title": "12:15"
      },
      {
          "value": "12:30",
          "title": "12:30"
      },
      {
          "value": "12:45",
          "title": "12:45"
      },
      {
          "value": "13:00",
          "title": "13:00"
      },
      {
          "value": "13:15",
          "title": "13:15"
      },
      {
          "value": "13:30",
          "title": "13:30"
      },
      {
          "value": "13:45",
          "title": "13:45"
      },
      {
          "value": "14:00",
          "title": "14:00"
      },
      {
          "value": "14:15",
          "title": "14:15"
      },
      {
          "value": "14:30",
          "title": "14:30"
      },
      {
          "value": "14:45",
          "title": "14:45"
      },
      {
          "value": "15:00",
          "title": "15:00"
      },
      {
          "value": "15:15",
          "title": "15:15"
      },
      {
          "value": "15:30",
          "title": "15:30"
      },
      {
          "value": "15:45",
          "title": "15:45"
      },
      {
          "value": "16:00",
          "title": "16:00"
      },
      {
          "value": "16:15",
          "title": "16:15"
      },
      {
          "value": "16:30",
          "title": "16:30"
      },
      {
          "value": "16:45",
          "title": "16:45"
      },
      {
          "value": "17:00",
          "title": "17:00"
      },
      {
          "value": "17:15",
          "title": "17:15"
      },
      {
          "value": "17:30",
          "title": "17:30"
      },
      {
          "value": "17:45",
          "title": "17:45"
      },
      {
          "value": "18:00",
          "title": "18:00"
      },
      {
          "value": "18:15",
          "title": "18:15"
      },
      {
          "value": "18:30",
          "title": "18:30"
      },
      {
          "value": "18:45",
          "title": "18:45"
      },
      {
          "value": "19:00",
          "title": "19:00"
      },
      {
          "value": "19:15",
          "title": "19:15"
      },
      {
          "value": "19:30",
          "title": "19:30"
      },
      {
          "value": "19:45",
          "title": "19:45"
      },
      {
          "value": "20:00",
          "title": "20:00"
      },
      {
          "value": "20:15",
          "title": "20:15"
      },
      {
          "value": "20:30",
          "title": "20:30"
      },
      {
          "value": "20:45",
          "title": "20:45"
      },
      {
          "value": "21:00",
          "title": "21:00"
      },
      {
          "value": "21:15",
          "title": "21:15"
      },
      {
          "value": "21:30",
          "title": "21:30"
      },
      {
          "value": "21:45",
          "title": "21:45"
      },
      {
          "value": "22:00",
          "title": "22:00"
      },
      {
          "value": "22:15",
          "title": "22:15"
      },
      {
          "value": "22:30",
          "title": "22:30"
      },
      {
          "value": "22:45",
          "title": "22:45"
      },
      {
          "value": "23:00",
          "title": "23:00"
      },
      {
          "value": "23:15",
          "title": "23:15"
      },
      {
          "value": "23:30",
          "title": "23:30"
      },
      {
          "value": "23:45",
          "title": "23:45"
      },
      {
          "value": "24:00",
          "title": "24:00"
      }
    ]
  }

  ngOnInit() {
    //console.log(this.editingItem);

    this.ym.hit('shift-edit', { params: { title: "Окно редактирования смены" } });

    this.dataForm = this.formBuilder.group({
      date: [, [Validators.pattern(/(0[1-9]|[12][0-9]|3[01])\.(0[1-9]|1[0,1,2])\.(19|20)\d{2}/), Validators.required]],
      pvz_id: [, Validators.required],
      user_id: [, Validators.required],
      pay_method: ['none'],
      shift_rate: [, Validators.min(0)],
      shift_hour_rate: [, Validators.min(0)],
      time_start: ['09:00', [Validators.pattern(/^\d\d:\d\d$/)]],
      time_start_fact: [, Validators.pattern(/^\d\d:\d\d$/)],
      time_end_fact: [, Validators.pattern(/^\d\d:\d\d$/)],
      time_end: ['21:00', [Validators.pattern(/^\d\d:\d\d$/)]],
      time_break: [, [Validators.min(0)]],
      repeat: [0],
      repeat_date: [, [Validators.pattern(/(0[1-9]|[12][0-9]|3[01])\.(0[1-9]|1[0,1,2])\.(19|20)\d{2}/)]],
      comment: [],
    });

    if (!this.user.data.subActive)
      this.dataForm.get('repeat').disable();

    if (!this.canEditShift) {
      this.dataForm.get('user_id').disable();
      this.dataForm.get('pay_method').disable();
      this.dataForm.get('shift_rate').disable();
      this.dataForm.get('shift_hour_rate').disable();
      this.dataForm.get('time_start_fact').disable();
      this.dataForm.get('time_end_fact').disable();
      this.dataForm.get('repeat').disable();
      this.dataForm.get('repeat_date').disable();
      this.dataForm.get('comment').disable();
    }

    this.today = new Date(Date.now() - new Date().getTimezoneOffset() * 60 * 1000).toISOString().slice(0,);
    if (this.editingItem.action == 'blank') {
      this.editingItem.date = new Date(Date.now() - new Date().getTimezoneOffset() * 60 * 1000).toISOString().slice(0, 10)
    }

    this.prepareShift();

    if (this.editingItem && !this.editingItem.user_name) { // если редактируем смену, но нет полной инфы, то загружаем
      this.getShift();
    }
  }

  resetFormValues() {
    this.dataForm.patchValue({
      pay_method: 'none',
      shift_rate: null,
      shift_hour_rate: null,
      time_start: '09:00',
      time_start_fact: null,
      time_end_fact: null,
      time_end: '21:00',
      time_break: null,
      repeat: 0,
      repeat_date: null,
      comment: null,
    })
    delete this.editingItem.id
    delete this.editingItem.time_start_fact
    delete this.editingItem.time_end_fact
    delete this.editingItem.pvz_id
    delete this.editingItem.user_id
     
  }

  prepareShift() {
    //console.log(JSON.stringify(this.editingItem.date, null, 2));

    if (this.editingItem.requests_exists)
      this.getRequests();

    if (!this.editingItem.can_del) {
      this.dataForm.get('pay_method').disable();
      this.dataForm.get('shift_rate').disable();
      this.dataForm.get('shift_hour_rate').disable();
      this.dataForm.get('time_start').disable();
      this.dataForm.get('time_start_fact').disable();
      this.dataForm.get('time_end').disable();
      this.dataForm.get('time_end_fact').disable();
      this.dataForm.get('time_break').disable();
    } else {
      this.dataForm.get('pay_method').enable();
      this.dataForm.get('shift_rate').enable();
      this.dataForm.get('shift_hour_rate').enable();
      this.dataForm.get('time_start').enable();
      this.dataForm.get('time_start_fact').enable();
      this.dataForm.get('time_end').enable();
      this.dataForm.get('time_end_fact').enable();
      this.dataForm.get('time_break').enable();
    }

    if (this.editingItem.time_break == 0)
      this.editingItem.time_break = null;
    // проставляем оплату за смену
    if (this.editingItem.shift_rate)
      this.editingItem.pay_method = 'shift_rate'
    else if (this.editingItem.shift_hour_rate)
      this.editingItem.pay_method = 'shift_hour_rate'
    else if (this.editingItem.formula_id)
      this.editingItem.pay_method = this.editingItem.formula_id
    else
      this.editingItem.pay_method = 'none'
    
    // если помощник, то выбираем дополнительную смену
    if (!this.editingItem.status)
      this.editingItem.status = this.editingItem.is_helper ? 2 : 1;

    //console.log(JSON.stringify(this.editingItem.pvz_id, null, 2));

    this.dataForm.patchValue(this.editingItem) 

    if (this.editingItem.time_start_fact)
      this.dataForm.get('time_start_fact').setValue(this.datePipe.transform(this.editingItem.time_start_fact, 'HH:mm'))
    if (this.editingItem.time_end_fact)
      this.dataForm.get('time_end_fact').setValue(this.datePipe.transform(this.editingItem.time_end_fact, 'HH:mm'))
    this.factTimeChanged();

    //console.log(this.editingItem.date);
    
    if (this.editingItem.date)
      this.dataForm.get('date').setValue(this.api.formatInputDate(this.editingItem.date, 'dd.mm.yyyy'))

    this.processingFormulas(this.formulas)

    // если не указано время смены, подставляем время смены пвз
    if (!this.editingItem.time_start || !this.editingItem.time_end) {
      if (this.editingItem?.pvz_shift_time) {
        let time = this.editingItem.pvz_shift_time.split('-');
        this.dataForm.get('time_start').setValue(time[0].padStart(5, '0'))
        this.dataForm.get('time_end').setValue(time[1].padStart(5, '0'))
      } else {
        this.dataForm.get('time_start').setValue('09:00')
        this.dataForm.get('time_end').setValue('21:00')
      }
    } else {
      this.dataForm.get('time_start').setValue(this.editingItem.time_start.slice(0, 5))
      this.dataForm.get('time_end').setValue(this.editingItem.time_end.slice(0, 5))
    }

    // заполняем списки выбора времени начала и завершения смены
    this.setShiftTimes();
  }

  setShiftHours() {
    this.editingItem.hours = Math.round(((this.calculateShiftTime(this.dataForm.get('time_start').getRawValue(), this.dataForm.get('time_end').getRawValue()) - this.dataForm.get('time_break').getRawValue()) / 60 + Number.EPSILON) * 100) / 100;
    if (this.editingItem.hours <= 0) {
      this.dataForm.get('time_break').setErrors({ notValid: true });
      return;
    }
    this.methodChanged();
  }

  calculateShiftTime(start: string, end: string) {
    let partsStart = start.split(':');
    let partsEnd = end.split(':');

    let hoursStart = parseInt(partsStart[0]);
    let minutesStart = parseInt(partsStart[1]);
    let hoursEnd = parseInt(partsEnd[0]);
    let minutesEnd = parseInt(partsEnd[1]);

    let totalMinutesStart = hoursStart * 60 + minutesStart;
    let totalMinutesEnd = hoursEnd * 60 + minutesEnd;

    return totalMinutesEnd - totalMinutesStart;
  }

  setShiftTimes() {
    if (this.dataForm.get('time_end').getRawValue()) {
      this.shiftTimesStart = this.shiftTimes.filter(t => t.value < this.dataForm.get('time_end').getRawValue())
    } else {
      this.shiftTimesStart = this.shiftTimes
    }

    if (this.dataForm.get('time_start').getRawValue()) {
      this.shiftTimesEnd = this.shiftTimes.filter(t => t.value > this.dataForm.get('time_start').getRawValue()).map(t => { 
        let diff = this.calculateShiftTime(this.dataForm.get('time_start').getRawValue(), t.value)

        let hours = Math.floor(diff / 60);
        let mins = diff % 60;
        return { value: t.value, title: t.title + `   (${ hours > 0 ? this.spellCount.transform(hours, 'ч.', "ч.", "ч.") : '' }${ hours && mins ? ' ' : '' }${ mins > 0 ? mins + ' м.' : "" })` }
      })
    } else {
      this.shiftTimesEnd = this.shiftTimes
    }
    this.setShiftHours();
  }

  async getShift() {
    //console.log(JSON.stringify(this.dataForm.value, null, 2));

    if (this.dataForm.get('date').valid && this.datePicker)
      this.datePicker.reset(this.api.formatInputDate(this.dataForm.value.date, 'yyyy-mm-dd'));
    
    if (this.dataForm.value.date && this.dataForm.get('date').valid && this.dataForm.get('pvz_id').value && this.dataForm.get('user_id').getRawValue()) {
      await this.api.loadingPresent();
      this.api.get(`users_shifts/${ this.api.formatInputDate(this.dataForm.value.date, 'yyyy-mm-dd') }/1`, { user_id: this.dataForm.get('user_id').getRawValue(), pvz_id: this.dataForm.value.pvz_id })
        .subscribe({
          next: (res: any) => {
            this.api.loadingDismiss();
            if (res.success) {
              this.resetFormValues()

              //console.log(res);
              if (res.days[0]?.shifts[0]) {
                this.dataForm.get('repeat').disable();
                this.editingItem = { ...this.editingItem, ...res.days[0].shifts[0] }
                this.editingItem.can_del = !res.days[0].shifts[0].salary_exists
                this.dataUpdated = this.editingItem.updated;
                this.dataUpdatedUserName = this.editingItem.updated_name;
              } else {
                this.editingItem.can_del = true;
                if (this.user.data.subActive && this.canEditShift)
                  this.dataForm.get('repeat').enable();
              }
              let day = res.days[0]
              let pvz = res.pvzs[0]
              let user = res.pvzs[0].users[0]
              delete this.editingItem.date; // чтобы не триггерить изменения поля даты в prepareShift
              this.editingItem.pvz_address = this.api.formatPvzType(pvz.type, 'short') + ' | ' + pvz.pvz_address
              this.editingItem.pvz_shift_time = pvz.shifts_time.split(',')[new Date(day.date).getDay() == 0 ? 6 : new Date(day.date).getDay() - 1];
              this.editingItem.formula_id_pvz = pvz.formula_id
              this.editingItem.user_name = user.name; 
              this.editingItem.is_helper = user.role == 3 || user.role == 4;
              this.editingItem.formula_id_user = user.formula_id
              this.editingItem.formula_id_role = user.role_formula_id
              //console.log(this.editingItem);
              
              this.prepareShift();
              //console.log(JSON.stringify(this.dataForm.value, null, 2));
            } else {
              if (res.code == 401) {
                return this.modalCtrl.dismiss(null, 'close');
              }
            }
          }
        }
      );
    }
  }

  getFormulas(): Promise<boolean> {
    return new Promise((resolve, reject) => {
      this.api.getFormulas(2).subscribe((res: any) => {
        if (res.success) {
          this.formulas = res.items;
          this.processingFormulas(res.items);
          resolve(true);
        } else {
          this.api.toastPresent('Не удалось загрузить список формул для расчёта. Повторите попытку позже.');
          this.close();
          reject(false)
        }
      });
    })
  }

  processingFormulas(formulas) {
    let formulaExists = false;
    for (let i = 0; i < formulas?.length; i++) {
      if (formulas[i].id == this.editingItem.formula_id_pvz)
        this.editingItem.formula_id_pvz_title = formulas[i].title;
      if (formulas[i].id == this.editingItem.formula_id_user)
        this.editingItem.formula_id_user_title = formulas[i].title;
      if (formulas[i].id == this.editingItem.formula_id_role)
        this.editingItem.formula_id_role_title = formulas[i].title;

      if (this.editingItem.formula_id && formulas[i].id == this.editingItem.formula_id) {
        formulaExists = true;
        this.editingItem.formula_id_title = formulas[i].title
      }
      if (!formulaExists && this.editingItem.formula_id) {
        this.editingItem.formula_id_title = 'удалена'
      }
    }

    //console.log(this.editingItem);

    // подставляем значение подходящией формулы для новых и нет смен, но если не указана формула, чтобы нормально работало и при заявках сотрудников    
    if (!this.editingItem.formula_id && !this.editingItem.shift_rate && !this.editingItem.shift_hour_rate && (this.editingItem.action == 'blank' || this.editingItem.action == 'new' || this.editingItem.action != 'new' && this.dataForm.get('pay_method').value != 'none')) {
      if (this.editingItem.formula_id_pvz && !this.editingItem.is_helper) { // если есть формула пвз, то только для основных смен
        this.editingItem.formula_id = this.editingItem.formula_id_pvz;
      } else if (this.editingItem.formula_id_user) {
        this.editingItem.formula_id = this.editingItem.formula_id_user;
      } else if (this.editingItem.formula_id_role) {
        this.editingItem.formula_id = this.editingItem.formula_id_role;
      }
      if (this.editingItem.formula_id)
        this.dataForm.get('pay_method').setValue(this.editingItem.formula_id)
    }

    this.methodChanged();
  }

  async showSelect(type: string) {
    let modal;
    switch (type) {
      case 'pvz':
        modal = await this.modalCtrl.create({
          component: PvzSelectComponent,
          componentProps: { id: this.dataForm.get('pvz_id').value },
          cssClass: 'pvz-select-modal',
        });
        await modal.present();
    
        var { data, role } = await modal.onDidDismiss();
    
        if (role == 'pvz') {
          this.dataForm.patchValue({ pvz_id: data.item.id })
          this.editingItem.pvz_address = `${ this.api.formatPvzType(data.item.type, 'short') } | ${ data.item.pvz_address }`
        }
        break;
      case 'user':
        modal = await this.modalCtrl.create({
          component: UserSelectComponent,
          componentProps: { id: this.dataForm.get('user_id').value, date: this.api.formatInputDate(this.dataForm.get('date').value, 'yyyy-mm-dd') },
          cssClass: 'user-select-modal',
        });
        await modal.present();
    
        var { data, role } = await modal.onDidDismiss();
    
        if (role == 'selected') {
          this.dataForm.patchValue({ user_id: data.item.id })
          this.editingItem.user_name = data.item.name
        }
        break;
    }
  }

  setInputDate(e, type: string) {
    if (document.querySelector('.datetime-select.datetime-ready') && document.getElementsByClassName('month-year-picker-open').length == 0) {
      e.target.confirm(true)
      this.dataForm.get(type).setValue(this.api.formatInputDate(e.detail.value, 'dd.mm.yyyy'))
    }
  }

  repeatDateChanged() {
    //console.log(this.dataForm.value.repeat_date);
    
    let repeatDate = new Date(this.api.formatInputDate(this.dataForm.value.repeat_date, 'yyyy-mm-dd')).getTime()
    let startDate = new Date(this.api.formatInputDate(this.dataForm.value.date, 'yyyy-mm-dd')).getTime()
    if (repeatDate - startDate > 6 * 31 * 24 * 60 * 60 * 1000 || startDate > repeatDate)
      this.dataForm.get('repeat_date').setErrors({ notValid: true });

    if (this.dataForm.get('repeat_date').valid)
      this.dateRepeatPicker.reset(this.api.formatInputDate(this.dataForm.value.repeat_date, 'yyyy-mm-dd'));
  }

  repeatChanged() {
    if (!this.dataForm.value.repeat_date) {
      if (this.dataForm.value.repeat != 0) {
        let repeatUntil = this.repeatUntil ? this.repeatUntil : new Date(new Date(this.dataForm.get('date').valid ? this.api.formatInputDate(this.dataForm.value.date, 'yyyy-mm-dd') : new Date()).getTime() + 14 * 24 * 60 * 60 * 1000).toISOString().slice(0, 10)
        this.dataForm.get('repeat_date').setValue(this.api.formatInputDate(repeatUntil, 'dd.mm.yyyy'));
        this.dataForm.get('repeat_date').addValidators(Validators.required);
        this.dataForm.get('time_start_fact').setValue(null);
        this.dataForm.get('time_end_fact').setValue(null);
      } else {
        this.dataForm.get('repeat_date').setValue(null);
        this.dataForm.get('repeat_date').removeValidators(Validators.required);
      }
      this.dataForm.get('repeat_date').updateValueAndValidity();
    }
  }

  factTimeChanged() {
    this.factShiftTime = null;
    if (this.dataForm.get('time_start_fact').getRawValue()) {
      let end = this.dataForm.get('time_end_fact').getRawValue();
      if (!end)
          end = this.api.formatInputDate(this.dataForm.get('date').value, 'yyyy-mm-dd') == new Date(Date.now() - new Date().getTimezoneOffset() * 60 * 1000).toISOString().slice(0, 10) && this.datePipe.transform(new Date(), 'HH:mm') < this.dataForm.get('time_end').getRawValue().slice(0, -3) ? this.datePipe.transform(new Date(), 'HH:mm') : this.dataForm.get('time_end').getRawValue().slice(0, -3)

      let diff = this.calculateShiftTime(this.dataForm.get('time_start_fact').getRawValue(), end)

      if (diff <= 0) {
        this.dataForm.get('time_end_fact').setErrors({ notValid: true });
        return;
      }

      let hours = Math.floor(diff / 60);
      let mins = diff % 60;

      this.factShiftTime = `${ hours } ч. ${ mins } мин.`
    }
  }

  methodChanged() {
    // костыль, чтобы окно выбора формулы не показывалось 2 раза (триггерит этот метод 2 раза при изменении) 
    if (!this.selectFormulaChoosen) {
      // конкретная формула
      if (!isNaN(parseInt(this.dataForm.get('pay_method').getRawValue()))) {
        this.editingItem.formula_id = parseInt(this.dataForm.get('pay_method').getRawValue())
        if (this.formulas?.length > 0)
          this.formula = this.api.buildShiftFormula(this.formulas.find((f: any) => f.id == this.editingItem.formula_id)?.formula, this.editingItem.hours)
        this.dataForm.get('shift_rate').setValue(null)
        this.dataForm.get('shift_hour_rate').setValue(null)
      } else {
        this.formula = [];
        switch (this.dataForm.get('pay_method').getRawValue()) {
          case 'none': // без оплаты
            this.dataForm.get('shift_hour_rate').removeValidators(Validators.required);
            this.dataForm.get('shift_rate').removeValidators(Validators.required);
            this.dataForm.get('shift_rate').setValue(null)
            this.dataForm.get('shift_hour_rate').setValue(null)
            this.editingItem.formula_id = null   
            break;
          case 'shift_rate': // ставка за смену
            this.dataForm.get('shift_rate').addValidators(Validators.required);
            this.dataForm.get('shift_hour_rate').removeValidators(Validators.required);
            this.dataForm.get('shift_hour_rate').setValue(null)
            this.editingItem.formula_id = null   
            setTimeout(() => this.valueInput.setFocus(), 300);
            break;
          case 'shift_hour_rate': // ставка за час
            this.dataForm.get('shift_hour_rate').addValidators(Validators.required);
            this.dataForm.get('shift_rate').removeValidators(Validators.required);
            this.dataForm.get('shift_rate').setValue(null)
            this.editingItem.formula_id = null   
            this.formula = [{ name: 'Оплата за смену', type: 1, value: Math.round((this.dataForm.get('shift_hour_rate').getRawValue() * this.editingItem.hours + Number.EPSILON) * 100) / 100 }]
            setTimeout(() => this.valueInput.setFocus(), 300);
            break;
          case 'formula_id': // выбор формулы
            this.dataForm.get('shift_hour_rate').removeValidators(Validators.required);
            this.dataForm.get('shift_rate').removeValidators(Validators.required);
            this.selectFormulaChoosen = true;
            setTimeout(() => {
              if (this.editingItem.formula_id)
                this.dataForm.get('pay_method').setValue(this.editingItem.formula_id);
              else if (this.dataForm.get('shift_rate').getRawValue())
                this.dataForm.get('pay_method').setValue('shift_rate')
              else if (this.dataForm.get('shift_hour_rate').getRawValue())
                this.dataForm.get('pay_method').setValue('shift_hour_rate')
              else
                this.dataForm.get('pay_method').setValue('none');
            }, 100);
            this.selectFormula();
            break;
        }
        this.dataForm.get('shift_hour_rate').updateValueAndValidity();
        this.dataForm.get('shift_rate').updateValueAndValidity();
      }
    }
  }

  async selectFormula() {
    const modal = await this.modalCtrl.create({
      component: SalaryFormulaSelectComponent,
      componentProps: { type: 2, subActive: this.user.data.subActive },
      cssClass: 'salary-formula-select-modal shift-edit',
    });
    modal.present();

    const { data, role } = await modal.onWillDismiss();

    this.selectFormulaChoosen = false;
    if (role === 'selected') {
      let setMethod = () => {
        this.dataForm.get('pay_method').setValue(data.item.id);
        this.methodChanged()
        this.editingItem.formula_id_title = data.item.title
      }
      this.getFormulas().then(() =>setMethod())
    }
  }

  pinFormatter(value: number) {
    return `${ value }:00`;
  }

  setStatus(status: number) {
    this.editingItem.status = status;
    if (status <= 2) {
      this.dataForm.get('pay_method').addValidators(Validators.required);
      this.dataForm.get('time_start').addValidators(Validators.required);
      this.dataForm.get('time_end').addValidators(Validators.required);
    } else {
      this.dataForm.get('pay_method').removeValidators(Validators.required);
      this.dataForm.get('time_start').removeValidators(Validators.required);
      this.dataForm.get('time_end').removeValidators(Validators.required);
    }
    this.dataForm.get('pay_method').updateValueAndValidity();
    this.dataForm.get('time_start').updateValueAndValidity();
    this.dataForm.get('time_end').updateValueAndValidity();
  }

  getRequests() {
    this.requests = [];
    this.requestsLoading = true;
    if (this.reqR)
      this.reqR.unsubscribe();
    this.reqR = this.api.get(`shifts_requests/${ this.editingItem.id }`)
      .subscribe({
        next: (res: any) => {
          this.requestsLoading = false;
          if (res.success) {
            this.requests = res.items;
          } else {
            this.api.toastPresent('Не удалось загрузить историю запросов сотрудников.');
          }
        }
    });
  }

  async approveRequest(data) {
    let sendRequest = async () => {
      await this.api.loadingPresent();
      if (this.reqR)
        this.reqR.unsubscribe();
      this.reqR = this.api.post(`shift_request`, { id: data.id, approve: data.approve }, true)
        .subscribe({
          next: (res: any) => {
            this.api.loadingDismiss();
            if (res.success) {
              if (data.is_new && !data.approve)
                this.del();
              else {
                if (!data.is_new && data.approve) {
                  this.getShift();
                  this.events.publishShiftUpdated();
                } else {
                  this.getRequests()
                }
              }
            } else {
              this.api.toastPresent('Не удалось выполнить операцию. Повторите попытку позднее.');
            }
          }
      });
    }
    if (data.is_new && !data.approve) {
      const alert = await this.alertCtrl.create({
        message: `Вы собираетесь отклонить запрос на добавление смены. Она будет удалена из графика работ. Продолжить?`,
        buttons: [{
            text: 'Отмена',
            role: 'cancel'
          },
          {
            text: 'Да',
            role: 'confirm',
            handler: () => {
              sendRequest();
            }
          }
        ],
      });
      await alert.present();
    } else {
      sendRequest();
    }
  }

  async del() {
    return this.modalCtrl.dismiss(null, 'deleting');
  }

  async save() {
    if (this.dataForm.valid) {
      let data: any = {
        id: this.editingItem.id,
        status: this.editingItem.status,
        hours: this.editingItem.hours,
        formula_id: this.editingItem.formula_id,
      };

      data = { ...data, ...this.dataForm.getRawValue() }
      delete data.pay_method

      data.date = this.api.formatInputDate(data.date, 'yyyy-mm-dd')
      if (data.repeat_date)
        data.repeat_date = this.api.formatInputDate(data.repeat_date, 'yyyy-mm-dd')

      // если поля отметок не заполнены, то удаляем их, чтобы случайно не перезаписать
      if (!data.time_start_fact)
        delete data.time_start_fact
      else
        data.time_start_fact = new Date(parseInt(data.date.slice(0, 4)), parseInt(data.date.slice(5, 7)) - 1, parseInt(data.date.slice(8, 10)), data.time_start_fact.slice(0, 2), data.time_start_fact.slice(3, 5)).getTime()
      if (!data.time_end_fact)
        delete data.time_end_fact
      else
        data.time_end_fact = new Date(parseInt(data.date.slice(0, 4)), parseInt(data.date.slice(5, 7)) - 1, parseInt(data.date.slice(8, 10)), data.time_end_fact.slice(0, 2), data.time_end_fact.slice(3, 5)).getTime()

      if (data.status >= 3) {
        delete data.formula_id
        delete data.pay_method
        delete data.hours
        delete data.shift_rate
        delete data.shift_hour_rate
        delete data.time_break
        delete data.time_start
        delete data.time_end
        delete data.time_start_fact
        delete data.time_end_fact
      }

      //console.log(data);

      let upload = async () => {
        await this.api.loadingPresent();
        this.api.post('shift', data, true)
          .subscribe({
            next: (res: any) => {
              this.api.loadingDismiss();
              if (res.success) {
                if (!this.editingItem.id)
                  this.ym.reachGoal('SHIFT_ADDED', { "Расчёт смены": this.editingItem.formula_id ? "Формула" : (this.dataForm.value.shift_rate || this.dataForm.value.shift_hour_rate ? "Ставка" : 'Нет') })
                if (this.canEditShift || !this.editingItem?.id) {
                  this.modalCtrl.dismiss({ requests_exists: !this.canEditShift, requests_pending: !this.canEditShift, repeat: data.repeat, date: data.date, user_id: data.user_id, status: data.status, old_status: this.editingItem.old_status, time_start: data.time_start, time_end: data.time_end, time_break: data.time_break, pvz_id: data.pvz_id, comment: data.comment, hours: data.hours, formula_id: data.formula_id, id: res.id, shift_rate: data.shift_rate, shift_hour_rate: data.shift_hour_rate, time_start_fact: data.time_start_fact, time_end_fact: data.time_end_fact }, 'saved');
                } else if (this.editingItem?.id) {
                  this.api.toastPresent('Ваша заявка на изменение смены была отправлена руководителю. После его одобрения смена будет обновлена.')
                  this.modalCtrl.dismiss({ requests_exists: !this.canEditShift, requests_pending: !this.canEditShift });
                }
              } else {
                this.api.toastPresent(res.message);
                if (res.code == 401) {
                  return this.modalCtrl.dismiss(null, 'close');
                }
              }
            }
          });
      }

      if (data.repeat) {
        const alert = await this.alertCtrl.create({
          message: `Вы собираетесь повторить смену с ${ this.api.formatInputDate(data.date, 'dd.mm.yyyy') } по ${ this.api.formatInputDate(data.repeat_date, 'dd.mm.yyyy') }. Если в указанном периоде и на выбранном ПВЗ у сотрудника есть неоплаченные смены, они будут обновлены. Продолжить?`,
          buttons: [{
              text: 'Отмена',
              role: 'cancel'
            },
            {
              text: 'Да',
              role: 'confirm',
              handler: () => {
                upload();
              }
            }
          ],
        });
        await alert.present();
      } else {
        upload();
      }
    } else {
      this.api.toastPresent('Укажите корректные значения в форме!')
    }
  }

  close() {
    this.modalCtrl.dismiss();
  }

  ngOnDestroy() {
    if (this.reqR)
      this.reqR.unsubscribe();
  }
}
