import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular'
import { SettingsShiftsComponent } from './settings-shifts.component';

@NgModule({
	declarations: [
		SettingsShiftsComponent,
	],
	imports: [
		CommonModule,
		FormsModule,
		IonicModule,
		ReactiveFormsModule
	],
	exports: [
		SettingsShiftsComponent
	]
})

export class SettingsShiftsModule {}
