import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { AlertController, ModalController, NavController } from '@ionic/angular';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';

import { ApiService } from '../../services/api.service';
import { UserService } from '../../services/user.service';
import { YmService } from '../../services/ym.service';

@Component({
  selector: 'app-salary-formula-edit',
  templateUrl: './salary-formula-edit.component.html',
  styleUrls: ['./salary-formula-edit.component.scss'],
})
export class SalaryFormulaEditComponent implements OnInit {

  @ViewChild('title') valueInput: any;
  @Input() editingItem: any;
  dataForm: FormGroup;
  dataUpdated: string;
  dataUpdatedUserName: string;
  templateBuilder;
  
  constructor(private alertCtrl: AlertController, private api: ApiService, private formBuilder: FormBuilder, private modalCtrl: ModalController, private navCtrl: NavController, private router: Router, public user: UserService, private ym: YmService) {}

  async ngOnInit() {
    this.ym.hit('salary-formula-edit', { params: { title: 'Окно редактирования формулы смены' } });

    this.templateBuilder = {
      salary: null,
      salary_point: null
    }
    this.dataForm = this.formBuilder.group({
      title: [, [Validators.required, Validators.minLength(1), Validators.maxLength(50)]],
      comment: [, Validators.maxLength(250)],
      fixed: [, Validators.min(0)],
      fixed_1: [, Validators.min(0)],
      fixed_2: [, Validators.min(0)],
      fixed_3: [, Validators.min(0)],
      fixed_4: [, Validators.min(0)],
      fixed_hour: [, Validators.min(0)],
      fixed_hour_1: [, Validators.min(0)],
      fixed_hour_2: [, Validators.min(0)],
      fixed_hour_3: [, Validators.min(0)],
      fixed_hour_4: [, Validators.min(0)],
      ready_count_min: [, [Validators.min(0)]],
      ready_count: [, Validators.min(0)],
      ready_price: [, [Validators.min(0), Validators.max(100)]],
      recieved_count_min: [, [Validators.min(0)]],
      recieved_count: [, Validators.min(0)],
      recieved_price: [, [Validators.min(0), Validators.max(100)]],
      returned_count_min: [, [Validators.min(0)]],
      returned_count: [, Validators.min(0)],
      returned_price: [, [Validators.min(0), Validators.max(100)]],
      ready_reward: [, [Validators.min(0), Validators.max(100)]],
      ready_hold: [, [Validators.min(0), Validators.max(100)]],
      rating_reward: [, [Validators.min(0), Validators.max(100)]],
      rating_hold: [, [Validators.min(0), Validators.max(100)]],
      repack_reward: [, [Validators.min(0), Validators.max(100)]],
      users: [, Validators.min(0)],
      clean: [, Validators.min(0)],
      shift_salary_min: [, Validators.min(0)],
      employment_reward: [, Validators.min(0)],
      shift_reward_fix: [, Validators.min(0)],
      // при добавлении параметра премии не забыть отключить поле для бесплатного тарифа ниже
      // добавить в api.buildShiftFormula
      // указать в api post salary_formula, generateRewards
    });

    if (this.editingItem) {
      let filteredItem = { ...this.editingItem };
      try {
        let formula = JSON.parse(filteredItem.formula)
        delete filteredItem.formula;
        this.dataForm.patchValue(filteredItem);
        formula.forEach((rule: any) => this.dataForm.patchValue(rule));

        this.templateBuilder.salary = this.dataForm.value.fixed_1 || this.dataForm.value.fixed_hour_1 ? true : false
        this.templateBuilder.salary_point = this.dataForm.value.fixed || this.dataForm.value.fixed_1 ? 'shift' : 'hour'
      } catch(e) {
        this.api.toastPresent("Не удалось разобрать формулу. Возможно она повреждена. Повторите попытку или свяжитесь с поддержкой.");
        return this.close();
      }

      this.dataUpdated = this.editingItem.updated;
      this.dataUpdatedUserName = this.editingItem.updated_name ? this.editingItem.updated_name : this.editingItem.added_name;
    }

    if (!this.user.data.subActive) {
      this.templateBuilder.salary = false;
      this.dataForm.patchValue({
        fixed_1: null,
        fixed_2: null,
        fixed_3: null,
        fixed_4: null,
        fixed__hour_1: null,
        fixed__hour_2: null,
        fixed__hour_3: null,
        fixed__hour_4: null
      })
      this.dataForm.get('ready_count').disable();
      this.dataForm.get('ready_count_min').disable();
      this.dataForm.get('ready_price').disable();
      this.dataForm.get('recieved_count').disable();
      this.dataForm.get('recieved_count_min').disable();
      this.dataForm.get('recieved_price').disable();
      this.dataForm.get('returned_count').disable();
      this.dataForm.get('returned_count_min').disable();
      this.dataForm.get('returned_price').disable();
      this.dataForm.get('ready_reward').disable();
      this.dataForm.get('ready_hold').disable();
      this.dataForm.get('rating_reward').disable();
      this.dataForm.get('rating_hold').disable();
      this.dataForm.get('repack_reward').disable();
      this.dataForm.get('users').disable();
      this.dataForm.get('clean').disable();
      this.dataForm.get('shift_salary_min').disable();
      this.dataForm.get('employment_reward').disable();
      this.dataForm.get('shift_reward_fix').disable();
    }

    setTimeout(() => {
      this.valueInput.setFocus()
      this.checkRewards();
    }, 500);
  }

  templateBuilderChanged() {
    if (this.templateBuilder.salary) {
      this.dataForm.get('fixed').setValue(null);
      this.dataForm.get('fixed_hour').setValue(null);
    } else {
      this.dataForm.get('fixed_1').setValue(null);
      this.dataForm.get('fixed_2').setValue(null);
      this.dataForm.get('fixed_3').setValue(null);
      this.dataForm.get('fixed_4').setValue(null);
      this.dataForm.get('fixed_hour_1').setValue(null);
      this.dataForm.get('fixed_hour_2').setValue(null);
      this.dataForm.get('fixed_hour_3').setValue(null);
      this.dataForm.get('fixed_hour_4').setValue(null);
    }
    if (this.templateBuilder.salary_point == 'shift') {
      this.dataForm.get('fixed_hour').setValue(null);
      this.dataForm.get('fixed_hour_1').setValue(null);
      this.dataForm.get('fixed_hour_2').setValue(null);
      this.dataForm.get('fixed_hour_3').setValue(null);
      this.dataForm.get('fixed_hour_4').setValue(null);
    } else {
      this.dataForm.get('fixed').setValue(null);
      this.dataForm.get('fixed_1').setValue(null);
      this.dataForm.get('fixed_2').setValue(null);
      this.dataForm.get('fixed_3').setValue(null);
      this.dataForm.get('fixed_4').setValue(null);
    }
  }

  checkRewards() {
    if ((this.dataForm.get('fixed').value || this.dataForm.get('fixed_hour').value) && (this.dataForm.get('fixed_1').value || this.dataForm.get('fixed_2').value || this.dataForm.get('fixed_3').value || this.dataForm.get('fixed_4').value || this.dataForm.get('fixed_hour_1').value || this.dataForm.get('fixed_hour_2').value || this.dataForm.get('fixed_hour_3').value || this.dataForm.get('fixed_hour_4').value)) {
      if (this.dataForm.get('fixed').value)
        this.dataForm.get('fixed').setErrors({ notValid: true })
      if (this.dataForm.get('fixed_hour').value)
        this.dataForm.get('fixed_hour').setErrors({ notValid: true })
      if (this.dataForm.get('fixed_1').value)
        this.dataForm.get('fixed_1').setErrors({ notValid: true })
      if (this.dataForm.get('fixed_2').value)
        this.dataForm.get('fixed_2').setErrors({ notValid: true })
      if (this.dataForm.get('fixed_3').value)
        this.dataForm.get('fixed_3').setErrors({ notValid: true })
      if (this.dataForm.get('fixed_4').value)
        this.dataForm.get('fixed_4').setErrors({ notValid: true })
      if (this.dataForm.get('fixed_hour_1').value)
        this.dataForm.get('fixed_hour_1').setErrors({ notValid: true })
      if (this.dataForm.get('fixed_hour_2').value)
        this.dataForm.get('fixed_hour_2').setErrors({ notValid: true })
      if (this.dataForm.get('fixed_hour_3').value)
        this.dataForm.get('fixed_hour_3').setErrors({ notValid: true })
      if (this.dataForm.get('fixed_hour_4').value)
        this.dataForm.get('fixed_hour_4').setErrors({ notValid: true })
    } else if (this.dataForm.get('fixed').value && this.dataForm.get('fixed_hour').value) {
      this.dataForm.get('fixed').setErrors({ notValid: true })
      this.dataForm.get('fixed_hour').setErrors({ notValid: true })
    } else if ((this.dataForm.get('fixed_1').value || this.dataForm.get('fixed_2').value || this.dataForm.get('fixed_3').value || this.dataForm.get('fixed_4').value) && (this.dataForm.get('fixed_hour_1').value || this.dataForm.get('fixed_hour_2').value || this.dataForm.get('fixed_hour_3').value || this.dataForm.get('fixed_hour_4').value)) {
      if (this.dataForm.get('fixed_1').value)
        this.dataForm.get('fixed_1').setErrors({ notValid: true })
      if (this.dataForm.get('fixed_2').value)
        this.dataForm.get('fixed_2').setErrors({ notValid: true })
      if (this.dataForm.get('fixed_3').value)
        this.dataForm.get('fixed_3').setErrors({ notValid: true })
      if (this.dataForm.get('fixed_4').value)
        this.dataForm.get('fixed_4').setErrors({ notValid: true })
      if (this.dataForm.get('fixed_hour_1').value)
        this.dataForm.get('fixed_hour_1').setErrors({ notValid: true })
      if (this.dataForm.get('fixed_hour_2').value)
        this.dataForm.get('fixed_hour_2').setErrors({ notValid: true })
      if (this.dataForm.get('fixed_hour_3').value)
        this.dataForm.get('fixed_hour_3').setErrors({ notValid: true })
      if (this.dataForm.get('fixed_hour_4').value)
        this.dataForm.get('fixed_hour_4').setErrors({ notValid: true })
    } else if (this.dataForm.get('fixed_1').value && (!this.dataForm.get('fixed_2').value || !this.dataForm.get('fixed_3').value || this.dataForm.get('fixed_4').value) ||
        this.dataForm.get('fixed_2').value && (!this.dataForm.get('fixed_1').value || !this.dataForm.get('fixed_3').value || !this.dataForm.get('fixed_4').value) ||
        this.dataForm.get('fixed_3').value && (!this.dataForm.get('fixed_1').value || !this.dataForm.get('fixed_2').value || !this.dataForm.get('fixed_4').value) ||
        this.dataForm.get('fixed_4').value && (!this.dataForm.get('fixed_1').value || !this.dataForm.get('fixed_2').value || !this.dataForm.get('fixed_3').value)) {
      if (!this.dataForm.get('fixed_1').value)
        this.dataForm.get('fixed_1').setErrors({ notValid: true })
      if (!this.dataForm.get('fixed_2').value)
        this.dataForm.get('fixed_2').setErrors({ notValid: true })
      if (!this.dataForm.get('fixed_3').value)
        this.dataForm.get('fixed_3').setErrors({ notValid: true })
      if (!this.dataForm.get('fixed_4').value)
        this.dataForm.get('fixed_4').setErrors({ notValid: true })
    } else if (this.dataForm.get('fixed_hour_1').value && (!this.dataForm.get('fixed_hour_2').value || !this.dataForm.get('fixed_hour_3').value || this.dataForm.get('fixed_hour_4').value) ||
        this.dataForm.get('fixed_hour_2').value && (!this.dataForm.get('fixed_hour_1').value || !this.dataForm.get('fixed_hour_3').value || !this.dataForm.get('fixed_hour_4').value) ||
        this.dataForm.get('fixed_hour_3').value && (!this.dataForm.get('fixed_hour_1').value || !this.dataForm.get('fixed_hour_2').value || !this.dataForm.get('fixed_hour_4').value) ||
        this.dataForm.get('fixed_hour_4').value && (!this.dataForm.get('fixed_hour_1').value || !this.dataForm.get('fixed_hour_2').value || !this.dataForm.get('fixed_hour_3').value)) {
      if (!this.dataForm.get('fixed_hour_1').value)
        this.dataForm.get('fixed_hour_1').setErrors({ notValid: true })
      if (!this.dataForm.get('fixed_hour_2').value)
        this.dataForm.get('fixed_hour_2').setErrors({ notValid: true })
      if (!this.dataForm.get('fixed_hour_3').value)
        this.dataForm.get('fixed_hour_3').setErrors({ notValid: true })
      if (!this.dataForm.get('fixed_hour_4').value)
        this.dataForm.get('fixed_hour_4').setErrors({ notValid: true })
    } else {
      this.dataForm.get('fixed').setErrors(null)
      this.dataForm.get('fixed_hour').setErrors(null)
      this.dataForm.get('fixed_1').setErrors(null)
      this.dataForm.get('fixed_2').setErrors(null)
      this.dataForm.get('fixed_3').setErrors(null)
      this.dataForm.get('fixed_4').setErrors(null)
      this.dataForm.get('fixed_hour_1').setErrors(null)
      this.dataForm.get('fixed_hour_2').setErrors(null)
      this.dataForm.get('fixed_hour_3').setErrors(null)
      this.dataForm.get('fixed_hour_4').setErrors(null)
    }
  }

  async save() {
    if (!this.dataForm.get('fixed').value && !this.dataForm.get('fixed_hour').value && !this.dataForm.get('fixed_1').value && !this.dataForm.get('fixed_2').value && !this.dataForm.get('fixed_3').value && !this.dataForm.get('fixed_4').value && !this.dataForm.get('fixed_hour_1').value && !this.dataForm.get('fixed_hour_2').value && !this.dataForm.get('fixed_hour_3').value && !this.dataForm.get('fixed_hour_4').value)
      return this.api.toastPresent('Укажите в формуле расчёт оклада!');

    this.checkRewards();
    if (this.dataForm.valid) {
      let data = { ...this.dataForm.value };
      
      if (this.editingItem) {
        data.id = this.editingItem.id;
      }
      
      await this.api.loadingPresent();

      this.api.post('salary_formula', data, true)
        .subscribe({
          next: async (res: any) => {
            this.api.loadingDismiss();
            if (res.success) {
              this.api.getFormulas(2, true);
              if (this.editingItem?.id) {
                this.modalCtrl.dismiss({ id: res.id }, 'saved');
                const alert = await this.alertCtrl.create({
                  message: 'Формула успешно обновлена. Если необходимо пересчитать начисления для уже добавленных ранее смен, перейдите в "График работ", выберите нужные смены, нажмите на кнопку "С выбранными" и выберите пункт "Пересчитать".',
                  buttons: [{
                      text: 'Отмена',
                      role: 'cancel'
                    },
                    {
                      text: 'Перейти в График работ',
                      role: 'confirm',
                      handler: () => {
                        this.navCtrl.setDirection('root');
                        this.router.navigate(['app', 'shifts'], { state: { formula_id: res.id, formula_title: this.editingItem.title }, replaceUrl: true });
                      }
                    }
                  ],
                });
                await alert.present();
              } else {
                this.api.toastPresent("Формула успешно добавлена.");
                return this.modalCtrl.dismiss({ id: res.id }, 'saved');
              }
            } else {
              this.api.toastPresent(res.message);
              if (res.code == 401) {
                return this.modalCtrl.dismiss(null, 'close');
              }
            }
          }
        });
    } else {
      this.api.toastPresent("Указаны некорректные данные в форме. Проверьте ещё раз введённую информацию.");
    }
  }

  close() {
    return this.modalCtrl.dismiss(null, 'close');
  }
}