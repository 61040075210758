import { LOCALE_ID, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';
import { CommonModule, CurrencyPipe, DatePipe, registerLocaleData } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import localeRu from '@angular/common/locales/ru';
registerLocaleData(localeRu);

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';

import { AppComponent } from './app.component';
import { CamerasConnectComponent } from './modals/cameras-connect/cameras-connect.component';
import { MoneyDetailsEditComponent } from './modals/money-details-edit/money-details-edit.component';
import { PvzsEditComponent } from './modals/pvzs-edit/pvzs-edit.component';
import { PvzSelectComponent } from './modals/pvz-select/pvz-select.component'
import { RequestEditComponent } from './modals/request-edit/request-edit.component';
import { SalaryEditComponent } from './modals/salary-edit/salary-edit.component';
import { SalaryFormulaEditComponent } from './modals/salary-formula-edit/salary-formula-edit.component'
import { SalaryFormulaSalaryEditComponent } from './modals/salary-formula-salary-edit/salary-formula-salary-edit.component'
import { SalaryFormulaSelectComponent } from './modals/salary-formula-select/salary-formula-select.component'
import { SalaryRewardEditComponent } from './modals/salary-reward-edit/salary-reward-edit.component';
import { ShiftEditComponent } from './modals/shift-edit/shift-edit.component'
import { StatsDetailsEditComponent } from './modals/stats-details-edit/stats-details-edit.component';
import { TaskEditComponent } from './modals/task-edit/task-edit.component';
import { UsersEditComponent } from './modals/users-edit/users-edit.component';
import { UserSelectComponent } from './modals/user-select/user-select.component'
import { WelcomeMasterComponent } from './modals/welcome-master/welcome-master.component';

import { AppRoutingModule } from './app-routing.module';
import { FakeSelectModule } from './components/fake-select/fake-select.module'
import { ItemActionsModule } from './popovers/item-actions/item-actions.module';
import { ShiftRequestItemModule } from './items/shift-request-item/shift-request-item.module';
import { SubPageModule } from './pages/sub/sub.module';
import { ScrollbarModule } from './directives/scrollbar.directive.module';
import { VideoItemModule } from './items/video-item/video-item.module';

import { SpellCountPipe } from './pipes/spell-count.pipe';

import { HttpClientModule } from '@angular/common/http';
import { IonicStorageModule } from '@ionic/storage-angular';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';
import { PermissionsIconsListModule } from "./components/permissions-icons-list/permissions-icons-list.module";

@NgModule({
  declarations: [
    AppComponent,
    CamerasConnectComponent,
    MoneyDetailsEditComponent,
    PvzsEditComponent,
    PvzSelectComponent,
    RequestEditComponent,
    SalaryEditComponent,
    SalaryFormulaEditComponent,
    SalaryFormulaSalaryEditComponent,
    SalaryFormulaSelectComponent,
    SalaryRewardEditComponent,
    ShiftEditComponent,
    StatsDetailsEditComponent,
    TaskEditComponent,
    UsersEditComponent,
    UserSelectComponent,
    WelcomeMasterComponent
  ],
  imports: [
    AppRoutingModule,
    BrowserModule,
    CommonModule,
    FakeSelectModule,
    FormsModule,
    HttpClientModule,
    IonicModule.forRoot({
        backButtonText: ''
    }),
    IonicStorageModule.forRoot(),
    ItemActionsModule,
    ReactiveFormsModule,
    ScrollbarModule,
    ServiceWorkerModule.register('ngsw-worker.js', {
        enabled: environment.production,
        registrationStrategy: 'registerWhenStable:30000'
    }),
    ShiftRequestItemModule,
    SubPageModule,
    VideoItemModule,
    PermissionsIconsListModule
],
  providers: [
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    { provide: LOCALE_ID, useValue: 'ru' },
    CurrencyPipe,
    DatePipe,
    SpellCountPipe
  ],
  bootstrap: [
    AppComponent
  ],
})
export class AppModule {}
