import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { IonDatetime, ModalController } from '@ionic/angular';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

import { ApiService } from '../../services/api.service';
import { UserService } from '../../services/user.service';
import { YmService } from '../../services/ym.service';

import { PvzSelectComponent } from '../../modals/pvz-select/pvz-select.component'

@Component({
  selector: 'app-stats-details-edit',
  templateUrl: './stats-details-edit.component.html',
  styleUrls: ['./stats-details-edit.component.scss'],
})
export class StatsDetailsEditComponent implements OnInit {

  @ViewChild('datePicker') datePicker : IonDatetime
  @ViewChild('balance') balanceInput: any;
  @ViewChild('ready') readyInput: any;
  @Input() editingItem;
  userPvzs: any;
  pvzTypes: any; // список маркетплейсов по адресу
  dataForm: FormGroup;
  dataUpdated: string; // последнее обновление данных и кем
  dataUpdatedUserName: string;
  dateMax: any; // максимальная дата для выбора - сегодня
  selectedPvzType: number; // тип выбранного ПВЗ для показа нужных полей
  fakePvzAddress: string;
  fakeUserName: string;
  canAddMoney: boolean;
  canAddStats: boolean;

  constructor(public api: ApiService, private formBuilder: FormBuilder, private modalCtrl: ModalController, public user: UserService, private ym: YmService) { 
    this.userPvzs = [];
    this.dateMax = new Date(Date.now() - new Date().getTimezoneOffset() * 60 * 1000).toISOString();
    this.canAddMoney = this.user.data.role == 0 || this.user.checkPermissions([14,15])
    this.canAddStats = this.user.data.role == 0 || this.user.checkPermissions([12, 13])
  }

  async ngOnInit() {
    this.ym.hit('stats-details-edit', { params: { title: 'Окно редактирования статистики' } });

    this.dataForm = this.formBuilder.group({
      id: [],
      pvz_id: [null, Validators.required],
      date: [this.editingItem ? this.api.formatInputDate(this.editingItem.date, 'dd.mm.yyyy') : this.api.formatInputDate(new Date(Date.now() - new Date().getTimezoneOffset() * 60 * 1000).toISOString(), 'dd.mm.yyyy'), [Validators.pattern(/(0[1-9]|[12][0-9]|3[01])\.(0[1-9]|1[0,1,2])\.(19|20)\d{2}/), Validators.required]],
      type: [, Validators.required],
      ready: [, Validators.min(0)],
      ready_price: [, Validators.min(0)],
      recieved: [, Validators.min(0)],
      recieved_price: [, Validators.min(0)],
      users: [, Validators.min(0)],
      returned: [, Validators.min(0)],
      returned_price: [, Validators.min(0)],
      balance: [],
      ready_reward: [],
      replace_reward: [],
      repack_reward: [],
      bag_reward: [],
      rating_reward: [],
      rating_total: []
      // TODO при добавлении новых значений, 
      // добавить сбрасывание в resetFormValues
      // добавить обнуление при смене ПВЗ
      // добавить в настройки графиков в разделе Статистика
      // добавить в api при вставке в pvzs_stats из pvzs_stats_details
    });

    this.getPvzs();

    if (this.editingItem) {
      this.editingItem.date = this.api.formatInputDate(this.editingItem.date, 'dd.mm.yyyy')
      Object.keys(this.editingItem).forEach((key, index) => {
        if (this.editingItem[key] === 0)
          this.editingItem[key] = null
      });
      this.dataForm.patchValue(this.editingItem)
      
      this.dataUpdated = this.editingItem.updated;
      this.dataUpdatedUserName = this.editingItem.updated_name;
    }
  }

  resetFormValues() {
    this.dataForm.patchValue({
      id: null,
      ready: null,
      ready_price: null,
      recieved: null,
      recieved_price: null,
      users: null,
      returned: null,
      returned_price: null,
      balance: null,
      ready_reward: null,
      replace_reward: null,
      rating_reward: null,
      repack_reward: null,
      bag_reward: null,
      rating_total: null
    })
  }

  private getPvzs() {
    this.api.getPvzs()
      .subscribe({
        next: (res: any) => {
          if (res.success) {
            this.userPvzs = res.items;

            if (!this.editingItem && this.userPvzs.length == 1) {
              this.dataForm.patchValue({ pvz_id: this.userPvzs[0].id });
              this.fakePvzAddress = this.userPvzs[0].pvz_address
            }

            // выбираем тип пвз, чтобы показать нужные поля и форматируем список типов ПВЗ
            this.pvzSelected();
          } else {
            this.api.toastPresent('Не удалось загрузить список ПВЗ. Повторите попытку позже.');
            return this.modalCtrl.dismiss(null, 'close');
          }
        }
      });
  }

  pvzSelected() {
    for (let i = 0; i < this.userPvzs.length; i++) {
      if (this.userPvzs[i].id == this.dataForm.get('pvz_id').getRawValue()) {
        this.selectedPvzType = this.userPvzs[i].type;
        break;
      }
    }
    this.pvzTypes = [];
    for (const type in this.api.pvzTypes) {
      if (Object.prototype.hasOwnProperty.call(this.api.pvzTypes, type)) {
        if (this.selectedPvzType & parseInt(type)) {
          this.pvzTypes.push({ type: parseInt(type), title: this.api.pvzTypes[type].full })
        }
      }
    }
    if (this.pvzTypes.length == 1)
      this.dataForm.get('type').setValue(this.pvzTypes[0].type)
  }

  async showSelect(type: string) {
    let modal;
    switch (type) {
      case 'pvz':
        modal = await this.modalCtrl.create({
          component: PvzSelectComponent,
          componentProps: { id: this.dataForm.get('pvz_id').value },
          cssClass: 'pvz-select-modal',
        });
        await modal.present();
    
        var { data, role } = await modal.onDidDismiss();
    
        if (role == 'pvz') {
          this.dataForm.patchValue({ pvz_id: data.item.id })
          this.fakePvzAddress = data.item.pvz_address
        }
        break;
    }
  }

  async getStats() {
    if (this.dataForm.get('pvz_id').getRawValue() && this.dataForm.get('type').getRawValue()) {
      await this.api.loadingPresent();
      this.api.get(`stats_details`, { date: this.api.formatInputDate(this.dataForm.get('date').value, 'yyyy-mm-dd'), pvz_id: this.dataForm.get('pvz_id').getRawValue(), type: this.dataForm.get('type').getRawValue() })
        .subscribe({
          next: (res: any) => {
            this.api.loadingDismiss();
            if (res.success) {
              this.resetFormValues();
              if (res.items && res.items[0]) {
                for (const data in res.items[0]) {
                  if (Object.prototype.hasOwnProperty.call(res.items[0], data)) {
                    if (res.items[0][data] == 0) {
                      res.items[0][data] = null
                    }
                  }
                }
                res.items[0].date = this.api.formatInputDate(res.items[0].date, 'dd.mm.yyyy')
                this.dataForm.patchValue(res.items[0]);
                this.dataUpdated = res.items[0].updated;
                this.dataUpdatedUserName = res.items[0].updated_name;
              }
              if (this.readyInput)
                setTimeout(() => this.readyInput.setFocus(), 500);
              else
                setTimeout(() => this.balanceInput.setFocus(), 500);
            } else {
              this.api.toastPresent('Не удалось загрузить данные за указанную дату по выбранному ПВЗ. Повторите попытку позже.');
              return this.modalCtrl.dismiss(null, 'close');
            }
          }
        });
    }
  }

  setCalendarDate() {
    if (this.dataForm.get('date').valid) {
      this.datePicker.reset(this.api.formatInputDate(this.dataForm.get('date').value, 'yyyy-mm-dd'));
      this.getStats();
    }
  }

  setInputDate(e) {
    if (document.querySelector('.datetime-select.datetime-ready') && document.getElementsByClassName('month-year-picker-open').length == 0) {
      e.target.confirm(true)
      this.dataForm.get('date').setValue(this.api.formatInputDate(e.detail.value, 'dd.mm.yyyy'))
    }
  }

  async save() {
    if (this.dataForm.valid) {
      await this.api.loadingPresent();

      let data = { ...this.dataForm.getRawValue() };
      data.date = this.api.formatInputDate(data.date, 'yyyy-mm-dd')
      
      this.api.post('stats_details', data, true)
        .subscribe({
          next: (res: any) => {
            this.api.loadingDismiss();
            if (res.success) {
              return this.modalCtrl.dismiss({ pvz_id: this.dataForm.get('pvz_id').getRawValue(), date: this.dataForm.get('date').getRawValue() }, 'saved');
            } else {
              this.api.toastPresent(res.message);
              if (res.code == 401) {
                return this.modalCtrl.dismiss(null, 'close');
              }
            }
          }
        });
    } else {
      this.api.toastPresent("Указаны некорректные данные в форме. Проверьте ещё раз указанную информацию.");
    }
  }

  close() {
    return this.modalCtrl.dismiss(null, 'close');
  }

}
