import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { ModalController } from '@ionic/angular';

import { ApiService } from '../../services/api.service';
import { YmService } from '../../services/ym.service';

@Component({
  selector: 'app-pvz-select',
  templateUrl: './pvz-select.component.html',
  styleUrls: ['./pvz-select.component.scss'],
})
export class PvzSelectComponent implements OnInit {

  @ViewChild('search') searchInput: any;
  @Input() id: number;
  @Input() all: boolean;
  @Input() no: boolean;
  @Input() type: number; // специализация
  @Input() usersToFilter; // список пользователей для отфильтровки
  @Input() showGroups: boolean;
  @Input() onlyGroups: boolean;
  items: any;
  itemsNotFound: boolean;
  itemsLoading: boolean;
  private req: any;
  searchString: string;
  segment: string;

  constructor(public api: ApiService, private modalCtrl: ModalController, private ym: YmService) {}

  ngOnInit() {
    this.ym.hit('pvz-select', { params: { title: 'Окно выбора ПВЗ' } });
    this.segment = !this.onlyGroups && (!this.id || this.id >= -1) ? 'pvzs' : 'pvzs_groups';
    this.getItems();
  }

  searchItems(e) {
    this.searchString = e.target.value.toLowerCase();
    this.getItems();
  }

  getItems(force?: boolean) {
    this.itemsNotFound = false;
    this.itemsLoading = true;
    this.items = [];

    if (this.req)
      this.req.unsubscribe();

    if (this.segment == 'pvzs') {
      this.req = this.api.getPvzs(force)
        .subscribe({
          next: (res: any) => {
            this.itemsLoading = false;
            if (res.success) {
              if (res.items.length > 0) {
                // фильтруем результат, если ищу по названию
                if (this.searchString)
                  this.items = res.items.filter((i) => i.pvz_address.toLowerCase().indexOf(this.searchString) > -1);
                else {
                  this.items = res.items;
                  if (this.items.length >= 10)
                    setTimeout(() => this.searchInput.setFocus(), 500);
                }

                this.itemsNotFound = false;
              } else {
                this.itemsNotFound = true;
              }
            } else {
              this.api.toastPresent(res.message);
              this.modalCtrl.dismiss();
            }
          }
      });
    } else {
      this.req = this.api.getPvzsGroups(force)
        .subscribe({
          next: (res: any) => {
            this.itemsLoading = false;
            if (res.success) {
              if (res.items.length > 0) {
                // фильтруем результат, если ищу по названию
                if (this.searchString)
                  this.items = res.items.filter((i) => i.title.toLowerCase().indexOf(this.searchString) > -1);
                else
                  this.items = res.items;

                this.itemsNotFound = false;
              } else {
                this.itemsNotFound = true;
              }
            } else {
              this.api.toastPresent('Не удалось загрузить список групп. Повторите попытку позже.');
              this.modalCtrl.dismiss();
            }
          }
      });
    }
  }

  select(item, type: string) {
    return this.modalCtrl.dismiss({ item }, type);
  }

  close() {
    return this.modalCtrl.dismiss(null, 'close');
  }

  // удаляет из DOM скрытый селект
  removeHiddenSelect() {
    let hidden = document.getElementsByClassName('hidden-select')[0]
    if (hidden)
      hidden.parentElement.removeChild(hidden);
  }

  ngOnDestroy() {
    this.removeHiddenSelect();
    if (this.req)
      this.req.unsubscribe()
  }
}