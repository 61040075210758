import { ApplicationRef, Component } from '@angular/core';
import { SwUpdate, VersionReadyEvent } from '@angular/service-worker';
import { Platform } from '@ionic/angular';
import { interval } from 'rxjs';

import { StorageService } from './services/storage.service';
import { UserService } from './services/user.service';
import { YmService } from './services/ym.service';

import {filter, first, map, switchMap, tap} from 'rxjs/operators';

declare let window: any;

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent {

  constructor(private appRef: ApplicationRef, private storage: StorageService, private swUpdate: SwUpdate, private platform: Platform, private user: UserService, private ym: YmService) {}

  ngOnInit() {
    // генерим UUID для пушей
    let generateUUID = () => {
      this.user.data.uuid = this.user.generateUUID();
      this.user.saveUserData();
    }

    this.storage.get('userData').then(val => {
      if (val != null) {
        if (val.settings) {
          this.user.data.settings.globalSettings = { ...this.user.data.settings.globalSettings, ...val.settings.globalSettings }
          this.user.data.settings.appSettings = { ...this.user.data.settings.appSettings, ...val.settings.appSettings }
          this.user.data.settings.pvzsSettings = { ...this.user.data.settings.pvzsSettings, ...val.settings.pvzsSettings }
          this.user.data.settings.shiftsSettings = { ...this.user.data.settings.shiftsSettings, ...val.settings.shiftsSettings }
          this.user.data.settings.statsSettings = { ...this.user.data.settings.statsSettings, ...val.settings.statsSettings }
          this.user.data.settings.usersSettings = { ...this.user.data.settings.usersSettings, ...val.settings.usersSettings }
          delete val.settings
        }
        this.user.data = { ...this.user.data, ...val };
        if (!this.user.data.uuid)
          generateUUID();
      } else {
        generateUUID();
      }
      
      this.ym.initialize();

      const prefersDark = window.matchMedia('(prefers-color-scheme: dark)');
      let toggleDarkTheme = (shouldAdd: boolean) => {
        document.body.classList.toggle('dark', shouldAdd);
        if (shouldAdd)
          this.user.data.settings.appSettings.dark_theme = true;
      }
      prefersDark.addListener((mediaQuery) => toggleDarkTheme(mediaQuery.matches));
      toggleDarkTheme(this.user.data.settings.appSettings.dark_theme);
    });

    // чекаем обновление версии приложения
    if (this.swUpdate.isEnabled) {
      try {
        this.swUpdate.versionUpdates.pipe(
          filter((evt): evt is VersionReadyEvent => evt.type === 'VERSION_READY'),
          map(evt => ({
            type: 'UPDATE_AVAILABLE',
            current: evt.currentVersion,
            available: evt.latestVersion,
          }))
        ).subscribe(() => this.swUpdate.activateUpdate().then(() => window.location.reload()));
      } catch(e) {
        console.log('service worker disabled', e);
      }
      
      this.appRef.isStable.pipe(
        first(stable => stable),
        tap(() => { /* console.log('App is stable now'); */ this.swUpdate.checkForUpdate(); }),
        switchMap(() => interval(3 * 60 * 1000))
      ).subscribe(() => {
        try {
          this.swUpdate.checkForUpdate();
        } catch(e) {
          console.log('service worker disabled', e);
        }
      });
    }

    // адаптация под клавиатуру
    this.platform.keyboardDidShow.subscribe(e => {
      const { keyboardHeight } = e;
      let app = document.querySelector('ion-app')
      if (app)
        app.style.setProperty(
          'bottom',
          `${ keyboardHeight }px`
        );
    });
  
    this.platform.keyboardDidHide.subscribe(() => {
      let app = document.querySelector('ion-app')
      if (app)
        app.style.removeProperty('bottom');
    });
  }
}
