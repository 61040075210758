import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { AlertController, IonDatetime, ModalController } from '@ionic/angular';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

import { ApiService } from '../../services/api.service';
import { RewardsService } from '../../services/rewards.service';
import { UserService } from '../../services/user.service';
import { YmService } from '../../services/ym.service';

import { PvzSelectComponent } from '../../modals/pvz-select/pvz-select.component'
import { UserSelectComponent } from '../../modals/user-select/user-select.component'

import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-salary-reward-edit',
  templateUrl: './salary-reward-edit.component.html',
  styleUrls: ['./salary-reward-edit.component.scss'],
})
export class SalaryRewardEditComponent implements OnInit {

  @ViewChild('datePicker') datePicker : IonDatetime
  @ViewChild('value') valueInput: any;
  @Input() editingItem: any;
  dataForm: FormGroup;
  userPvzs: any;
  userUsers: any;
  userKinds: any;
  selectedEmployees: any; // выбранные для распределения сотрудники
  dataUpdated: string;
  dataUpdatedUserName: string;
  fakePvzAddress;
  pvzRequired: boolean;
  
  constructor(private alertCtrl: AlertController, public api: ApiService, private datePipe: DatePipe, private formBuilder: FormBuilder, private modalCtrl: ModalController, private rewards: RewardsService, public user: UserService, private ym: YmService) {
    this.userPvzs = [];
    this.userUsers = [];
    this.userKinds = [];
    this.selectedEmployees = [];
  }

  async ngOnInit() {
    this.ym.hit('salary-reward-edit', { params: { title: 'Окно редактирования начисления' } });
    
    this.dataForm = this.formBuilder.group({
      type: [1, Validators.required],
      date: [this.api.formatInputDate(new Date(Date.now() - new Date().getTimezoneOffset() * 60 * 1000).toISOString(), 'dd.mm.yyyy'), [Validators.pattern(/(0[1-9]|[12][0-9]|3[01])\.(0[1-9]|1[0,1,2])\.(19|20)\d{2}/), Validators.required]],
      pvz_id: [],
      employee_id: [],
      visibility: [1, Validators.required],
      kind_id: [, Validators.required],
      value: [, [Validators.required, Validators.min(0)]],
      shk_code: [, Validators.maxLength(15)],
      url: [, Validators.maxLength(250)],
      comment: [, Validators.maxLength(250)]
    });

    if (this.user.data.role != 0 && this.user.checkPermissions([9])) {
      this.pvzRequired = true;
      this.dataForm.get('pvz_id').addValidators(Validators.required);
      this.dataForm.get('pvz_id').updateValueAndValidity();
    }

    if (this.editingItem) {
      if (this.editingItem.pvz_address)
        this.fakePvzAddress = this.editingItem.pvz_address

      this.dataForm.patchValue(this.editingItem);

      if (this.editingItem.date)
        this.dataForm.get('date').setValue(this.api.formatInputDate(this.editingItem.date, 'dd.mm.yyyy'))

      if (this.editingItem.employees) {
        this.selectedEmployees = JSON.parse(JSON.stringify(this.editingItem.employees));
        // если добавляем начисление при оспаривании, то раскидываем сумму
        if (!this.selectedEmployees[0].value)
          this.valueChanged();
      }

      // если редактируем уже начисленную или выплаченную премию, то блокируем изменение некоторых полей
      for (let i = 0; i < this.selectedEmployees.length; i++) {
        if (this.selectedEmployees[i].salary_id) {
          this.dataForm.get('employee_id').disable();
          this.dataForm.get('value').disable();
          this.dataForm.get('type').disable();
          this.dataForm.get('kind_id').disable();
          this.dataForm.get('date').disable();
          break;
        }
      }

      this.dataUpdated = this.editingItem.updated;
      this.dataUpdatedUserName = this.editingItem.updated_name ? this.editingItem.updated_name : 'Рассчитано по формуле';
    }

    setTimeout(() => this.valueInput.setFocus(), 500);

    this.getUsers();
    this.getPvzs();
    this.getKinds();
  }

  private getKinds() {
    this.api.get('salary_rewards_kinds')
      .subscribe({
        next: (res: any) => {
          if (res.success) {
            this.userKinds = res.items;
          } else {
            this.api.toastPresent('Не удалось загрузить типы наград/штрафов. Повторите попытку позже.');
            return this.modalCtrl.dismiss(null, 'close');
          }
        }
      });
  }

  private getPvzs() {
    this.api.getPvzs()
      .subscribe({
        next: (res: any) => {
          this.api.loadingDismiss();
          if (res.success) {
            this.userPvzs = res.items;
          } else {
            this.api.toastPresent('Не удалось загрузить список ПВЗ. Повторите попытку позже.');
            return this.modalCtrl.dismiss(null, 'close');
          }
        }
      });
  }

  getUsers() {
    this.api.getUsers()
      .subscribe({
        next: (res: any) => {
          this.api.loadingDismiss();
          if (res.success) {
            this.userUsers = res.items.sort((a: any, b: any) => { return a.name > b.name ? 1 : -1 })
              .map((user: any) => { return { id: user.id, name: user.name, value: 0 } });
          } else {
            this.api.toastPresent('Не удалось загрузить список сотрудников. Повторите попытку позже.');
            return this.modalCtrl.dismiss(null, 'close');
          }
        }
      });
  }

  typeChanged(e: any) {
    if (e.detail.value == 3) {
      this.dataForm.patchValue({ kind_id: 1 })
      this.dataForm.patchValue({ shk_code: null })
    } else {
      if (this.dataForm.get('kind_id').value == 1)
        this.dataForm.patchValue({ kind_id: null })
    }
  }

  async kindChanged() {
    if (this.dataForm.get('kind_id').value == -1) {
      const confirm = await this.alertCtrl.create({
        message: `Укажите за что планируете начислять премию/штраф (макс. 30 символов):`,
        inputs: [
          {
            type: 'text',
            value: '',
        
          }
        ],
        buttons: [{
            text: 'Отмена',
            role: 'cancel'
          },
          {
            text: 'Добавить',
            role: 'confirm',
            handler: async (data) => {    
              if (!data[0] || data[0] && (data[0].length < 1 || data[0].length > 30)) {
                this.api.toastPresent('Укажите название до 30 символов.');
                return false;
              }      
              await this.api.loadingPresent();
              this.api.post('rewards_kind', { name: data[0] }, true)
                .subscribe({
                  next: (res: any) => {
                    this.api.loadingDismiss();
                    if (res.success) {
                      this.userKinds.push({ id: res.id, name: data[0] })
                      this.dataForm.get('kind_id').setValue(res.id);
                    } else {
                      this.api.toastPresent(res.message);
                      if (res.code == 401) {
                        return this.modalCtrl.dismiss(null, 'close');
                      }
                    }
                  }
                });
            },
          }
        ],
      });
  
      await confirm.present();
      this.dataForm.get('kind_id').setValue(null);
    }
  }

  valueChanged() {
    if (this.selectedEmployees && this.selectedEmployees.length > 0 && this.dataForm.get('value').getRawValue() != null) {
      if (this.selectedEmployees.length == 1) {
        this.selectedEmployees[0].value = this.dataForm.get('value').getRawValue()
      } else {
        let splitValue = Math.round((this.dataForm.get('value').getRawValue() / this.selectedEmployees.length + Number.EPSILON) * 100) / 100
        for (let i = 0; i < this.selectedEmployees.length - 1; i++) {
          this.selectedEmployees[i].value = splitValue;
        }
        // последнему докидываем все, что осталось, на случай, если поделилось не совсем ровно
        this.selectedEmployees[this.selectedEmployees.length - 1].value = Math.round((this.dataForm.get('value').getRawValue() - splitValue * (this.selectedEmployees.length - 1) + Number.EPSILON) * 100) / 100;
      }
    }
  }

  
  clearPvz() {
    this.dataForm.patchValue({ pvz_id: null });
    this.fakePvzAddress = null;
  }

  async showSelect(type: string) {
    let modal;
    switch (type) {
      case 'pvz':
        modal = await this.modalCtrl.create({
          component: PvzSelectComponent,
          componentProps: { id: this.dataForm.get('pvz_id').value },
          cssClass: 'pvz-select-modal',
        });
        await modal.present();
    
        var { data, role } = await modal.onDidDismiss();
    
        if (role == 'pvz') {
          this.dataForm.patchValue({ pvz_id: data.item.id })
          this.fakePvzAddress = data.item.pvz_address
        }
        break;
      case 'user':
        modal = await this.modalCtrl.create({
          component: UserSelectComponent,
          componentProps: { id: this.dataForm.get('employee_id').value },
          cssClass: 'user-select-modal',
        });
        await modal.present();
    
        var { data, role } = await modal.onDidDismiss();
    
        if (role == 'selected') {
          this.dataForm.patchValue({ employee_id: data.item.id })
        }
        break;
    }
  }

  employeeSelectionChanged() {
    if (this.dataForm.get('employee_id').value) {
      this.dataForm.get('employee_id').setErrors(null);
      let employee = this.userUsers.find((user: any) => user.id == this.dataForm.get('employee_id').value);
      this.selectedEmployees.push({ user_id: employee.id, name: employee.name, value: 0 });
      this.valueChanged();
      setTimeout(() => {
        this.dataForm.get('employee_id').setValue(null)
      }, 100);
    } else {
      this.dataForm.get('employee_id').setErrors({ notValid: true });
    }
  }

  deleteRewardedEmployee(index: number) {
    this.selectedEmployees.splice(index, 1);
    this.valueChanged();
  }

  setCalendarDate() {
    if (this.dataForm.get('date').valid) {
      this.datePicker.reset(this.api.formatInputDate(this.dataForm.get('date').value, 'yyyy-mm-dd'));
    }
  }

  setInputDate(e) {
    if (document.querySelector('.datetime-select.datetime-ready') && document.getElementsByClassName('month-year-picker-open').length == 0) {
      e.target.confirm(true)
      this.dataForm.get('date').setValue(this.api.formatInputDate(e.detail.value, 'dd.mm.yyyy'))
    }
  }

  async save(shk_force?: boolean) {
    if (this.selectedEmployees.length == 0) {
      this.dataForm.get('employee_id').setErrors({ notValid: true });
    } else {
      this.dataForm.get('employee_id').setErrors(null);
    }

    if (this.dataForm.valid) {
      // сверяем общую сумму и поделенную между сотрудниками
      let sum = 0;
      for (let i = 0; i < this.selectedEmployees.length; i++) {
        sum += parseFloat(this.selectedEmployees[i].value);
      }
      //console.log(this.selectedEmployees);
      //console.log(sum);
      //console.log(this.dataForm.get('value').getRawValue());
      
      
      if (sum != this.dataForm.get('value').getRawValue()) {
        return this.api.toastPresent('Общая сумма начисления не совпадает с разделённой суммой между сотрудниками.');
      }

      let data = { ...this.dataForm.getRawValue() };
      data.employees = this.selectedEmployees;
      delete data.employee_id;

      data.date = this.api.formatInputDate(data.date, 'yyyy-mm-dd')
      
      if (this.editingItem) {
        data.id = this.editingItem.id;

        if (this.editingItem.contest_id)
          data.contest_id = this.editingItem.contest_id
      }

      if (shk_force)
        data.shk_code_force = true
      
      await this.api.loadingPresent();

      this.api.post('salary_reward', data, true)
        .subscribe({
          next: async (res: any) => {
            this.api.loadingDismiss();
            if (res.success) {
              this.api.toastPresent(this.editingItem ? "Начисление успешно обновлено." : "Начисление успешно добавлено.");
              return this.modalCtrl.dismiss({ id: res.id }, 'saved');
            } else if (res.shk) {
              const confirm = await this.alertCtrl.create({
                message: `${ res.shk.length > 1 ? 'Найдены начисления' : "Найдено начисление" } с указанным кодом товара за ${ res.shk.map(shk => this.datePipe.transform(shk, 'd MMMM yyyy')).join(', ') }. Всё равно добавить?`,
                buttons: [{
                    text: 'Отмена',
                    role: 'cancel'
                  },
                  {
                    text: 'Добавить',
                    role: 'confirm',
                    handler: () => {    
                      this.save(true)
                    },
                  }
                ],
              });
          
              await confirm.present();
            } else {
              this.api.toastPresent(res.message);
              if (res.code == 401) {
                return this.modalCtrl.dismiss(null, 'close');
              }
            }
          }
        });
    } else {
      this.api.toastPresent("Указаны некорректные данные в форме. Проверьте ещё раз введённую информацию.");
    }
  }

  async del() {
    let result = await this.rewards.delete(this.editingItem)
    if (result) {
      this.modalCtrl.dismiss(null, 'saved')
    }
  }

  close() {
    return this.modalCtrl.dismiss(null, 'close');
  }
}
