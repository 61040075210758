import { Injectable } from '@angular/core';

import { Storage } from '@ionic/storage-angular';

@Injectable({
  providedIn: 'root'
})
export class StorageService {

  private _storage: Storage | null = null;
  private initialized: boolean;

  constructor(private storage: Storage) {}

  async init(): Promise<void> {
    if (!this.initialized) {
      this.initialized = true;
      this._storage = await this.storage.create();
      return;
    } else {
      return;
    }
  }

  public set(key: string, value: any) {
    this._storage?.set(key, value);
  }

  public async get(key: string) {
    await this.init();
    return await this.storage.get(key);
  }

  public remove(key: string) {
    this._storage?.remove(key);
  }
}
